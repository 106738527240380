import React, { useContext } from "react";
import { AppContext } from "../AppContext";

import { ChatMessageComponentInterface } from "../interfaces";

const ChatMessageComponent: React.FC<ChatMessageComponentInterface> = ({
  step,
}) => {
  const { values, setDataValues } = useContext(AppContext);

  const renderComponent = () => {
    return React.cloneElement(step?.component!, {
      values,
      step,
      setDataValues,
    });
  };

  return renderComponent();
};

export default ChatMessageComponent;
