import React, { useEffect } from "react";
import qs from "query-string";
import { ConversationStep } from "../../interfaces";
import { sendNewLead, trackEvent } from "../../util";
import { useTheme } from "@mui/material";

const { REACT_APP_PROTECTION_URL } = process.env;

interface IProps {
  values?: any;
  step?: ConversationStep;
}

const InsuranceQuote: React.FC<IProps> = ({ values }) => {
  const theme = useTheme();

  const state = {
    form: {
      ...values,
      occupation: "",
      partner_occupation: "",
      occupation_code: values.occupation?.code,
      occupation_description: values.occupation?.description,
      partner_occupation_code: values.partner_occupation?.code,
      partner_occupation_description: values.partner_occupation?.description,
    },
  };

  useEffect(() => {
    if (theme?.custom?.googleAnalyticsTrackingId) {
      trackEvent(theme?.custom?.googleAnalyticsTrackingId, {
        category: "MeetParkerChat",
        action: "Insurance Quote Loaded",
      });
    }
    /*eslint-disable react-hooks/exhaustive-deps*/
  }, []);

  return (
    <div
      className="conversation-message-bubble"
      style={{
        maxWidth: "100%",
        width: "100%",
        height: 800,
        padding: 0,
      }}
    >
      <iframe
        title="Insurance Quote"
        style={{
          borderWidth: 0,
          borderRadius: 17,
        }}
        src={`${REACT_APP_PROTECTION_URL}/client?apiKey=${
          theme?.custom?.apiKey
        }&requestType=mortgage_chat&hideAppointmentButton=yes&hideAppBar=yes&hideProgressBar=yes&hideFooter=yes&${qs.stringify(
          state.form
        )}`}
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default React.memo(InsuranceQuote);
