import { ConversationStep } from "../../interfaces";
import { numberWithCommas } from "../../util";

export default [
  {
    id: "holiday_let_mortgage_reason",
    type: "bot",
    message: "Are you Purchasing or Remortgaging your Holiday Let?",
    options: [
      {
        label: "Purchase",
        value: "Purchase",
        trigger: "holiday_let_home_reason",
      },
      {
        label: "Remortgage",
        value: "Remortgage",
        trigger: "holiday_let_home_reason",
      },
    ],
  },
  {
    id: "holiday_let_home_reason",
    type: "bot",
    message: "Is the Holiday Home for your own use or being rented out?",
    options: [
      {
        label: "Own Use",
        value: "Own Use",
        trigger: "property_value",
      },
      {
        label: "Rented Out",
        value: "Rented Out",
        trigger: "property_value",
      },
      {
        label: "Bit of Both",
        value: "Bit of Both",
        trigger: "property_value",
      },
    ],
  },
  {
    id: "holiday_let_rental",
    type: "bot",
    message:
      "What is the average weekly expected rental from your Holiday Let?",
    input: {
      type: "number",
      placeholder: "Type here...",
      startAdornment: "£",
    },
    formatter: numberWithCommas,
    trigger: "mortgage_balance",
  },
  {
    id: "holiday_let_applicant_fname",
    type: "bot",
    message: "What's your first name?",
    input: {
      type: "text",
    },
    trigger: "holiday_let_applicant_lname",
  },
  {
    id: "holiday_let_applicant_lname",
    type: "bot",
    message: "What's your last name?",
    input: {
      type: "text",
    },
    trigger: "holiday_let_applicant_email",
  },
  {
    id: "holiday_let_applicant_email",
    type: "bot",
    message: "What's your email?",
    input: {
      type: "email",
    },
    end: true,
  },
] as ConversationStep[];
