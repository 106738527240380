import { createTheme, lighten } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#e6292a",
    },
    secondary: {
      main: "#595959",
    },
  },
  typography: {
    fontWeightRegular: 500,
    h1: {
      fontSize: 30,
    },
    h2: {
      fontSize: 20,
    },
    h3: {
      fontSize: 18,
    },
    h4: {
      fontSize: 16,
    },
    h5: {
      fontSize: 14,
    },
    h6: {
      fontSize: 12,
    },
    body1: {
      lineHeight: 1.1,
    },
    body2: {
      fontSize: 12,
    },
  },
});

export default createTheme(theme, {
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 10,
          backgroundColor: lighten(theme.palette.primary.main, 0.5),
          borderRadius: "8px",
        },
        bar: {
          borderRadius: 20,
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          letterSpacing: 1.5,
          marginBottom: 10,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
          },
        },
      },
    },
  },
});
