import { useContext, useEffect, useState } from "react";
import ReactTypingEffect from "react-typing-effect";
import { Typewriter } from "react-simple-typewriter";
import dot from "dot-object";

import { ChatMessageInterface } from "../interfaces/ChatMessage";
import { ConversationStepOption } from "../interfaces";
import ChatMessageOptions from "./ChatMessageOptions";
import ChatMessageInput from "./ChatMessageInput";
import ChatMessageComponent from "./ChatMessageComponent";
import { AppContext } from "../AppContext";
import { useTheme } from "@mui/material";

const ChatMessage: React.FC<ChatMessageInterface> = ({
  currentPage,
  triggerNextPage,
}) => {
  const { values } = useContext(AppContext);

  const theme = useTheme();

  const [isStatic, setIsStatic] = useState(false);
  const [messages, setMessages] = useState<string[]>([]);
  const [buttonOptions, setButtonOptions] = useState<ConversationStepOption[]>(
    []
  );
  const lastStep = currentPage![currentPage!.length - 1]!;

  const componentSteps = currentPage?.filter((page) => page?.component);

  const getStepMessage = (message: string) => {
    const matches =
      typeof message === "string" ? message?.match(/{(.*?)}/g) || [] : [];
    let newMessage = message;
    for (const match of matches) {
      const property: any = match.slice(1).replace("}", "");
      newMessage = newMessage.replace(match, values[property]);
    }

    return newMessage;
  };

  const containsSubstring = (messagesParam: string[], searchSubstring: string): boolean => {
    return messagesParam.some(message => message.includes(searchSubstring));
  }

  useEffect(() => {
    const initializeOptions = async () => {
      let optionsList =
        typeof lastStep?.options === "function"
          ? await lastStep?.options(values)
          : lastStep?.options!;
      setButtonOptions(
        optionsList?.map((option) => ({
          label: option.label!,
          value: option.value!,
          trigger: option.trigger!,
          enable: option.enable!,
        })) || []
      );
    };

    if (currentPage) {
      const messagesToRender = currentPage!
        .map((page) => getStepMessage(page?.message!))!
        .filter(Boolean) as string[];
      setMessages(messagesToRender);
      initializeOptions();

      if (!messagesToRender.length) {
        setIsStatic(true);
      } else {
        setIsStatic(false);
      }
    }

    /*eslint-disable react-hooks/exhaustive-deps*/
  }, [currentPage]);

  return (
    <>
      {componentSteps
        ? componentSteps.map((componentStep: any) => (
            <ChatMessageComponent step={componentStep} />
          ))
        : null}

      {messages.length ? (
        <div
          className={`conversation-text`}
          style={{
            color: `${theme?.custom?.customization?.parkerSlideTextColor}`,
          }}
        >
          {isStatic ? (
            <ReactTypingEffect
              typingDelay={1000}
              speed={16}
              eraseSpeed={33}
              eraseDelay={1000}
              staticText={
                typeof messages[messages?.length - 1] === "string"
                  ? messages[messages?.length - 1]
                  : (messages[messages?.length - 1] as any)(values)
              }
              text={[]}
            />
          ) : (
            <Typewriter
              words={messages.map((message) =>
                typeof message === "string" ? message : (message as any)(values)
              )}
              loop={1}
              cursor
              typeSpeed={containsSubstring(
                messages,
                "We highly recommend seeking good quality"
              ) ? 120 : 5}
              deleteSpeed={containsSubstring(
                messages,
                "We highly recommend seeking good quality"
              ) ? 50 : 5}
              delaySpeed={1000}
              onLoopDone={() => {
                setIsStatic(true);
              }}
            />
          )}
        </div>
      ) : null}

      {buttonOptions?.length && isStatic ? (
        <ChatMessageOptions
          options={buttonOptions}
          onOptionSelect={(option: ConversationStepOption) => {
            setIsStatic(false);
            triggerNextPage!(lastStep, {
              trigger: option?.trigger,
              value: option?.value,
            });
          }}
        />
      ) : null}

      {lastStep?.input && isStatic ? (
        <ChatMessageInput
          input={lastStep?.input}
          onInputSubmit={(input: string) => {
            setIsStatic(false);
            triggerNextPage!(lastStep, {
              trigger: lastStep?.trigger,
              value: input,
            });
          }}
        />
      ) : null}
    </>
  );
};

export default ChatMessage;
