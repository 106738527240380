import {
  AppBar,
  IconButton,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";

interface HeaderProps {
  toggleDrawer?: any;
}

const Header: React.FC<HeaderProps> = ({ toggleDrawer }) => {
  const theme = useTheme();

  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  //@ts-ignore
  const header = theme?.custom?.header === "false";

  return (
    <AppBar
      elevation={4}
      position="fixed"
      sx={{ padding: 1, bgcolor: "#FFF", zIndex: 1201 }}
    >
      <Toolbar>
        {matchDownMd && (
          <IconButton disableRipple onClick={toggleDrawer!}>
            <MenuIcon />
          </IconButton>
        )}
        {!header && (
          <img
            src={theme?.custom?.logo}
            width="auto"
            height="65"
            alt="company-logo"
          />
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
