import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Drawer,
  Typography,
  Box,
  List,
  Toolbar,
  useMediaQuery,
} from "@mui/material";

interface SidebarProps {
  drawerWidth?: number;
  open?: boolean;
  toggleDrawer?: Function;
  pages?: any;
  currentPage?: any;
}

const Sidebar: React.FC<SidebarProps> = ({
  drawerWidth = 270,
  open,
  pages,
  currentPage,
  toggleDrawer,
}) => {
  const theme = useTheme();

  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Drawer
      variant={matchDownMd ? "temporary" : "permanent"}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
      }}
      open={open}
      onClose={() => toggleDrawer!()}
    >
      <Box
        padding="24px"
        sx={{
          overflowY: "auto",
        }}
      >
        <Toolbar />
        <Typography
          marginTop="24px"
          marginBottom="155px"
          fontSize={24}
          fontWeight={700}
          color="primary"
        >
          Your eligibility
        </Typography>
        <List>
          {(pages || []).map((page: any) => {
            return (
              <Box
                key={page?.displayName}
                sx={{
                  cursor: "pointer",
                  background:
                    page?.displayName === currentPage?.displayName
                      ? theme?.palette?.primary?.main
                      : "#FFF",
                  minWidth: "180px",
                  minHeight: "48px",
                  borderRadius: 27,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  fontSize={16}
                  fontWeight={700}
                  lineHeight="17px"
                  textAlign="center"
                  sx={{
                    color:
                      page?.displayName === currentPage?.displayName
                        ? "#FFF"
                        : "#000",
                  }}
                >
                  {page?.displayName}
                </Typography>
              </Box>
            );
          })}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
