import { ConversationStep } from "../interfaces";
import Theme from "../interfaces/Theme";
import { getReadableDate } from "../util";

import AppointmentAPICall from "../components/custom/AppointmentAPICall";
import AppointmentCalendar from "../components/custom/AppointmentCalendar";
import AppointmentTelephoneCall from "../components/custom/AppointmentTelephoneCall";
import SendNewLead from "../components/custom/SendNewLead";

// const TriggerRedirection = (
//   values: any,
//   ifTrigger: string,
//   elseTrigger: string,
//   theme: Theme
// ) => {
//   if (
//     //@ts-ignore
//     theme.products?.length > 0
//   ) {
//     return ifTrigger;
//   }

//   return elseTrigger;
// };

// const OfferingTrigger = (values: any, theme: Theme) =>
//   TriggerRedirection(values, "product_item", "select_date", theme);

const AppointmentSteps = (theme: Theme) => {
  return [
    {
      id: "time_requested",
      message: "Which of the following would you prefer?",
      type: "bot",
      options: [
        {
          label: "Morning (9am - 12pm)",
          value: "Morning (9am - 12pm)",
          trigger: "contact_requested",
        },
        {
          label: "Lunchtime (12pm - 3pm)",
          value: "Lunchtime (12pm - 3pm)",
          trigger: "contact_requested",
        },
        {
          label: "Afternoon (3pm - 6pm)",
          value: "Afternoon (3pm - 6pm)",
          trigger: "contact_requested",
        },
      ],
    },
    {
      id: "talk_with_property_experts",
      message: "Would you like to talk to one of our Property Experts?",
      type: "bot",
      options: [
        {
          label: "Yes",
          value: "Yes",
          trigger: "select_date",
        },
        {
          label: "No",
          value: "No",
          trigger: "trigger_no_advisor",
        },
      ],
    },
    {
      id: "talk_with_advisor",
      message: "Would you like to talk to one of our advisors?",
      type: "bot",
      options: [
        {
          label: "Yes",
          value: "Yes",
          trigger: "appointment_page",
          // trigger: (values) =>
          //     theme.apiKey === "43e63eb7-f1d4-4db0-a74b-1ec0c9096479"
          //         ? OfferingTrigger(values, theme)
          //         : "select_date",
        },
        {
          label: "No",
          value: "No",
          trigger: "appointment_api",
        },
      ],
    },
    {
      id: "select_date",
      message:
        theme?.apiKey === "fbb8a34f-0bb0-40c0-a007-7a0f07a68341"
          ? "Please select your preferred date and time for the appointment you would like to schedule and we will contact you to confirm"
          : "Please select the date and time for the appointment you want to schedule",
      type: "bot",
      trigger: "appointment_date",
    },
    {
      id: "date_chosen",
      message: "You want to book on {previousValue}",
      type: "bot",
      trigger: "contact_requested",
      formatter: getReadableDate,
    },
    {
      id: "date_chosen_no_problem",
      message: "No problem.",
      type: "bot",
      trigger: "contact_requested",
    },
    {
      id: "contact_requested",
      message:
        theme?.apiKey === "fbb8a34f-0bb0-40c0-a007-7a0f07a68341"
          ? "What is your preferred appointment type?"
          : "How would you prefer to be contacted?",
      type: "bot",
      options: [
        {
          label: "Telephone Call",
          value: "Telephone Call",
          trigger: "appointment_api",
          enable: (values: any) =>
            !values.theme?.appointmentCustomization?.disableTelephoneCall,
        },
        {
          label: "Video Call",
          value: "Video Call",
          trigger: "appointment_api",
          enable: (values: any) =>
            values.theme.apiKey !== "d8eccbf3-bfa9-435b-9735-c91641c5446e" &&
            !values.theme?.appointmentCustomization?.disableVideoCall,
        },
        {
          label: "Face to Face Meeting",
          value: "Face to Face Meeting",
          trigger: "appointment_api",
          enable: (values: any) =>
            values.theme.apiKey !== "d8eccbf3-bfa9-435b-9735-c91641c5446e" &&
            !values.theme?.appointmentCustomization?.disableFaceToFaceMeeting,
        },
      ],
    },
    {
      id: "appointment_date",
      message:
        "Please select the date and time for the appointment you want to schedule",
      trigger: (values: any) => {
        if (values?.contact_requested === "Talk Now") {
          return "talk_now";
        }

        if (values?.theme?.appointmentCustomization?.disableAppointmentTime) {
          return "time_requested";
        }

        if (values?.theme?.version === "property") {
          return "date_chosen_no_problem";
        }

        if (values?.theme?.version === "rightmove") {
          return "appointment_api";
        }

        return "date_chosen";
      },
      //date_chosen_no_problem
      component: <AppointmentCalendar />,
      type: "bot",
    },
    {
      id: "appointment_api",
      trigger: (values: any) => {
        if (values?.talk_with_advisor === "No") {
          return "can_i_help_with_anything_else";
        }

        if (values?.theme?.version === "property") {
          return "property_booking_confirmation";
        }

        if (values?.contact_requested === "Talk Now") return "end_greeting";

        return "flow_end";
      },
      component: <AppointmentAPICall />,
      type: "bot",
    },
    {
      id: "can_i_help_with_anything_else",
      message: "Can I help with anything else?",
      type: "bot",
      options: [
        {
          label: "Yes",
          value: "Yes",
          trigger: "how_can_we_help",
        },
        {
          label: "No",
          value: "No",
          trigger: "please_close_tab_end",
        },
      ],
    },
    {
      id: "please_close_tab_end",
      message: "Ok, Thank you! You can now close this tab",
      type: "bot",
      end: true,
    },
    {
      id: "trigger_no_advisor",
      type: "bot",
      component: <SendNewLead />,
      trigger: "trigger_no_advisor_end",
    },
    {
      id: "talk_with_advisor_sendnewlead",
      type: "bot",
      component: <SendNewLead />,
      trigger: "talk_with_advisor",
    },
    {
      id: "illustration_page_sendnewlead",
      type: "bot",
      component: <SendNewLead />,
      trigger: "illustration_page",
    },
    {
      id: "trigger_no_advisor_end",
      type: "bot",
      message:
        "We highly recommend seeking good quality advice when it comes to your financial needs",
      trigger: (values: any) => {
        if (values?.theme?.version === "property") {
          return "trigger_no_advisor_end2";
        }

        return "trigger_no_advisor_end1";
      },
    },
    {
      id: "trigger_no_advisor_end1",
      type: "bot",
      message: `Please feel free to adjust your search parameters above, or contact us directly at ${theme?.email}.`,
      trigger: "trigger_no_advisor_end2",
    },
    {
      id: "trigger_no_advisor_end2",
      type: "bot",
      message: "Have a lovely day",
      end: true,
    },
    {
      id: "talk_now",
      type: "bot",
      component: <AppointmentTelephoneCall />,
      trigger: () => {
        return "appointment_api";
      },
    },
  ] as ConversationStep[];
};

export default AppointmentSteps;
