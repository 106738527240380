import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import "./assets/styles/styles.scss";

import AppTheme from "./interfaces/Theme";

import Conversation from "./pages/Conversation";
import AppProvider from "./AppContext";

import "./index.css";
import withRoot from "./withRoot";

declare module "@mui/material/styles" {
  interface Theme {
    custom: AppTheme;
  }
}

if (document.getElementById("root") as HTMLElement) {
  const urlParams = new URLSearchParams(document.location.search);

  const apiKey = urlParams?.get("apiKey");

  const config = {
    header: urlParams?.get("header"),
  };

  const ConversationWithRoot = withRoot(Conversation, apiKey!, config)!;

  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );
  root.render(
    <BrowserRouter>
      <AppProvider>
        <Routes>
          <Route path="client" element={<ConversationWithRoot />} />
          <Route path="404" element={<h1>Page not found</h1>} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </AppProvider>
    </BrowserRouter>
  );
}

const renderApp = (apiKey: string, config: any, element: HTMLElement) => {
  if (element) {
    const rootDiv = ReactDOM.createRoot(element);

    if (apiKey) {
      const ConversationWithRoot = withRoot(Conversation, apiKey!, config)!;

      rootDiv.render(
        <AppProvider>
          <ConversationWithRoot />
        </AppProvider>
      );
    }
  } else {
    throw new Error("Element does not exist.");
  }
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export { renderApp };
