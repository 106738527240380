[
    {
        "code": "AAB00001",
        "description": "Abattoir Inspector"
    },
    {
        "code": "AAC02588",
        "description": "Abattoir Worker"
    },
    {
        "code": "AAD03000",
        "description": "Abrasive Wheels Worker"
    },
    {
        "code": "AAB00010",
        "description": "Account Execu  ve"
    },
    {
        "code": "AAB00011",
        "description": "Accountant"
    },
    {
        "code": "AAB00013",
        "description": "Acidifier Operator"
    },
    {
        "code": "AAB00020",
        "description": "Actor/Actress (no stunt work)"
    },
    {
        "code": "AAB00021",
        "description": "Actuary"
    },
    {
        "code": "AAB00022",
        "description": "Acupuncturist"
    },
    {
        "code": "AAC02590",
        "description": "Administra  on Manager"
    },
    {
        "code": "AAC02591",
        "description": "Adver  sing execu  ve"
    },
    {
        "code": "AAB00023",
        "description": "Adver  sing Manager"
    },
    {
        "code": "AAB00024",
        "description": "Aerial Erector (40' up)"
    },
    {
        "code": "AAB00025",
        "description": "Aerial Erector (up to 40')"
    },
    {
        "code": "AAB00026",
        "description": "Aerial Photography"
    },
    {
        "code": "AAC02592",
        "description": "Aerobics Instructor"
    },
    {
        "code": "AAB00027",
        "description": "Agent"
    },
    {
        "code": "AAB00028",
        "description": "Agricultural Engineer"
    },
    {
        "code": "AAB00030",
        "description": "Agricultural Worker"
    },
    {
        "code": "AAB00031",
        "description": "Agronomist"
    },
    {
        "code": "AAD03004",
        "description": "Air Compressor Operator"
    },
    {
        "code": "AAB00036",
        "description": "Air Frame Service Fi   er"
    },
    {
        "code": "AAB00037",
        "description": "Air Pump      endant -Coastal etc"
    },
    {
        "code": "AAB00038",
        "description": "Air Pump      endant -Deep Sea"
    },
    {
        "code": "AAB00039",
        "description": "Air Traffic Control Assistant"
    },
    {
        "code": "AAB00041",
        "description": "Air Traffic Controller"
    },
    {
        "code": "AAC02593",
        "description": "Air Traffic Planner"
    },
    {
        "code": "AAB00042",
        "description": "Aircra    Electronics Service Fi   er"
    },
    {
        "code": "AAB00043",
        "description": "Aircra    Engine Service Fi   er"
    },
    {
        "code": "AAB00044",
        "description": "Aircra    Finisher"
    },
    {
        "code": "AAB00045",
        "description": "Aircra    Inspector"
    },
    {
        "code": "AAB00046",
        "description": "Aircra    Instrument Mechanic"
    },
    {
        "code": "AAB00047",
        "description": "Aircra    Joiner"
    },
    {
        "code": "AAB00048",
        "description": "Aircra    Maintenance Technician"
    },
    {
        "code": "AAB00049",
        "description": "Aircra    Marshaller"
    },
    {
        "code": "AAB00050",
        "description": "Aircra    Refueller"
    },
    {
        "code": "AAB00051",
        "description": "Aircrew (including Flight Engineer)"
    },
    {
        "code": "AAB00052",
        "description": "Airline Cabin Staﬀ"
    },
    {
        "code": "AAB00053",
        "description": "Airline Pilots"
    },
    {
        "code": "AAB00054",
        "description": "Airport Manager"
    },
    {
        "code": "AAB00055",
        "description": "Airport Superintendent"
    },
    {
        "code": "AAC02594",
        "description": "Ambassador"
    },
    {
        "code": "AAB00057",
        "description": "Ambulance Driver"
    },
    {
        "code": "AAB00058",
        "description": "Ambulanceman (No driving)"
    },
    {
        "code": "AAB00060",
        "description": "Anaesthe  st"
    },
    {
        "code": "AAC02596",
        "description": "Analy  cal Chemist"
    },
    {
        "code": "AAB00062",
        "description": "Ancient Monuments Inspector"
    },
    {
        "code": "AAB00063",
        "description": "Animal Nursing Auxiliary"
    },
    {
        "code": "AAB00064",
        "description": "Animal Trainer/Keeper"
    },
    {
        "code": "AAB00065",
        "description": "Animator"
    },
    {
        "code": "AAB00066",
        "description": "Annealer"
    },
    {
        "code": "AAB00067",
        "description": "Announcer - Radio & TV - Entertainment"
    },
    {
        "code": "AAB00068",
        "description": "Announcer - Sta  on Personnel - Railways"
    },
    {
        "code": "AAB00069",
        "description": "Anodiser"
    },
    {
        "code": "AAB00070",
        "description": "An  que Dealer"
    },
    {
        "code": "AAB00071",
        "description": "An  que Restorer"
    },
    {
        "code": "AAB00073",
        "description": "Arc Welder"
    },
    {
        "code": "AAB00074",
        "description": "Archaeologist"
    },
    {
        "code": "AAC02597",
        "description": "Archaeologist (other countries)"
    },
    {
        "code": "AAB00075",
        "description": "Architect"
    },
    {
        "code": "AAC02598",
        "description": "Architect (office)"
    },
    {
        "code": "AAB00076",
        "description": "Archivist"
    },
    {
        "code": "AAB00079",
        "description": "Aromatherapist"
    },
    {
        "code": "AAB00080",
        "description": "Arranger"
    },
    {
        "code": "AAB00081",
        "description": "Art Director"
    },
    {
        "code": "AAB00082",
        "description": "Art Gallery      endant"
    },
    {
        "code": "AAB00083",
        "description": "Art Gallery Curator"
    },
    {
        "code": "AAB00084",
        "description": "Art Gallery Guide"
    },
    {
        "code": "AAC02599",
        "description": "Art Gallery Manager - Commercial"
    },
    {
        "code": "AAB00085",
        "description": "Artexer"
    },
    {
        "code": "AAB00086",
        "description": "Ar  st - Freelance Painter"
    },
    {
        "code": "AAB00087",
        "description": "Ar  st Commercial"
    },
    {
        "code": "AAB00088",
        "description": "Ar  st's Model"
    },
    {
        "code": "AAB00089",
        "description": "Asbestos Worker"
    },
    {
        "code": "AAB00095",
        "description": "Asphalter"
    },
    {
        "code": "AAB00108",
        "description": "Assembler - Woodworking Industry"
    },
    {
        "code": "AAB00109",
        "description": "Assembly Inspector"
    },
    {
        "code": "AAC02600",
        "description": "Assessor (claims/insurance)"
    },
    {
        "code": "AAB00110",
        "description": "Assistant Cameraman"
    },
    {
        "code": "AAB00111",
        "description": "Assistant Director"
    },
    {
        "code": "AAB00112",
        "description": "Assistant Editor"
    },
    {
        "code": "AAB00113",
        "description": "Assistant Superintendent"
    },
    {
        "code": "AAB00114",
        "description": "Assistant Tool Pusher"
    },
    {
        "code": "AAB00115",
        "description": "Associate Producer"
    },
    {
        "code": "AAC02601",
        "description": "Assumed Non-Hazardous (for quotation only)"
    },
    {
        "code": "AAB00116",
        "description": "Astrologer"
    },
    {
        "code": "AAB00117",
        "description": "Astronomer"
    },
    {
        "code": "AAC02602",
        "description": "Atomic Energy Worker"
    },
    {
        "code": "AAB00119",
        "description": "Amusement Arcade Worker"
    },
    {
        "code": "AAB00120",
        "description": "Attendant - Bingo - Entertainment"
    },
    {
        "code": "AAB00121",
        "description": "Attendant - Fairground etc - Entertainment"
    },
    {
        "code": "AAB00122",
        "description": "Auctioneer"
    },
    {
        "code": "AAB00123",
        "description": "Audiometrician"
    },
    {
        "code": "AAB00124",
        "description": "Auditor"
    },
    {
        "code": "AAB00125",
        "description": "Author"
    },
    {
        "code": "AAB00126",
        "description": "Autoclave Operator"
    },
    {
        "code": "AAB00127",
        "description": "Autolysis Man"
    },
    {
        "code": "AAB00128",
        "description": "Automatic Train Attendant"
    },
    {
        "code": "BAB00130",
        "description": "Baggage Handler"
    },
    {
        "code": "BAB00133",
        "description": "Baggage Master"
    },
    {
        "code": "BAB00134",
        "description": "Baggage Porter"
    },
    {
        "code": "BAC02606",
        "description": "Baker"
    },
    {
        "code": "BAB00135",
        "description": "Bakery Equipment Operator"
    },
    {
        "code": "BAC02607",
        "description": "Bakery Shop Manager"
    },
    {
        "code": "BAB00137",
        "description": "Baler"
    },
    {
        "code": "BAB00138",
        "description": "Band Leader"
    },
    {
        "code": "BAB00139",
        "description": "Band Mill Sawyer"
    },
    {
        "code": "BAB00141",
        "description": "Bank Staﬀ"
    },
    {
        "code": "BAB00152",
        "description": "Banksman's Assistant"
    },
    {
        "code": "BAC02609",
        "description": "Bar Manager/Proprietor"
    },
    {
        "code": "BAB00153",
        "description": "Bar Steward"
    },
    {
        "code": "BAC02610",
        "description": "Barber"
    },
    {
        "code": "BAC02611",
        "description": "Barber - Shop Manager/Proprietor"
    },
    {
        "code": "BAB00154",
        "description": "Bargeman - Merchant Marine"
    },
    {
        "code": "BAB00155",
        "description": "Bargeman - Quarrying"
    },
    {
        "code": "BAB00156",
        "description": "Bargemaster"
    },
    {
        "code": "BAB00157",
        "description": "Barley Roaster"
    },
    {
        "code": "BAB00158",
        "description": "Barmaid"
    },
    {
        "code": "BAB00159",
        "description": "Barman"
    },
    {
        "code": "BAB00160",
        "description": "Barrelman"
    },
    {
        "code": "BAC02612",
        "description": "Barrister"
    },
    {
        "code": "BAB00161",
        "description": "Barrister, Advocate"
    },
    {
        "code": "BAB00163",
        "description": "Batman"
    },
    {
        "code": "BAB00164",
        "description": "Battery Assembler"
    },
    {
        "code": "BAB00165",
        "description": "Battery Repairer"
    },
    {
        "code": "BAB00167",
        "description": "Beautician"
    },
    {
        "code": "BAC02613",
        "description": "Beautician Shop Manager/Proprietor"
    },
    {
        "code": "BAC02614",
        "description": "Bed & Breakfast Proprietor"
    },
    {
        "code": "BAB00168",
        "description": "Beekeeper, Apiarist"
    },
    {
        "code": "BAB00169",
        "description": "Belt Maker"
    },
    {
        "code": "BAB00170",
        "description": "Belt Patrol Man"
    },
    {
        "code": "BAB00171",
        "description": "Bench Hand - Production Fitting - Metal Manufacture"
    },
    {
        "code": "BAB00172",
        "description": "Bench Hand - Rubber Industry - Natural"
    },
    {
        "code": "BAB00173",
        "description": "Berthing Superintendent"
    },
    {
        "code": "BAC02615",
        "description": "Betting shop manager (on course)"
    },
    {
        "code": "BAC02616",
        "description": "Betting shop manager (shop based)"
    },
    {
        "code": "BAB00174",
        "description": "Bill Poster/Sticker"
    },
    {
        "code": "BAB00176",
        "description": "Bindery Assistant"
    },
    {
        "code": "BAB00177",
        "description": "Binding Machine Attendant"
    },
    {
        "code": "BAC02618",
        "description": "Biochemist"
    },
    {
        "code": "BAC02619",
        "description": "Biochemist - Lecturing and research"
    },
    {
        "code": "BAC02620",
        "description": "Biological scientist"
    },
    {
        "code": "BAB00178",
        "description": "Biologist (No travel/ underwater)"
    },
    {
        "code": "BAB00179",
        "description": "Biologist (Overseas travel)"
    },
    {
        "code": "BAB00180",
        "description": "Biologist (Underwater work)"
    },
    {
        "code": "BAB00181",
        "description": "Biscuit Baker"
    },
    {
        "code": "BAB00182",
        "description": "Blacksmith"
    },
    {
        "code": "BAB00183",
        "description": "Blancher"
    },
    {
        "code": "BAB00184",
        "description": "Bleacher - Paper & Board Manufacture"
    },
    {
        "code": "BAB00185",
        "description": "Bleacher - Textile & Clothing Industry"
    },
    {
        "code": "BAB00186",
        "description": "Blender"
    },
    {
        "code": "BAB00187",
        "description": "Block Cutter"
    },
    {
        "code": "BAB00188",
        "description": "Boarding School Matron"
    },
    {
        "code": "BAB00189",
        "description": "Boat Builder"
    },
    {
        "code": "BAB00190",
        "description": "Boatswain - Fishing Industry"
    },
    {
        "code": "BAB00191",
        "description": "Boatswain - Merchant Marine"
    },
    {
        "code": "BAB00192",
        "description": "Boatswain's Mate"
    },
    {
        "code": "BAB00193",
        "description": "Bodyguard"
    },
    {
        "code": "BAB00194",
        "description": "Boiler - Confectionery etc - Food & Drink"
    },
    {
        "code": "BAB00195",
        "description": "Boiler - Fruit & Veg. - Food & Drink"
    },
    {
        "code": "BAB00196",
        "description": "Boiler - Meat, Fish etc - Food & Drink"
    },
    {
        "code": "BAB00197",
        "description": "Boiler Cleaner"
    },
    {
        "code": "BAB00198",
        "description": "Boiler Operator - Electrical Supply"
    },
    {
        "code": "BAB00199",
        "description": "Boiler Operator - Water Supply Industry"
    },
    {
        "code": "BAB00200",
        "description": "Boiler Operator/Fireman"
    },
    {
        "code": "BAB00201",
        "description": "Bomb Disposal - Elsewhere"
    },
    {
        "code": "BAB00202",
        "description": "Bomb Disposal - Mainland Britain"
    },
    {
        "code": "BAB00203",
        "description": "Book Illustrator"
    },
    {
        "code": "BAB00205",
        "description": "Bookbinder"
    },
    {
        "code": "BAB00204",
        "description": "Book-Keeper"
    },
    {
        "code": "BAB00206",
        "description": "Bookmaker - On course"
    },
    {
        "code": "BAB00207",
        "description": "Bookmaker - Shop Manager"
    },
    {
        "code": "BAB00208",
        "description": "Bookmaker - Shop Owner"
    },
    {
        "code": "BAB00209",
        "description": "Bookmaker - Shop Staﬀ"
    },
    {
        "code": "BAB00210",
        "description": "Boom Operator"
    },
    {
        "code": "BAB00212",
        "description": "Borer - Mining"
    },
    {
        "code": "BAB00211",
        "description": "Borer - Tunnelling"
    },
    {
        "code": "BAB00213",
        "description": "Borstal Matron"
    },
    {
        "code": "BAB00214",
        "description": "Bosun (Third Hand)"
    },
    {
        "code": "BAB00215",
        "description": "Botanist (No overseas field work)"
    },
    {
        "code": "BAB00216",
        "description": "Bottle Washer (hand or machine)"
    },
    {
        "code": "BAB00217",
        "description": "Bottling Machine Attendant"
    },
    {
        "code": "BAB00218",
        "description": "Box Maker"
    },
    {
        "code": "BAB00224",
        "description": "Box Office Manager"
    },
    {
        "code": "BAB00226",
        "description": "Brakesman"
    },
    {
        "code": "BAB00227",
        "description": "Brazer"
    },
    {
        "code": "BAB00228",
        "description": "Bread Baker"
    },
    {
        "code": "BAB00229",
        "description": "Bread Roundsman"
    },
    {
        "code": "BAB00230",
        "description": "Brewer"
    },
    {
        "code": "BAB00231",
        "description": "Brewery Manager"
    },
    {
        "code": "BAB00232",
        "description": "Bricklayer"
    },
    {
        "code": "BAB00233",
        "description": "Bridge Man"
    },
    {
        "code": "BAB00236",
        "description": "Bronzer"
    },
    {
        "code": "BAB00237",
        "description": "Broom/Brush Maker"
    },
    {
        "code": "BAB00238",
        "description": "Buﬀet Car Attendant"
    },
    {
        "code": "BAC02621",
        "description": "Builder"
    },
    {
        "code": "BAB00239",
        "description": "Building Inspector"
    },
    {
        "code": "BAC02623",
        "description": "Building Site Agent - Building and construction"
    },
    {
        "code": "BAB00241",
        "description": "Building Surveyor"
    },
    {
        "code": "BAB00243",
        "description": "Bulldozer Driver"
    },
    {
        "code": "BAC02625",
        "description": "Burglar Alarm Fitter"
    },
    {
        "code": "BAB00250",
        "description": "Bus Conductor (No driving)"
    },
    {
        "code": "BAB00251",
        "description": "Bus Driver"
    },
    {
        "code": "BAB00252",
        "description": "Bus Inspector"
    },
    {
        "code": "BAB00253",
        "description": "Business Consultant"
    },
    {
        "code": "BAB00255",
        "description": "Butcher"
    },
    {
        "code": "BAC02627",
        "description": "Butcher Shop Proprietor"
    },
    {
        "code": "BAB00256",
        "description": "Butler"
    },
    {
        "code": "BAB00257",
        "description": "Butter Blender"
    },
    {
        "code": "BAB00258",
        "description": "Butter Maker"
    },
    {
        "code": "BAC02628",
        "description": "Buyer - retail"
    },
    {
        "code": "CAB00259",
        "description": "Cabin Boy"
    },
    {
        "code": "CAB00260",
        "description": "Cabinet Maker"
    },
    {
        "code": "CAB00261",
        "description": "Cable Former"
    },
    {
        "code": "CAB00262",
        "description": "Cable Hand"
    },
    {
        "code": "CAB00263",
        "description": "Cable Jointer"
    },
    {
        "code": "CAB00264",
        "description": "Cable Laying Diver"
    },
    {
        "code": "CAB00265",
        "description": "Cable Tester"
    },
    {
        "code": "CAB00266",
        "description": "Cafe Cashier"
    },
    {
        "code": "CAC02629",
        "description": "Cafe Manager"
    },
    {
        "code": "CAB00267",
        "description": "Cafe Proprietor (Licensed)"
    },
    {
        "code": "CAB00268",
        "description": "Cafe Proprietor (Unlicensed)"
    },
    {
        "code": "CAB00270",
        "description": "Calibrator"
    },
    {
        "code": "CAB00271",
        "description": "Caller"
    },
    {
        "code": "CAC02630",
        "description": "Calligrapher"
    },
    {
        "code": "CAC02631",
        "description": "Camera Repair Technician"
    },
    {
        "code": "CAB00273",
        "description": "Cameraman Studio"
    },
    {
        "code": "CAB00275",
        "description": "Candle Maker"
    },
    {
        "code": "CAB00276",
        "description": "Canine Beautician"
    },
    {
        "code": "CAC02632",
        "description": "Canine Behaviourist"
    },
    {
        "code": "CAB00277",
        "description": "Canning Machine Attendant"
    },
    {
        "code": "CAB00278",
        "description": "Canteen Assistant"
    },
    {
        "code": "CAB00279",
        "description": "Canteen Manager"
    },
    {
        "code": "CAB00280",
        "description": "Canvasser"
    },
    {
        "code": "CAB00282",
        "description": "Captain - Merchant Marine"
    },
    {
        "code": "CAB00281",
        "description": "Captain - Oil & Natural Gas Industries"
    },
    {
        "code": "CAB00284",
        "description": "Car Delivery Driver"
    },
    {
        "code": "CAC02633",
        "description": "Car Hire Company Proprietor (admin. and driving)"
    },
    {
        "code": "CAB00285",
        "description": "Car Lasher"
    },
    {
        "code": "CAB00286",
        "description": "Car Park Attendant"
    },
    {
        "code": "CAC02634",
        "description": "Car Rental Company Manager"
    },
    {
        "code": "CAB00287",
        "description": "Car Salesman (S/E or commission)"
    },
    {
        "code": "CAB00288",
        "description": "Car Salesman (Salaried)"
    },
    {
        "code": "CAB00289",
        "description": "Car Valeter"
    },
    {
        "code": "CAB00290",
        "description": "Carbon Printer"
    },
    {
        "code": "CAB00291",
        "description": "Carbonation Man"
    },
    {
        "code": "CAB00292",
        "description": "Carboniser"
    },
    {
        "code": "CAB00293",
        "description": "Care Assistant"
    },
    {
        "code": "CAC02635",
        "description": "Care Worker - Residential"
    },
    {
        "code": "CAC02636",
        "description": "Careers Advisor"
    },
    {
        "code": "CAB00294",
        "description": "Caretaker, Janitor"
    },
    {
        "code": "CAB00295",
        "description": "Cargo Clerk"
    },
    {
        "code": "CAB00296",
        "description": "Cargo Superintendent"
    },
    {
        "code": "CAB00297",
        "description": "Carpenter - Construction Industry"
    },
    {
        "code": "CAB00298",
        "description": "Carpenter - Film Industry - Entertainment"
    },
    {
        "code": "CAB00299",
        "description": "Carpenter & Joiner"
    },
    {
        "code": "CAC02637",
        "description": "Carpet Cleaner"
    },
    {
        "code": "CAC02638",
        "description": "Carpet Company director (office based admin. only)"
    },
    {
        "code": "CAC02639",
        "description": "Carpet Designer"
    },
    {
        "code": "CAB00300",
        "description": "Carpet Fitter"
    },
    {
        "code": "CAC02640",
        "description": "Carpet Salesman"
    },
    {
        "code": "CAC02642",
        "description": "Carpet Shop Assistant"
    },
    {
        "code": "CAC02643",
        "description": "Carpet Shop Manager (admin.)"
    },
    {
        "code": "CAC02644",
        "description": "Carpet Shop Owner (no manual duties)"
    },
    {
        "code": "CAB00301",
        "description": "Carriage Examiner"
    },
    {
        "code": "CAB00302",
        "description": "Carriage Cleaner"
    },
    {
        "code": "CAB00303",
        "description": "Cartographer"
    },
    {
        "code": "CAB00304",
        "description": "Cartoonist"
    },
    {
        "code": "CAB00305",
        "description": "Cartridge Filler"
    },
    {
        "code": "CAB00306",
        "description": "Car Wash Attendant"
    },
    {
        "code": "CAC02645",
        "description": "Cashier - Bank, building society"
    },
    {
        "code": "CAC02646",
        "description": "Cashier - Shop, cafe, supermarket, bingo"
    },
    {
        "code": "CAB00309",
        "description": "Casino Cashier"
    },
    {
        "code": "CAB00310",
        "description": "Caster"
    },
    {
        "code": "CAB00311",
        "description": "Casting Director"
    },
    {
        "code": "CAB00312",
        "description": "Casting Machine Operator"
    },
    {
        "code": "CAC02647",
        "description": "Caterer - oﬀshore/at sea"
    },
    {
        "code": "CAC02648",
        "description": "Catering assistant"
    },
    {
        "code": "CAC02649",
        "description": "Catering manager"
    },
    {
        "code": "CAB00317",
        "description": "Cathead Man"
    },
    {
        "code": "CAB00323",
        "description": "Ceiling Fixer"
    },
    {
        "code": "CAB00324",
        "description": "Cell Tester"
    },
    {
        "code": "CAB00325",
        "description": "Cementer"
    },
    {
        "code": "CAB00326",
        "description": "Ceramicist"
    },
    {
        "code": "CAB00328",
        "description": "Chain Maker"
    },
    {
        "code": "CAB00329",
        "description": "Chair Maker"
    },
    {
        "code": "CAC02653",
        "description": "Chambermaid - Housekeeper"
    },
    {
        "code": "CAB00330",
        "description": "Charge Nurse"
    },
    {
        "code": "CAC02654",
        "description": "Chartered Engineer (some site duties)"
    },
    {
        "code": "CAC02655",
        "description": "Chartered Engineered (admin. only)"
    },
    {
        "code": "CAC02656",
        "description": "Chartered Surveyor (admin only)"
    },
    {
        "code": "CAC02657",
        "description": "Chartered Surveyor (some site duties)"
    },
    {
        "code": "CAB00332",
        "description": "Chassis Builder"
    },
    {
        "code": "CAB00333",
        "description": "Chauﬀeur"
    },
    {
        "code": "CAB00336",
        "description": "Chef"
    },
    {
        "code": "CAB00340",
        "description": "Chemical engineer - oﬀshore"
    },
    {
        "code": "CAB00344",
        "description": "Chemical Plumber"
    },
    {
        "code": "CAB00345",
        "description": "Chemical Plumber's Mate"
    },
    {
        "code": "CAC02658",
        "description": "Child Protection Co-ordinator"
    },
    {
        "code": "CAC02659",
        "description": "Child Welfare Officer"
    },
    {
        "code": "CAB00368",
        "description": "Childminder"
    },
    {
        "code": "CAB00370",
        "description": "Children's Inspector"
    },
    {
        "code": "CAB00371",
        "description": "Children's Matron"
    },
    {
        "code": "CAC02660",
        "description": "Children's Nursery Proprietor"
    },
    {
        "code": "CAC02661",
        "description": "Children's Play-group Leader"
    },
    {
        "code": "CAB00372",
        "description": "Chimney Sweep"
    },
    {
        "code": "CAC02662",
        "description": "Chip Shop Owner"
    },
    {
        "code": "CAB00373",
        "description": "Chip/Money Changer"
    },
    {
        "code": "CAB00374",
        "description": "Chipper & Painter"
    },
    {
        "code": "CAB00375",
        "description": "Chipper (hand)"
    },
    {
        "code": "CAB00376",
        "description": "Chipping Driver"
    },
    {
        "code": "CAB00377",
        "description": "Chiropodist"
    },
    {
        "code": "CAB00378",
        "description": "Chiropracter"
    },
    {
        "code": "CAC02664",
        "description": "Church Organist"
    },
    {
        "code": "CAB00383",
        "description": "Circus Hand"
    },
    {
        "code": "CAB00390",
        "description": "Claims Adjuster"
    },
    {
        "code": "CAB00391",
        "description": "Claims Assessor"
    },
    {
        "code": "CAC02666",
        "description": "Cleaner - domestic premises"
    },
    {
        "code": "CAB00405",
        "description": "Clergy"
    },
    {
        "code": "CAB00406",
        "description": "Clerical Staﬀ"
    },
    {
        "code": "CAB00407",
        "description": "Clerical Worker"
    },
    {
        "code": "CAB00409",
        "description": "Clerk of Works"
    },
    {
        "code": "CAB00412",
        "description": "Cloakroom Attendant - Club/Nightclub - Entertainment"
    },
    {
        "code": "CAB00413",
        "description": "Cloakroom Attendant - Theatre, Ballet etc - Entertainment"
    },
    {
        "code": "CAB00414",
        "description": "Clock & Watch Assembler"
    },
    {
        "code": "CAB00415",
        "description": "Clock/Watch Maker"
    },
    {
        "code": "CAB00416",
        "description": "Clock/Watch Repairer"
    },
    {
        "code": "CAB00417",
        "description": "Cloth Cutter"
    },
    {
        "code": "CAC02671",
        "description": "Clothing Designer"
    },
    {
        "code": "CAB00418",
        "description": "Clown"
    },
    {
        "code": "CAB00419",
        "description": "Club Manager"
    },
    {
        "code": "CAB00420",
        "description": "Club Proprietor"
    },
    {
        "code": "CAB00421",
        "description": "Club Steward"
    },
    {
        "code": "CAB00427",
        "description": "Coach Driver"
    },
    {
        "code": "CAB00428",
        "description": "Coach Painter"
    },
    {
        "code": "CAB00431",
        "description": "Coal Cutter Mover"
    },
    {
        "code": "CAB00432",
        "description": "Coal Cutterman"
    },
    {
        "code": "CAB00433",
        "description": "Coal Dry Cleaning Plant Operator"
    },
    {
        "code": "CAB00434",
        "description": "Coal Face Workers"
    },
    {
        "code": "CAB00435",
        "description": "Coal Melter"
    },
    {
        "code": "CAB00436",
        "description": "Coal Merchant - admin only"
    },
    {
        "code": "CAB00437",
        "description": "Coal Merchant - some delivery"
    },
    {
        "code": "CAB00438",
        "description": "Coal Trimmer"
    },
    {
        "code": "CAB00439",
        "description": "Coal Washery Operator"
    },
    {
        "code": "CAB00440",
        "description": "Coal Yard Foreman"
    },
    {
        "code": "CAB00441",
        "description": "Coal Yard Man"
    },
    {
        "code": "CAB00442",
        "description": "Coastguard (Office based)"
    },
    {
        "code": "CAB00443",
        "description": "Coastguard (Otherwise)"
    },
    {
        "code": "CAB00444",
        "description": "Coffin Maker"
    },
    {
        "code": "CAB00445",
        "description": "Coil Former"
    },
    {
        "code": "CAB00446",
        "description": "Coil Winder"
    },
    {
        "code": "CAB00447",
        "description": "College Lecturer"
    },
    {
        "code": "CAB00449",
        "description": "Colour Calculator"
    },
    {
        "code": "CAB00450",
        "description": "Colour Matcher"
    },
    {
        "code": "CAB00451",
        "description": "Colour Mixer"
    },
    {
        "code": "CAB00452",
        "description": "Columnist"
    },
    {
        "code": "CAB00453",
        "description": "Comedian"
    },
    {
        "code": "CAB00454",
        "description": "Commentator - no overseas travel etc"
    },
    {
        "code": "CAB00455",
        "description": "Commentator - otherwise"
    },
    {
        "code": "CAB00456",
        "description": "Commercial Diving"
    },
    {
        "code": "CAC02674",
        "description": "Commercial Manager (office sales)"
    },
    {
        "code": "CAB00457",
        "description": "Commercial Pilots"
    },
    {
        "code": "CAC02675",
        "description": "Commercial Traveller"
    },
    {
        "code": "CAB00458",
        "description": "Commissionaire"
    },
    {
        "code": "CAB00460",
        "description": "Community Nurse"
    },
    {
        "code": "CAC02678",
        "description": "Company Director (admin. duties only)"
    },
    {
        "code": "CAC02679",
        "description": "Company Secretary"
    },
    {
        "code": "CAB00462",
        "description": "Compass Adjuster"
    },
    {
        "code": "CAC02680",
        "description": "Compliance Manager"
    },
    {
        "code": "CAB00463",
        "description": "Composer"
    },
    {
        "code": "CAB00464",
        "description": "Compositor"
    },
    {
        "code": "CAB00465",
        "description": "Compounder"
    },
    {
        "code": "CAC02681",
        "description": "Computer Analyst"
    },
    {
        "code": "CAC02682",
        "description": "Computer Company Technical Support Manager"
    },
    {
        "code": "CAB00466",
        "description": "Computer Operator"
    },
    {
        "code": "CAC02683",
        "description": "Computer Programmer"
    },
    {
        "code": "CAB00467",
        "description": "Computer Programmer/Analyst"
    },
    {
        "code": "CAC02684",
        "description": "Computer Salesman (office based)"
    },
    {
        "code": "CAC02685",
        "description": "Computer Software Manager"
    },
    {
        "code": "CAC02686",
        "description": "Computer Software Salesman (includes travelling)"
    },
    {
        "code": "CAC02687",
        "description": "Computer Systems Installer"
    },
    {
        "code": "CAC02688",
        "description": "Computer Wirer"
    },
    {
        "code": "CAC02689",
        "description": "Computer Workshop Technical Engineer"
    },
    {
        "code": "CAC02690",
        "description": "Concert Promoter"
    },
    {
        "code": "CAB00468",
        "description": "Concrete Erector - 40' up"
    },
    {
        "code": "CAB00469",
        "description": "Concrete Erector - up to 40'"
    },
    {
        "code": "CAB00470",
        "description": "Concrete Finisher"
    },
    {
        "code": "CAB00471",
        "description": "Concrete Paving Driver"
    },
    {
        "code": "CAB00472",
        "description": "Concrete Shutterer"
    },
    {
        "code": "CAB00473",
        "description": "Concreter"
    },
    {
        "code": "CAB00474",
        "description": "Conductor - Music Industry - Entertainment"
    },
    {
        "code": "CAB00475",
        "description": "Conductor - Train Crew - Railways"
    },
    {
        "code": "CAC02691",
        "description": "Confectioner"
    },
    {
        "code": "CAC02692",
        "description": "Conference Organising Assistant"
    },
    {
        "code": "CAC02693",
        "description": "Conference Organising Manager"
    },
    {
        "code": "CAB00476",
        "description": "Conjurer"
    },
    {
        "code": "CAB00478",
        "description": "Construction Work"
    },
    {
        "code": "CAB00480",
        "description": "Contact Lens Technician"
    },
    {
        "code": "CAB00481",
        "description": "Continuity Clerk"
    },
    {
        "code": "CAB00482",
        "description": "Control Engineer"
    },
    {
        "code": "CAB00508",
        "description": "Cook"
    },
    {
        "code": "CAB00514",
        "description": "Cooper"
    },
    {
        "code": "CAB00515",
        "description": "Coppersmith"
    },
    {
        "code": "CAB00516",
        "description": "Copyholder"
    },
    {
        "code": "CAB00517",
        "description": "Copyholder (Newspapers etc)"
    },
    {
        "code": "CAB00518",
        "description": "Copytaster"
    },
    {
        "code": "CAC02695",
        "description": "Copywriter"
    },
    {
        "code": "CAB00519",
        "description": "Core Borer"
    },
    {
        "code": "CAB00520",
        "description": "Core Builder"
    },
    {
        "code": "CAB00521",
        "description": "Coremaker"
    },
    {
        "code": "CAB00522",
        "description": "Coroner"
    },
    {
        "code": "CAB00524",
        "description": "Correspondent - no overseas travel etc - Journalism"
    },
    {
        "code": "CAB00523",
        "description": "Correspondent - no overseas travel etc - Radio & TV - Entertainment"
    },
    {
        "code": "CAB00526",
        "description": "Correspondent - otherwise - Journalism"
    },
    {
        "code": "CAB00525",
        "description": "Correspondent - otherwise - Radio & TV - Entertainment"
    },
    {
        "code": "CAB00527",
        "description": "Costermonger"
    },
    {
        "code": "CAB00528",
        "description": "Costume Designer"
    },
    {
        "code": "CAC02696",
        "description": "Costumier"
    },
    {
        "code": "CAB00529",
        "description": "Counsellor"
    },
    {
        "code": "CAB00530",
        "description": "Counter Staﬀ - Dry Cleaning"
    },
    {
        "code": "CAB00531",
        "description": "Counter Staﬀ - Laundry"
    },
    {
        "code": "CAB00532",
        "description": "Counter Staﬀ - Post Office/Telecommunications"
    },
    {
        "code": "CAC02697",
        "description": "Couriers"
    },
    {
        "code": "CAB00533",
        "description": "Court Bailiﬀ"
    },
    {
        "code": "CAB00534",
        "description": "Court Usher"
    },
    {
        "code": "CAB00535",
        "description": "Crab Fisherman"
    },
    {
        "code": "CAB00542",
        "description": "Crane Erector"
    },
    {
        "code": "CAC02698",
        "description": "Credit Agent"
    },
    {
        "code": "CAC02699",
        "description": "Credit Controller"
    },
    {
        "code": "CAB00559",
        "description": "Critic"
    },
    {
        "code": "CAB00562",
        "description": "Crossing Keeper"
    },
    {
        "code": "CAB00563",
        "description": "Croupier"
    },
    {
        "code": "CAB00566",
        "description": "Crushing Worker"
    },
    {
        "code": "CAB00567",
        "description": "Curator - Zoo"
    },
    {
        "code": "CAC02700",
        "description": "Curator (museum)"
    },
    {
        "code": "CAB00568",
        "description": "Curer"
    },
    {
        "code": "CAC02701",
        "description": "Customer Care Officer"
    },
    {
        "code": "DAC02702",
        "description": "Dairyman"
    },
    {
        "code": "DAB00582",
        "description": "Dancer"
    },
    {
        "code": "DAC02703",
        "description": "Dancing Teacher"
    },
    {
        "code": "DAB00585",
        "description": "Debt Collector"
    },
    {
        "code": "DAB00586",
        "description": "Deck Chair Attendant"
    },
    {
        "code": "DAB00587",
        "description": "Deck Hand"
    },
    {
        "code": "DAB00589",
        "description": "Deck Officer"
    },
    {
        "code": "DAC02706",
        "description": "Decorator"
    },
    {
        "code": "DAB00593",
        "description": "Delivery Driver"
    },
    {
        "code": "DAB00594",
        "description": "Demolition Worker-no explosives"
    },
    {
        "code": "DAB00595",
        "description": "Demolition Worker-using explosives"
    },
    {
        "code": "DAB00598",
        "description": "Dental Assistant"
    },
    {
        "code": "DAC02707",
        "description": "Dental Auxiliary"
    },
    {
        "code": "DAB00599",
        "description": "Dental Consultant"
    },
    {
        "code": "DAB00600",
        "description": "Dental Hygienist"
    },
    {
        "code": "DAB00601",
        "description": "Dental Nurse"
    },
    {
        "code": "DAB00602",
        "description": "Dental Practitioner"
    },
    {
        "code": "DAB00603",
        "description": "Dental Technician"
    },
    {
        "code": "DAB00604",
        "description": "Dental Therapist"
    },
    {
        "code": "DAB00605",
        "description": "Dentist"
    },
    {
        "code": "DAC02708",
        "description": "Dermatologist"
    },
    {
        "code": "DAB00612",
        "description": "Designer"
    },
    {
        "code": "DAB00616",
        "description": "Detention Centre Warden"
    },
    {
        "code": "DAB00626",
        "description": "Die Cutter"
    },
    {
        "code": "DAB00627",
        "description": "Die Setter"
    },
    {
        "code": "DAB00629",
        "description": "Diesel Locomotive Fitter"
    },
    {
        "code": "DAC02709",
        "description": "Dietician"
    },
    {
        "code": "DAB00631",
        "description": "Dining Car Attendant"
    },
    {
        "code": "ZAD03385",
        "description": "Zoo Director"
    },
    {
        "code": "DAC02710",
        "description": "Director & Medical Consultant"
    },
    {
        "code": "DAB00637",
        "description": "Disc Jockey"
    },
    {
        "code": "DAB00638",
        "description": "Disinfecting Officer"
    },
    {
        "code": "DAB00639",
        "description": "Disinfestor"
    },
    {
        "code": "DAC02711",
        "description": "Dispatch Rider"
    },
    {
        "code": "DAB00640",
        "description": "Dispatcher"
    },
    {
        "code": "DAC02712",
        "description": "Distiller"
    },
    {
        "code": "DAB00641",
        "description": "Distillery Manager"
    },
    {
        "code": "DAB00642",
        "description": "District Nurse"
    },
    {
        "code": "DAC02713",
        "description": "Diver"
    },
    {
        "code": "DAC02714",
        "description": "Diver (North Sea)"
    },
    {
        "code": "DAB00643",
        "description": "Diver's Linesman -Coastal etc"
    },
    {
        "code": "DAB00644",
        "description": "Diver's Linesman -Deep Sea"
    },
    {
        "code": "DAB00647",
        "description": "Dock Foreman"
    },
    {
        "code": "DAB00648",
        "description": "Dock Master"
    },
    {
        "code": "DAB00649",
        "description": "Dock Superintendent"
    },
    {
        "code": "DAB00650",
        "description": "Docker"
    },
    {
        "code": "DAB00651",
        "description": "Doctor - Health"
    },
    {
        "code": "DAB00652",
        "description": "Doctor - Merchant Marine"
    },
    {
        "code": "DAC02715",
        "description": "Dog Catcher"
    },
    {
        "code": "DAB00655",
        "description": "Dogger"
    },
    {
        "code": "DAB00656",
        "description": "Domestic Electrician"
    },
    {
        "code": "DAB00657",
        "description": "Domestic Premises Cleaner"
    },
    {
        "code": "DAB00658",
        "description": "Domestic Supervisor (Hospital)"
    },
    {
        "code": "DAC02716",
        "description": "Domestic Tiler"
    },
    {
        "code": "DAB00659",
        "description": "Donkeyman"
    },
    {
        "code": "DAB00660",
        "description": "Door to Door Salesman"
    },
    {
        "code": "DAC02717",
        "description": "Double Glazing - Installer/fitter"
    },
    {
        "code": "DAC02719",
        "description": "Double Glazing Surveyor"
    },
    {
        "code": "DAC02720",
        "description": "Draper"
    },
    {
        "code": "DAB00665",
        "description": "Draughtsman"
    },
    {
        "code": "DAB00666",
        "description": "Drawer (Bar, Plate, Rod, etc)"
    },
    {
        "code": "DAB00667",
        "description": "Drayman"
    },
    {
        "code": "DAB00668",
        "description": "Dredger Driver"
    },
    {
        "code": "DAB00669",
        "description": "Dredgerman"
    },
    {
        "code": "DAB00672",
        "description": "Dresser"
    },
    {
        "code": "DAB00673",
        "description": "Dressmaker"
    },
    {
        "code": "DAC02724",
        "description": "Driver - refuse"
    },
    {
        "code": "DAC02725",
        "description": "Driver - tractor"
    },
    {
        "code": "DAB00699",
        "description": "Driving Examiner"
    },
    {
        "code": "DAB00700",
        "description": "Driving Instructor"
    },
    {
        "code": "DAB00701",
        "description": "Drop Ball Operator"
    },
    {
        "code": "DAB00702",
        "description": "Dry Cleaning Machine Operator"
    },
    {
        "code": "DAB00703",
        "description": "Dry Salter"
    },
    {
        "code": "DAB00708",
        "description": "Dustman/Refuse Collector"
    },
    {
        "code": "DAB00710",
        "description": "Dyer"
    },
    {
        "code": "EAB00712",
        "description": "Economist"
    },
    {
        "code": "EAC02729",
        "description": "Educational Advisor"
    },
    {
        "code": "EAB00715",
        "description": "Eﬄuent Inspector"
    },
    {
        "code": "EAB00716",
        "description": "Electric Logger"
    },
    {
        "code": "EAB00717",
        "description": "Electrical Contractor"
    },
    {
        "code": "EAB00722",
        "description": "Electrical Fitter"
    },
    {
        "code": "EAB00724",
        "description": "Electrical Wireman"
    },
    {
        "code": "EAB00736",
        "description": "Electronics Fitter"
    },
    {
        "code": "EAB00737",
        "description": "Electronics Installer"
    },
    {
        "code": "EAB00738",
        "description": "Electronics Mechanic"
    },
    {
        "code": "EAB00739",
        "description": "Electronics Repairer"
    },
    {
        "code": "EAB00740",
        "description": "Electronics Service Mechanic"
    },
    {
        "code": "EAB00741",
        "description": "Electronics Wireman"
    },
    {
        "code": "EAB00742",
        "description": "Electroplater"
    },
    {
        "code": "EAB00743",
        "description": "Electrotyper"
    },
    {
        "code": "EAB00744",
        "description": "Embalmer"
    },
    {
        "code": "EAB00745",
        "description": "Embroiderer"
    },
    {
        "code": "EAC02740",
        "description": "Employment Agency Owner (admin. only)"
    },
    {
        "code": "EAB00746",
        "description": "Enameller"
    },
    {
        "code": "EAB00747",
        "description": "Engine Driver"
    },
    {
        "code": "EAB00748",
        "description": "Engine Tester"
    },
    {
        "code": "EAC02742",
        "description": "Engineer - oﬀshore"
    },
    {
        "code": "EAB00762",
        "description": "Engineering Fitter"
    },
    {
        "code": "EAB00764",
        "description": "Engineering Technician"
    },
    {
        "code": "EAB00770",
        "description": "Enrolled Nurse"
    },
    {
        "code": "EAC02746",
        "description": "Entertainer - Entertainment industry"
    },
    {
        "code": "EAC02747",
        "description": "Entertainment Agent - Entertainment industry"
    },
    {
        "code": "EAC02748",
        "description": "Entertainment Manager - Entertainment industry"
    },
    {
        "code": "EAB00771",
        "description": "Entertainments Officer"
    },
    {
        "code": "EAC02749",
        "description": "Environmental Health Officer"
    },
    {
        "code": "EAB00772",
        "description": "Equestrian Artiste"
    },
    {
        "code": "EAB00773",
        "description": "Equestrianism - Riding Instructor"
    },
    {
        "code": "EAB00774",
        "description": "Equestrianism - Show Jumping"
    },
    {
        "code": "EAB00775",
        "description": "Equipment Cleaner"
    },
    {
        "code": "EAB00776",
        "description": "Erector - Aircraft/Aerospace"
    },
    {
        "code": "EAB00777",
        "description": "Erector - Production Fitting - Metal Manufacture"
    },
    {
        "code": "EAB00778",
        "description": "Escapologist"
    },
    {
        "code": "EAB00779",
        "description": "Estate Agent"
    },
    {
        "code": "EAC02750",
        "description": "Estate Manager - all aspects (no manual work)"
    },
    {
        "code": "EAB00780",
        "description": "Estate Ranger"
    },
    {
        "code": "EAB00781",
        "description": "Estimator"
    },
    {
        "code": "EAC02751",
        "description": "Estimator (mainly office duties)"
    },
    {
        "code": "EAB00783",
        "description": "Etcher - Pottery Industry"
    },
    {
        "code": "EAB00782",
        "description": "Etcher - Precious Metals, Engraving etc - Metal Manufacture"
    },
    {
        "code": "EAB00784",
        "description": "Etcher - Printing Industry"
    },
    {
        "code": "EAB00785",
        "description": "Etcher (creative)"
    },
    {
        "code": "EAB00795",
        "description": "Excavator Driver"
    },
    {
        "code": "EAB00796",
        "description": "Exhausterman"
    },
    {
        "code": "EAC02752",
        "description": "Exhibition Foreman"
    },
    {
        "code": "EAC02753",
        "description": "Exhibition Space Sales Manager"
    },
    {
        "code": "EAB00797",
        "description": "Exhibition Stand Fitter"
    },
    {
        "code": "EAB00798",
        "description": "Explosives Inspector"
    },
    {
        "code": "EAC02754",
        "description": "Export Agent"
    },
    {
        "code": "EAB00800",
        "description": "Extruder"
    },
    {
        "code": "FAB00803",
        "description": "Facilities Procurement Officer"
    },
    {
        "code": "FAC02755",
        "description": "Factory (worker)"
    },
    {
        "code": "FAB00806",
        "description": "Factory Inspector"
    },
    {
        "code": "FAC02756",
        "description": "Factory Manager (mainly admin.)"
    },
    {
        "code": "FAC02757",
        "description": "Fairground Worker"
    },
    {
        "code": "FAC02760",
        "description": "Farm Manager (manual duties)"
    },
    {
        "code": "FAC02761",
        "description": "Farm Manager (no manual duties)"
    },
    {
        "code": "FAC02762",
        "description": "Farm Owner (manual duties)"
    },
    {
        "code": "FAC02763",
        "description": "Farm Owner (no manual duties)"
    },
    {
        "code": "FAC02764",
        "description": "Farm Worker/Labourer"
    },
    {
        "code": "FAB00811",
        "description": "Farrier"
    },
    {
        "code": "FAB00812",
        "description": "Fashion Model"
    },
    {
        "code": "FAB00813",
        "description": "Fashion Photographer"
    },
    {
        "code": "FAC02767",
        "description": "Fast Food Restaurant Manager (admin. only)"
    },
    {
        "code": "FAB00814",
        "description": "Fat Extractor Man"
    },
    {
        "code": "FAC02768",
        "description": "Fencing Contractor"
    },
    {
        "code": "FAB00816",
        "description": "Ferryman"
    },
    {
        "code": "FAB00821",
        "description": "Fight Arranger"
    },
    {
        "code": "FAB00823",
        "description": "Film Developer"
    },
    {
        "code": "FAC02771",
        "description": "Director - TV and film"
    },
    {
        "code": "FAB00824",
        "description": "Film Joiner"
    },
    {
        "code": "FAB00825",
        "description": "Film Processor"
    },
    {
        "code": "FAB00827",
        "description": "Filmsetting Machine Operator"
    },
    {
        "code": "FAC02774",
        "description": "Financial Adviser"
    },
    {
        "code": "FAB00832",
        "description": "Fire Eater"
    },
    {
        "code": "FAB00833",
        "description": "Fire Prevention Officer"
    },
    {
        "code": "FAB00835",
        "description": "Firefighter - Fire Service"
    },
    {
        "code": "FAB00843",
        "description": "Fish Farmer"
    },
    {
        "code": "FAB00848",
        "description": "Fish Preparer"
    },
    {
        "code": "FAB00849",
        "description": "Fisherman"
    },
    {
        "code": "FAB00850",
        "description": "Fishery Officer/Warden"
    },
    {
        "code": "FAC02779",
        "description": "Fishmonger"
    },
    {
        "code": "FAC02782",
        "description": "Fitness instructor"
    },
    {
        "code": "FAB00852",
        "description": "Fitter - Motor Vehicle & Cycle Industry"
    },
    {
        "code": "FAB00855",
        "description": "Fitter-Assembler"
    },
    {
        "code": "FAB00858",
        "description": "Fixer Mason"
    },
    {
        "code": "FAB00864",
        "description": "Flame cutter - 40 ft +"
    },
    {
        "code": "FAB00865",
        "description": "Flame cutter - under 40 ft"
    },
    {
        "code": "FAB00867",
        "description": "Flight Dispatcher"
    },
    {
        "code": "FAB00869",
        "description": "Flight Planner"
    },
    {
        "code": "FAB00870",
        "description": "Floor Layer"
    },
    {
        "code": "FAB00871",
        "description": "Floor Manager"
    },
    {
        "code": "FAB00872",
        "description": "Floor Tiler"
    },
    {
        "code": "FAB00873",
        "description": "Floorman"
    },
    {
        "code": "FAC02785",
        "description": "Floorman - Oil Rig Industry"
    },
    {
        "code": "FAC02786",
        "description": "Florist"
    },
    {
        "code": "FAB00874",
        "description": "Flour Confectioner"
    },
    {
        "code": "FAB00876",
        "description": "Food Technologist"
    },
    {
        "code": "FAC02787",
        "description": "Football Manager - Professional players"
    },
    {
        "code": "FAC02788",
        "description": "Forecourt Attendant"
    },
    {
        "code": "FAB00937",
        "description": "Forest Ranger"
    },
    {
        "code": "FAB00938",
        "description": "Forest Worker"
    },
    {
        "code": "FAB00940",
        "description": "Forestry Officer"
    },
    {
        "code": "FAB00941",
        "description": "Forge Hammerman"
    },
    {
        "code": "FAB00942",
        "description": "Forge Pressman"
    },
    {
        "code": "FAB00943",
        "description": "Forger"
    },
    {
        "code": "FAB00944",
        "description": "Fork Lift Truck Driver"
    },
    {
        "code": "FAB00945",
        "description": "Fortune Teller"
    },
    {
        "code": "FAB00946",
        "description": "Frame Finisher"
    },
    {
        "code": "FAB00947",
        "description": "Freezer Operator"
    },
    {
        "code": "FAB00948",
        "description": "Freight Clerk"
    },
    {
        "code": "FAB00949",
        "description": "Freight Manager - Airport"
    },
    {
        "code": "FAB00950",
        "description": "Freight Manager - Docks"
    },
    {
        "code": "FAB00951",
        "description": "French Polisher"
    },
    {
        "code": "FAC02793",
        "description": "Fruitier"
    },
    {
        "code": "FAB00952",
        "description": "Fuel Technologist"
    },
    {
        "code": "FAB00953",
        "description": "Funeral Director"
    },
    {
        "code": "FAB00954",
        "description": "Funeral Director's Assistant"
    },
    {
        "code": "FAB00955",
        "description": "Furnace Control Room Operator"
    },
    {
        "code": "FAB00956",
        "description": "Furnace Operator - Cemetery, Crematorium"
    },
    {
        "code": "FAB00961",
        "description": "Furniture Designer"
    },
    {
        "code": "FAC02794",
        "description": "Furniture Maker"
    },
    {
        "code": "FAB00962",
        "description": "Furniture Remover"
    },
    {
        "code": "FAC02795",
        "description": "Furniture Restorer"
    },
    {
        "code": "FAC02796",
        "description": "Furniture Retailer"
    },
    {
        "code": "GAC02797",
        "description": "Gallery Owner (admin. only)"
    },
    {
        "code": "GAC02798",
        "description": "Gallery Owner (including manual work)"
    },
    {
        "code": "GAB00965",
        "description": "Galvaniser"
    },
    {
        "code": "GAB00966",
        "description": "Gamekeeper"
    },
    {
        "code": "GAB00968",
        "description": "Ganger"
    },
    {
        "code": "GAC02800",
        "description": "Garage - Mechanic"
    },
    {
        "code": "GAC02801",
        "description": "Garage - Petrol Pump Attendant"
    },
    {
        "code": "GAC02802",
        "description": "Garage Proprietor - admin only"
    },
    {
        "code": "GAC02803",
        "description": "Garage Repair Shop Supervisor (including manual duties)"
    },
    {
        "code": "GAC02804",
        "description": "Gardener"
    },
    {
        "code": "GAB00971",
        "description": "Gas Appliance Mechanic"
    },
    {
        "code": "GAB00972",
        "description": "Gas Compressor Operator"
    },
    {
        "code": "GAB00976",
        "description": "Gas Fitter"
    },
    {
        "code": "GAB00979",
        "description": "Gas Meter Tester"
    },
    {
        "code": "GAB00984",
        "description": "Gem Cutter"
    },
    {
        "code": "GAB00985",
        "description": "Gem Polisher"
    },
    {
        "code": "GAB00986",
        "description": "Gem Setter"
    },
    {
        "code": "GAB00987",
        "description": "Geologist - Mining"
    },
    {
        "code": "GAB00989",
        "description": "Geologist - no aerial/oﬀshore etc"
    },
    {
        "code": "GAB00988",
        "description": "Geologist - Oil & Natural Gas Industries"
    },
    {
        "code": "GAB00990",
        "description": "Geophysicist - Mining"
    },
    {
        "code": "GAB00991",
        "description": "Geophysicist - Oil & Natural Gas Industries"
    },
    {
        "code": "GAC02806",
        "description": "Glass Blower"
    },
    {
        "code": "GAB00996",
        "description": "Glazer"
    },
    {
        "code": "GAB01000",
        "description": "Gold Beater"
    },
    {
        "code": "GAC02807",
        "description": "Goldsmith"
    },
    {
        "code": "GAB01001",
        "description": "Golf - Caddie"
    },
    {
        "code": "GAB01003",
        "description": "Governor"
    },
    {
        "code": "GAB01004",
        "description": "Grader"
    },
    {
        "code": "GAC02808",
        "description": "Grain Merchant (office based)"
    },
    {
        "code": "GAC02809",
        "description": "Graphic Designer"
    },
    {
        "code": "GAB01007",
        "description": "Grave Digger"
    },
    {
        "code": "GAB01010",
        "description": "Greenkeeper"
    },
    {
        "code": "GAC02813",
        "description": "Grocer"
    },
    {
        "code": "GAB01019",
        "description": "Groom"
    },
    {
        "code": "GAB01020",
        "description": "Ground Equipment Service Mechanic"
    },
    {
        "code": "GAB01021",
        "description": "Ground Hostess/Steward"
    },
    {
        "code": "GAB01022",
        "description": "Ground Movement Controller"
    },
    {
        "code": "GAB01023",
        "description": "Groundsman"
    },
    {
        "code": "GAC02815",
        "description": "Guest House Proprietor"
    },
    {
        "code": "GAC02816",
        "description": "Guest House Proprietor (admin. only)"
    },
    {
        "code": "GAB01025",
        "description": "Guillotine Operator"
    },
    {
        "code": "GAB01026",
        "description": "Gummer"
    },
    {
        "code": "HAC02817",
        "description": "Haberdasher"
    },
    {
        "code": "HAB01028",
        "description": "Hairdresser - Mobile"
    },
    {
        "code": "HAB01029",
        "description": "Hairdresser - Salon"
    },
    {
        "code": "HAC02818",
        "description": "Hairdresser Shop Manager - admin only"
    },
    {
        "code": "HAC02819",
        "description": "Hairdresser Shop Proprietor"
    },
    {
        "code": "HAB01037",
        "description": "Handyman"
    },
    {
        "code": "HAB01038",
        "description": "Harbour Master"
    },
    {
        "code": "HAC02820",
        "description": "Harbour Pilot"
    },
    {
        "code": "HAB01041",
        "description": "Harness Maker"
    },
    {
        "code": "HAB01043",
        "description": "Hat Maker"
    },
    {
        "code": "HAB01044",
        "description": "Hatchery Worker"
    },
    {
        "code": "HAC02822",
        "description": "Haulage Contractor"
    },
    {
        "code": "HAB01046",
        "description": "Haulier (no driving)"
    },
    {
        "code": "HAB01047",
        "description": "Head Gardener"
    },
    {
        "code": "HAB01048",
        "description": "Head Groundsman"
    },
    {
        "code": "HAB01049",
        "description": "Head Keeper - Zoo"
    },
    {
        "code": "HAB01050",
        "description": "Head Roustabout"
    },
    {
        "code": "HAC02823",
        "description": "Headteacher"
    },
    {
        "code": "HAC02824",
        "description": "Health & Safety Officer"
    },
    {
        "code": "HAC02825",
        "description": "Health and Fitness Club Manager (admin. only)"
    },
    {
        "code": "HAC02826",
        "description": "Health and Fitness Club Trainer"
    },
    {
        "code": "HAC02827",
        "description": "Health Counsellor"
    },
    {
        "code": "HAB01053",
        "description": "Health Radiation Monitor"
    },
    {
        "code": "HAC02828",
        "description": "Health Visitor"
    },
    {
        "code": "HAC02829",
        "description": "Heating and Ventilating Fitter"
    },
    {
        "code": "HAC02830",
        "description": "Heavy Goods Driver (no loading) UK only"
    },
    {
        "code": "HAB01064",
        "description": "Heavy Goods Vehicle Driver"
    },
    {
        "code": "HAC02831",
        "description": "Helicopter Engineer"
    },
    {
        "code": "HAC02832",
        "description": "Helicopter Pilot - Oil Rig Industry"
    },
    {
        "code": "HAC02833",
        "description": "Helicopter Pilot - Onshore"
    },
    {
        "code": "HAB01066",
        "description": "Historic Building Guide"
    },
    {
        "code": "HAB01067",
        "description": "Hoist Driver"
    },
    {
        "code": "HAC02834",
        "description": "Home Help"
    },
    {
        "code": "HAC02835",
        "description": "Homeless Centre Manager (admin. only)"
    },
    {
        "code": "HAC02836",
        "description": "Homeopath"
    },
    {
        "code": "HAB01076",
        "description": "Horse Breeder"
    },
    {
        "code": "HAB01077",
        "description": "Horse Racing - Flat Jockey"
    },
    {
        "code": "HAB01078",
        "description": "Horse racing - National Hunt"
    },
    {
        "code": "HAC02837",
        "description": "Horticulturist"
    },
    {
        "code": "HAB01082",
        "description": "Hospital Matron"
    },
    {
        "code": "HAB01084",
        "description": "Hospital Porter - Health"
    },
    {
        "code": "HAB01085",
        "description": "Hospital Storeman"
    },
    {
        "code": "HAB01086",
        "description": "Hospital Ward Orderly"
    },
    {
        "code": "HAB01087",
        "description": "Hostel Matron"
    },
    {
        "code": "HAB01088",
        "description": "Hostel Warden"
    },
    {
        "code": "HAB01089",
        "description": "Hostess"
    },
    {
        "code": "HAB01090",
        "description": "Hotel Detective"
    },
    {
        "code": "HAB01091",
        "description": "Hotel Doorman"
    },
    {
        "code": "HAB01092",
        "description": "Hotel Maid"
    },
    {
        "code": "HAC02838",
        "description": "Hotel Manager (office based)"
    },
    {
        "code": "HAB01094",
        "description": "Hotel Porter"
    },
    {
        "code": "HAB01095",
        "description": "Hotel Proprietor"
    },
    {
        "code": "HAB01096",
        "description": "Hotel Receptionist"
    },
    {
        "code": "HAB01097",
        "description": "House Maid"
    },
    {
        "code": "HAB01098",
        "description": "Housekeeper"
    },
    {
        "code": "HAB01099",
        "description": "Housewife/House-Husband"
    },
    {
        "code": "HAC02839",
        "description": "Housing Association Development Manager (inc. site visits)"
    },
    {
        "code": "HAB01100",
        "description": "Housing Inspector"
    },
    {
        "code": "HAC02840",
        "description": "Housing Manager"
    },
    {
        "code": "HAC02841",
        "description": "Hydrographic Engineer/Surveyor"
    },
    {
        "code": "HAC02842",
        "description": "Hygienist"
    },
    {
        "code": "HAB01104",
        "description": "Hypnotherapist"
    },
    {
        "code": "HAB01105",
        "description": "Hypnotist"
    },
    {
        "code": "IAB01106",
        "description": "Ice Cream Van Driver"
    },
    {
        "code": "IAB01110",
        "description": "Illusionist"
    },
    {
        "code": "IAC02846",
        "description": "Illustrator"
    },
    {
        "code": "IAB01111",
        "description": "Impersonator"
    },
    {
        "code": "IAB01112",
        "description": "Importer"
    },
    {
        "code": "IAB01114",
        "description": "Incinerator Operator"
    },
    {
        "code": "IAC02847",
        "description": "Industrial Designer"
    },
    {
        "code": "IAC02848",
        "description": "Industrial Relations Officer"
    },
    {
        "code": "IAC02849",
        "description": "Industrial Trainer"
    },
    {
        "code": "IAB01118",
        "description": "Inseminator"
    },
    {
        "code": "IAC02851",
        "description": "Instrument Maker"
    },
    {
        "code": "IAC02852",
        "description": "Instrument Repairer"
    },
    {
        "code": "IAB01154",
        "description": "Insurance Agent"
    },
    {
        "code": "IAB01155",
        "description": "Insurance Assessor"
    },
    {
        "code": "IAB01156",
        "description": "Insurance Broker"
    },
    {
        "code": "IAB01157",
        "description": "Insurance Inspector"
    },
    {
        "code": "IAC02853",
        "description": "Interior Designer"
    },
    {
        "code": "IAB01158",
        "description": "Interpreter"
    },
    {
        "code": "IAC02854",
        "description": "Investment Analyst"
    },
    {
        "code": "IAB01163",
        "description": "Ironer"
    },
    {
        "code": "JAC02855",
        "description": "Janitor"
    },
    {
        "code": "JAB01167",
        "description": "Jetty Hand"
    },
    {
        "code": "JAC02856",
        "description": "Jeweller"
    },
    {
        "code": "JAB01168",
        "description": "Jewellery Enameller"
    },
    {
        "code": "JAB01169",
        "description": "Jewellery Making & Repair"
    },
    {
        "code": "JAB01170",
        "description": "Jewellery Mounter"
    },
    {
        "code": "JAB01173",
        "description": "Joiner - Construction Industry"
    },
    {
        "code": "JAB01174",
        "description": "Joiner - Ship Building, Ship Repair & Marine Engineering"
    },
    {
        "code": "JAB01175",
        "description": "Jointer"
    },
    {
        "code": "JAB01176",
        "description": "Journalist - no overseas travel etc"
    },
    {
        "code": "JAB01177",
        "description": "Journalist - otherwise"
    },
    {
        "code": "JAB01178",
        "description": "Judge"
    },
    {
        "code": "JAB01179",
        "description": "Judge's Clerk"
    },
    {
        "code": "JAB01180",
        "description": "Juggler"
    },
    {
        "code": "KAB01181",
        "description": "Keeper - Zoo"
    },
    {
        "code": "KAC02857",
        "description": "Kennel Hand"
    },
    {
        "code": "KAB01184",
        "description": "Kerb Layer"
    },
    {
        "code": "KAB01185",
        "description": "Keyboard Operator (type setting)"
    },
    {
        "code": "KAB01186",
        "description": "Kiln Attendant"
    },
    {
        "code": "KAB01187",
        "description": "Kiln Operator"
    },
    {
        "code": "KAB01192",
        "description": "Kitchen Porter"
    },
    {
        "code": "KAC02858",
        "description": "Kitchen Staﬀ"
    },
    {
        "code": "KAB01194",
        "description": "Knife Thrower"
    },
    {
        "code": "KAB01195",
        "description": "Knitter"
    },
    {
        "code": "LAB01196",
        "description": "Labeller"
    },
    {
        "code": "LAC02859",
        "description": "Laboratory Manager (supervisory and some testing)"
    },
    {
        "code": "LAC02860",
        "description": "Laboratory Technician"
    },
    {
        "code": "LAB01246",
        "description": "Lagger"
    },
    {
        "code": "LAB01249",
        "description": "Laminator"
    },
    {
        "code": "LAB01251",
        "description": "Land Agent"
    },
    {
        "code": "LAB01252",
        "description": "Land Drainage Worker"
    },
    {
        "code": "LAB01253",
        "description": "Land Surveyor"
    },
    {
        "code": "LAC02864",
        "description": "Landlord (Property -no manual work )"
    },
    {
        "code": "LAB01254",
        "description": "Landscape Gardener"
    },
    {
        "code": "LAB01255",
        "description": "Landscape Painter"
    },
    {
        "code": "LAB01259",
        "description": "Launderette Assistant"
    },
    {
        "code": "LAB01261",
        "description": "Laundryman"
    },
    {
        "code": "LAB01262",
        "description": "Lavatory Attendant"
    },
    {
        "code": "LAC02868",
        "description": "Lawyer"
    },
    {
        "code": "LAB01265",
        "description": "Leading Fireman"
    },
    {
        "code": "LAB01268",
        "description": "Leather Technologist"
    },
    {
        "code": "LAB01269",
        "description": "Lecturer"
    },
    {
        "code": "LAB01271",
        "description": "Left Luggage Attendant"
    },
    {
        "code": "LAC02869",
        "description": "Legal Adviser"
    },
    {
        "code": "LAC02870",
        "description": "Legal Executive"
    },
    {
        "code": "LAC02872",
        "description": "Letting Agent - Holiday homes"
    },
    {
        "code": "LAB01275",
        "description": "Librarian"
    },
    {
        "code": "LAC02874",
        "description": "Library Assistant"
    },
    {
        "code": "LAC02875",
        "description": "Lifeboatman - Crew"
    },
    {
        "code": "LAB01276",
        "description": "Lifeboatman (enrolled crew)"
    },
    {
        "code": "LAB01277",
        "description": "Lifeguard"
    },
    {
        "code": "LAB01278",
        "description": "Lift Attendant"
    },
    {
        "code": "LAC02876",
        "description": "Lift Engineer"
    },
    {
        "code": "LAC02877",
        "description": "Lift Erector"
    },
    {
        "code": "LAB01280",
        "description": "Light Goods Vehicle Driver"
    },
    {
        "code": "LAB01284",
        "description": "Lighting Manager"
    },
    {
        "code": "LAB01285",
        "description": "Lighting Technician"
    },
    {
        "code": "LAB01289",
        "description": "Linesman's Mate"
    },
    {
        "code": "LAC02878",
        "description": "Liquidator"
    },
    {
        "code": "LAB01293",
        "description": "Literary Agent"
    },
    {
        "code": "LAC02879",
        "description": "Lithographer"
    },
    {
        "code": "LAB01294",
        "description": "Lithographic Assistant"
    },
    {
        "code": "LAB01295",
        "description": "Lithographic Plate Grainer"
    },
    {
        "code": "LAB01296",
        "description": "Lithographic Plate Preparer"
    },
    {
        "code": "LAB01306",
        "description": "Lobster Fisherman"
    },
    {
        "code": "LAB01307",
        "description": "Local Government Officer"
    },
    {
        "code": "LAC02880",
        "description": "Local Newspaper Editor"
    },
    {
        "code": "LAB01309",
        "description": "Lock Keeper"
    },
    {
        "code": "LAB01311",
        "description": "Locksmith"
    },
    {
        "code": "LAB01312",
        "description": "Locomotive Driver"
    },
    {
        "code": "LAB01313",
        "description": "Locomotive Guard"
    },
    {
        "code": "LAB01315",
        "description": "Loss Adjuster"
    },
    {
        "code": "LAB01317",
        "description": "Lumberjack"
    },
    {
        "code": "MAB01318",
        "description": "Machine Attendant"
    },
    {
        "code": "MAB01319",
        "description": "Machine Maintenance Worker"
    },
    {
        "code": "MAB01358",
        "description": "Machine Tool Setter-Operator"
    },
    {
        "code": "MAB01363",
        "description": "Machinery Electrician"
    },
    {
        "code": "MAB01367",
        "description": "Magician"
    },
    {
        "code": "MAC02881",
        "description": "Magistrate - Stipendiary"
    },
    {
        "code": "MAC02882",
        "description": "Mail Sorter"
    },
    {
        "code": "MAB01377",
        "description": "Maintenance Repairer"
    },
    {
        "code": "MAB01390",
        "description": "Make-up Artist"
    },
    {
        "code": "MAB01393",
        "description": "Malt Roaster"
    },
    {
        "code": "MAB01394",
        "description": "Maltster"
    },
    {
        "code": "MAB01395",
        "description": "Management Consultant - Usually"
    },
    {
        "code": "MAB01436",
        "description": "Manager (oﬀ site)"
    },
    {
        "code": "MAB01451",
        "description": "Manicurist"
    },
    {
        "code": "MAB01453",
        "description": "Marine Engineer"
    },
    {
        "code": "MAB01454",
        "description": "Marine Installation Fitter"
    },
    {
        "code": "MAB01456",
        "description": "Marine Surveyor"
    },
    {
        "code": "MAB01459",
        "description": "Market Gardener"
    },
    {
        "code": "MAC02885",
        "description": "Market or Street Traders Assistant"
    },
    {
        "code": "MAB01460",
        "description": "Market Porter - Usually"
    },
    {
        "code": "MAB01461",
        "description": "Market Research Analyst"
    },
    {
        "code": "MAB01462",
        "description": "Market Research Interviewer"
    },
    {
        "code": "MAC02887",
        "description": "Market Researcher - Street research"
    },
    {
        "code": "MAC02886",
        "description": "Market Researcher (office based)"
    },
    {
        "code": "MAC02888",
        "description": "Marketing Consultant - International"
    },
    {
        "code": "MAC02889",
        "description": "Marketing Manager"
    },
    {
        "code": "MAC02890",
        "description": "Marketing Research Manager (office based)"
    },
    {
        "code": "MAB01464",
        "description": "Martial Arts Instructor"
    },
    {
        "code": "MAB01465",
        "description": "Mason"
    },
    {
        "code": "MAB01466",
        "description": "Mason Bricklayer"
    },
    {
        "code": "MAB01467",
        "description": "Mason's Labourer"
    },
    {
        "code": "MAB01468",
        "description": "Masseur"
    },
    {
        "code": "MAB01469",
        "description": "Masseuse"
    },
    {
        "code": "MAB01476",
        "description": "Mate, Second Hand"
    },
    {
        "code": "MAC02891",
        "description": "Mathematician"
    },
    {
        "code": "MAB01478",
        "description": "Mattress Maker"
    },
    {
        "code": "MAB01482",
        "description": "Meat Cutter"
    },
    {
        "code": "MAB01483",
        "description": "Meat Inspector"
    },
    {
        "code": "MAB01484",
        "description": "Meat Trimmer"
    },
    {
        "code": "MAB01496",
        "description": "Medical Practitioner"
    },
    {
        "code": "MAB01497",
        "description": "Medium"
    },
    {
        "code": "MAB01499",
        "description": "Member of Parliament, Politician"
    },
    {
        "code": "MAC02896",
        "description": "Messenger - Motorcycle"
    },
    {
        "code": "MAC02897",
        "description": "Messenger - Not motorcycle"
    },
    {
        "code": "MAB01503",
        "description": "Metallographer"
    },
    {
        "code": "MAB01504",
        "description": "Metallurgist"
    },
    {
        "code": "MAB01505",
        "description": "Meteorologist"
    },
    {
        "code": "MAB01515",
        "description": "Microfilm Operator"
    },
    {
        "code": "MAC02901",
        "description": "Midwife"
    },
    {
        "code": "MAB01516",
        "description": "Milk Roundsman"
    },
    {
        "code": "MAB01517",
        "description": "Milker"
    },
    {
        "code": "MAC02902",
        "description": "Miner"
    },
    {
        "code": "MAC02903",
        "description": "Mini Cab Driver"
    },
    {
        "code": "MAB01529",
        "description": "Mining Engineer"
    },
    {
        "code": "MAB01530",
        "description": "Mining Officer"
    },
    {
        "code": "MAB01531",
        "description": "Mining Surveyor"
    },
    {
        "code": "MAC02904",
        "description": "Minister of Religion"
    },
    {
        "code": "MAB01532",
        "description": "Missionary"
    },
    {
        "code": "MAC02905",
        "description": "Model Maker"
    },
    {
        "code": "MAB01546",
        "description": "Money Broker"
    },
    {
        "code": "MAB01548",
        "description": "Mortuary Attendant"
    },
    {
        "code": "MAC02906",
        "description": "Motor Bike Instructor"
    },
    {
        "code": "MAB01551",
        "description": "Motor Cycle Courier"
    },
    {
        "code": "MAC02907",
        "description": "Motor Cycle Messenger"
    },
    {
        "code": "MAC02908",
        "description": "Motor Fleet Manager"
    },
    {
        "code": "MAC02909",
        "description": "Motor Service Manager - admin only"
    },
    {
        "code": "MAB01564",
        "description": "Mud Engineer"
    },
    {
        "code": "MAB01565",
        "description": "Mud Logger"
    },
    {
        "code": "MAB01566",
        "description": "Mud Man"
    },
    {
        "code": "MAB01568",
        "description": "Museum Attendant"
    },
    {
        "code": "MAB01569",
        "description": "Museum Curator"
    },
    {
        "code": "MAB01570",
        "description": "Museum Guide"
    },
    {
        "code": "MAB01571",
        "description": "Music Teacher (Private)"
    },
    {
        "code": "MAB01573",
        "description": "Musical Instrument Maker"
    },
    {
        "code": "MAB01574",
        "description": "Musical Instrument Repairer"
    },
    {
        "code": "NAC01811",
        "description": "Nanny"
    },
    {
        "code": "NAB01575",
        "description": "Navigator"
    },
    {
        "code": "NAB01578",
        "description": "News Photographer - no overseas travel etc"
    },
    {
        "code": "NAB01579",
        "description": "News Photographer - otherwise"
    },
    {
        "code": "NAC01812",
        "description": "Newsagent (not delivering papers)"
    },
    {
        "code": "NAC01814",
        "description": "Newspaper Reporter - Freelance"
    },
    {
        "code": "NAB01580",
        "description": "Newsreader"
    },
    {
        "code": "NAB01581",
        "description": "Newsvendor"
    },
    {
        "code": "NAB01587",
        "description": "Nuclear Engineer"
    },
    {
        "code": "NAB01591",
        "description": "Nuclear Scientist"
    },
    {
        "code": "NAB01592",
        "description": "Nurse"
    },
    {
        "code": "NAB01597",
        "description": "Nurse - Midwife"
    },
    {
        "code": "NAB01598",
        "description": "Nurse - Teaching duties only"
    },
    {
        "code": "NAB01599",
        "description": "Nurseryman"
    },
    {
        "code": "NAB01600",
        "description": "Nursing Auxiliary"
    },
    {
        "code": "NAC01817",
        "description": "Nursing Home Proprietor (admin. only)"
    },
    {
        "code": "OAC01818",
        "description": "Obstetrician"
    },
    {
        "code": "OAB01601",
        "description": "Occupational Therapist"
    },
    {
        "code": "OAB01603",
        "description": "Office Cashier"
    },
    {
        "code": "OAB01604",
        "description": "Office Clerk"
    },
    {
        "code": "OAB01605",
        "description": "Office Fitter"
    },
    {
        "code": "OAB01606",
        "description": "Office Messenger"
    },
    {
        "code": "OAB01608",
        "description": "Office Receptionist"
    },
    {
        "code": "OAB01602",
        "description": "Oﬀ-Licence Manager"
    },
    {
        "code": "BAD03388",
        "description": "Broker - Oil"
    },
    {
        "code": "OAB01612",
        "description": "Old People's Home Matron"
    },
    {
        "code": "OAB01613",
        "description": "Old People's Home Warden"
    },
    {
        "code": "OAC01823",
        "description": "Operations Manager - Light engineering company"
    },
    {
        "code": "OAB01616",
        "description": "Operations Officer"
    },
    {
        "code": "OAB01617",
        "description": "Optical Instrument Fitter"
    },
    {
        "code": "OAB01618",
        "description": "Optical Instrument Maker"
    },
    {
        "code": "OAB01619",
        "description": "Optical Instrument Mechanic"
    },
    {
        "code": "OAB01620",
        "description": "Optical Instrument Repairer"
    },
    {
        "code": "OAB01621",
        "description": "Optical Printer"
    },
    {
        "code": "OAB01622",
        "description": "Optician"
    },
    {
        "code": "OAC01826",
        "description": "Opticians Assistant"
    },
    {
        "code": "OAB01625",
        "description": "Orchestrator"
    },
    {
        "code": "OAB01628",
        "description": "Orthodontic Technician"
    },
    {
        "code": "OAB01629",
        "description": "Orthodontist"
    },
    {
        "code": "OAC01827",
        "description": "Orthopaedic Surgeon"
    },
    {
        "code": "OAB01630",
        "description": "Orthoptist"
    },
    {
        "code": "OAB01631",
        "description": "Osteopath"
    },
    {
        "code": "OAB01637",
        "description": "Overhead Crane Driver"
    },
    {
        "code": "OAB01638",
        "description": "Overhead Linesman"
    },
    {
        "code": "OAB01639",
        "description": "Overhead Linesman's Mate"
    },
    {
        "code": "OAB01643",
        "description": "Oyster Fisherman"
    },
    {
        "code": "PAB01644",
        "description": "Packaging Machine Attendant"
    },
    {
        "code": "PAC01828",
        "description": "Painter"
    },
    {
        "code": "PAB01665",
        "description": "Painter - Woodworking Industry"
    },
    {
        "code": "PAB01666",
        "description": "Painter & Decorator (Interior)"
    },
    {
        "code": "PAB01668",
        "description": "Painter (Exterior) - 40' up"
    },
    {
        "code": "PAB01669",
        "description": "Painter (Exterior) - up to 40'"
    },
    {
        "code": "PAB01673",
        "description": "Paper Maker (hand)"
    },
    {
        "code": "PAB01674",
        "description": "Paper Merchant"
    },
    {
        "code": "PAB01676",
        "description": "Paramedic (Driver)"
    },
    {
        "code": "PAB01677",
        "description": "Paramedic (No driving)"
    },
    {
        "code": "PAB01678",
        "description": "Park Keeper"
    },
    {
        "code": "PAB01679",
        "description": "Park Ranger"
    },
    {
        "code": "PAB01680",
        "description": "Parks Superintendent"
    },
    {
        "code": "PAB01681",
        "description": "Parliamentary Agent"
    },
    {
        "code": "PAC01832",
        "description": "Party Organiser"
    },
    {
        "code": "PAB01682",
        "description": "Passenger Officer"
    },
    {
        "code": "PAB01685",
        "description": "Pasteuriser"
    },
    {
        "code": "PAB01687",
        "description": "Patent Agent"
    },
    {
        "code": "PAB01688",
        "description": "Pathologist"
    },
    {
        "code": "PAB01691",
        "description": "Pattern Maker - Metal, Plastic etc"
    },
    {
        "code": "PAB01692",
        "description": "Pavior"
    },
    {
        "code": "PAC01834",
        "description": "Pawnbroker"
    },
    {
        "code": "PAB01693",
        "description": "Pedicurist"
    },
    {
        "code": "HAD03386",
        "description": "Human Resources Consultant"
    },
    {
        "code": "HAD03387",
        "description": "Personnel Officer"
    },
    {
        "code": "PAB01696",
        "description": "Pest Control Manager"
    },
    {
        "code": "PAB01697",
        "description": "Pest Control Operator"
    },
    {
        "code": "PAB01699",
        "description": "Petrol Pump Attendant"
    },
    {
        "code": "PAB01701",
        "description": "Pharmacist"
    },
    {
        "code": "PAB01702",
        "description": "Pharmacologist"
    },
    {
        "code": "PAB01703",
        "description": "Pharmacy Assistant"
    },
    {
        "code": "PAC01844",
        "description": "Phlebotomist"
    },
    {
        "code": "PAB01704",
        "description": "Photocopying Machine Operator"
    },
    {
        "code": "PAC01845",
        "description": "Photographer"
    },
    {
        "code": "PAC01846",
        "description": "Photographer - Aerial"
    },
    {
        "code": "PAB01705",
        "description": "Photographic Finisher"
    },
    {
        "code": "PAB01706",
        "description": "Photographic Model"
    },
    {
        "code": "PAB01707",
        "description": "Photographic Mounter"
    },
    {
        "code": "PAB01708",
        "description": "Physician"
    },
    {
        "code": "PAB01709",
        "description": "Physicist"
    },
    {
        "code": "PAB01710",
        "description": "Physiotherapist"
    },
    {
        "code": "PAB01711",
        "description": "Piano/Organ Tuner"
    },
    {
        "code": "PAB01712",
        "description": "Pickler"
    },
    {
        "code": "PAC01848",
        "description": "Picture Framer"
    },
    {
        "code": "PAB01713",
        "description": "Pier Master"
    },
    {
        "code": "PAB01714",
        "description": "Pile Driver"
    },
    {
        "code": "PAB01715",
        "description": "Pilot"
    },
    {
        "code": "PAB01729",
        "description": "Pipe/Powerline Survey Work"
    },
    {
        "code": "PAB01731",
        "description": "Pitch Melter"
    },
    {
        "code": "PAB01735",
        "description": "Planning Inspector"
    },
    {
        "code": "PAB01736",
        "description": "Plant Attendant"
    },
    {
        "code": "PAC01849",
        "description": "Plant Hire Manager (some manual work)"
    },
    {
        "code": "PAC01850",
        "description": "Plant Hire Owner (some manual work)"
    },
    {
        "code": "PAC01851",
        "description": "Plant Hire Proprietor (admin. only)"
    },
    {
        "code": "PAB01747",
        "description": "Plasterer"
    },
    {
        "code": "PAB01748",
        "description": "Plastic Coating Operator"
    },
    {
        "code": "PAB01752",
        "description": "Plate Cutter"
    },
    {
        "code": "PAB01753",
        "description": "Plate Moulder"
    },
    {
        "code": "PAB01754",
        "description": "Plate Separator"
    },
    {
        "code": "PAB01755",
        "description": "Platelayer"
    },
    {
        "code": "PAB01756",
        "description": "Plateman"
    },
    {
        "code": "PAB01757",
        "description": "Plater - Aircraft/Aerospace"
    },
    {
        "code": "PAB01758",
        "description": "Plater - Motor Vehicle & Cycle Industry"
    },
    {
        "code": "PAB01759",
        "description": "Plater - Ship Building, Ship Repair & Marine Engineering"
    },
    {
        "code": "PAB01760",
        "description": "Plater (including Boiler)"
    },
    {
        "code": "PAB01761",
        "description": "Playwright"
    },
    {
        "code": "PAB01762",
        "description": "Plumber - Construction/Industrial"
    },
    {
        "code": "PAC01852",
        "description": "Plumber - Domestic"
    },
    {
        "code": "PAB01765",
        "description": "Pneumatic Drill Operator"
    },
    {
        "code": "PAB01766",
        "description": "Poet"
    },
    {
        "code": "PAB01767",
        "description": "Pointsman"
    },
    {
        "code": "PAB01768",
        "description": "Police"
    },
    {
        "code": "PAB01773",
        "description": "Police Frogman"
    },
    {
        "code": "PAB01777",
        "description": "Pollution Inspector"
    },
    {
        "code": "PAB01780",
        "description": "Pop Musician"
    },
    {
        "code": "PAB01781",
        "description": "Pop Singer"
    },
    {
        "code": "PAB01782",
        "description": "Port Control Signalman"
    },
    {
        "code": "PAB01783",
        "description": "Port Health Inspector"
    },
    {
        "code": "PAB01785",
        "description": "Porter - Meat, Fish etc - Food & Drink"
    },
    {
        "code": "PAB01786",
        "description": "Porter - Station Personnel - Railways"
    },
    {
        "code": "PAB01787",
        "description": "Portrait Painter"
    },
    {
        "code": "PAB01788",
        "description": "Portrait Photographer"
    },
    {
        "code": "PAC01853",
        "description": "Postal Courier Driver"
    },
    {
        "code": "PAC01854",
        "description": "Postal Courier Manager"
    },
    {
        "code": "PAB01789",
        "description": "Postman (no driving)"
    },
    {
        "code": "PAB01790",
        "description": "Postman"
    },
    {
        "code": "PAB01791",
        "description": "Pot Fisherman"
    },
    {
        "code": "PAB01792",
        "description": "Potman"
    },
    {
        "code": "PAB01793",
        "description": "Potter"
    },
    {
        "code": "PAB01794",
        "description": "Poultry Dresser"
    },
    {
        "code": "PAB01795",
        "description": "Poultry Plucker"
    },
    {
        "code": "PAB01797",
        "description": "Poultryman"
    },
    {
        "code": "PAB01798",
        "description": "Power Loader Man"
    },
    {
        "code": "PAB01799",
        "description": "Power Loader Operator"
    },
    {
        "code": "PAB01800",
        "description": "Power Station Charge Engineer"
    },
    {
        "code": "PAB01801",
        "description": "Power Station Manager"
    },
    {
        "code": "PAB01802",
        "description": "Power Station Superintendent"
    },
    {
        "code": "PAC01857",
        "description": "PR executive"
    },
    {
        "code": "PAC01858",
        "description": "Practice Manager"
    },
    {
        "code": "PAB01806",
        "description": "Precision Instrument Fitter"
    },
    {
        "code": "PAB01807",
        "description": "Precision Instrument Maker"
    },
    {
        "code": "PAB01808",
        "description": "Precision Instrument Repairer"
    },
    {
        "code": "PAB01809",
        "description": "Preparer"
    },
    {
        "code": "PAB01810",
        "description": "Press Cutter"
    },
    {
        "code": "PAB01811",
        "description": "Press Officer"
    },
    {
        "code": "PAB01814",
        "description": "Press Tool Setter"
    },
    {
        "code": "PAB01817",
        "description": "Presser - Fruit & Veg. - Food & Drink"
    },
    {
        "code": "PAB01818",
        "description": "Presser - Laundry"
    },
    {
        "code": "PAB01819",
        "description": "Priest"
    },
    {
        "code": "PAB01821",
        "description": "Printer"
    },
    {
        "code": "PAB01822",
        "description": "Printer's Assistant"
    },
    {
        "code": "PAB01823",
        "description": "Printing - Textiles"
    },
    {
        "code": "PAC01860",
        "description": "Printing Director (purely admin.)"
    },
    {
        "code": "PAB01826",
        "description": "Prison Officer"
    },
    {
        "code": "PAB01827",
        "description": "Private Detective"
    },
    {
        "code": "PAB01831",
        "description": "Probation Officer"
    },
    {
        "code": "PAB01868",
        "description": "Production Manager"
    },
    {
        "code": "PAC01865",
        "description": "Professional Sportsperson"
    },
    {
        "code": "PAC01866",
        "description": "Project Co-ordinator"
    },
    {
        "code": "PAB01899",
        "description": "Projectionist"
    },
    {
        "code": "PAB01900",
        "description": "Prompter"
    },
    {
        "code": "PAB01901",
        "description": "Proofer"
    },
    {
        "code": "PAC01867",
        "description": "Property & Estate Manager"
    },
    {
        "code": "PAD03271",
        "description": "Property Developer(no manual work)"
    },
    {
        "code": "PAB01902",
        "description": "Property Manager"
    },
    {
        "code": "PAB01905",
        "description": "Proprietor"
    },
    {
        "code": "PAB01906",
        "description": "Psychiatrist"
    },
    {
        "code": "PAB01907",
        "description": "Psychologist"
    },
    {
        "code": "PAC01870",
        "description": "Psychotherapist"
    },
    {
        "code": "PAC01871",
        "description": "Public Hall Bookings Office Manager"
    },
    {
        "code": "PAB01909",
        "description": "Public Health Inspector"
    },
    {
        "code": "PAB01910",
        "description": "Public House Manager (salaried)"
    },
    {
        "code": "PAB01911",
        "description": "Public Lighting Fitter-Erector"
    },
    {
        "code": "PAB01913",
        "description": "Public Relations Officer"
    },
    {
        "code": "PAB01915",
        "description": "Publican"
    },
    {
        "code": "PAB01916",
        "description": "Publisher"
    },
    {
        "code": "PAB01923",
        "description": "Puppeteer"
    },
    {
        "code": "PAC01872",
        "description": "Purchasing Officer/Manager (not retail)"
    },
    {
        "code": "PAB01924",
        "description": "Purifier Man"
    },
    {
        "code": "PAB01925",
        "description": "Purser"
    },
    {
        "code": "PAB01927",
        "description": "Pusherman"
    },
    {
        "code": "QAB01930",
        "description": "Quantity Surveyor"
    },
    {
        "code": "QAB01931",
        "description": "Quarry Manager"
    },
    {
        "code": "QAC01874",
        "description": "Quarryman"
    },
    {
        "code": "QAB01933",
        "description": "Quartermaster"
    },
    {
        "code": "QAB01934",
        "description": "Queen’s Counsel"
    },
    {
        "code": "RAB01935",
        "description": "Rabbi"
    },
    {
        "code": "RAB01936",
        "description": "Radar Controller/Operator"
    },
    {
        "code": "RAB01937",
        "description": "Radar Observer"
    },
    {
        "code": "RAB01942",
        "description": "Radio Station Manager"
    },
    {
        "code": "RAB01943",
        "description": "Radio/Radar Operator"
    },
    {
        "code": "RAC01875",
        "description": "Radio/TV Announcer/Presenter"
    },
    {
        "code": "RAC01876",
        "description": "Radio/TV Director/Producer"
    },
    {
        "code": "RAC01877",
        "description": "Radio and TV Repairer"
    },
    {
        "code": "RAB01947",
        "description": "Radiographer - Health"
    },
    {
        "code": "RAB01948",
        "description": "Radiologist"
    },
    {
        "code": "AAD03389",
        "description": "Armed Forces - RAF - aircrew"
    },
    {
        "code": "RAB01950",
        "description": "Rag & Bone Dealer"
    },
    {
        "code": "RAB01959",
        "description": "Receptionist"
    },
    {
        "code": "RAC01881",
        "description": "Record Producer - Entertainment Industry"
    },
    {
        "code": "RAB01960",
        "description": "Recording Engineer"
    },
    {
        "code": "RAB01961",
        "description": "Rector"
    },
    {
        "code": "RAB01963",
        "description": "Reﬂexologist"
    },
    {
        "code": "RAC01882",
        "description": "Refuse Collector"
    },
    {
        "code": "RAB01964",
        "description": "Registrar"
    },
    {
        "code": "RAC01883",
        "description": "Reinsurance Broker"
    },
    {
        "code": "RAB01967",
        "description": "Rent Collector"
    },
    {
        "code": "RAB01973",
        "description": "Reporter/Writer - no overseas travel etc"
    },
    {
        "code": "RAB01974",
        "description": "Reporter/Writer - otherwise"
    },
    {
        "code": "RAB01975",
        "description": "Rescue Diver"
    },
    {
        "code": "RAC01885",
        "description": "Research Chemist (Managerial)"
    },
    {
        "code": "RAC01886",
        "description": "Research Information Officer (Office based)"
    },
    {
        "code": "RAC01887",
        "description": "Research Projects Manager (Deals with hazardous substances)"
    },
    {
        "code": "RAB01976",
        "description": "Research Survey Clerk"
    },
    {
        "code": "RAB01978",
        "description": "Researcher - Journalism"
    },
    {
        "code": "RAB01977",
        "description": "Researcher - Radio & TV - Entertainment"
    },
    {
        "code": "RAB01979",
        "description": "Reservations Clerk"
    },
    {
        "code": "RAB01980",
        "description": "Reservoir Attendant"
    },
    {
        "code": "RAC01888",
        "description": "Residential Care Worker"
    },
    {
        "code": "RAC01890",
        "description": "Residential Home Proprietor (Admin. only)"
    },
    {
        "code": "RAC01891",
        "description": "Residential Home Proprietor (full involvement in caring)"
    },
    {
        "code": "RAC01892",
        "description": "Restaurant Proprietor - no cooking"
    },
    {
        "code": "RAB01983",
        "description": "Restorer (Paintings)"
    },
    {
        "code": "RAB01984",
        "description": "Restorer (Stone, Brickwork)"
    },
    {
        "code": "RAC01894",
        "description": "Retail Shop Manager"
    },
    {
        "code": "RAC01895",
        "description": "Retail Shop Manager - admin only"
    },
    {
        "code": "RAC01896",
        "description": "Retired"
    },
    {
        "code": "RAC01897",
        "description": "Riding Instructor"
    },
    {
        "code": "RAB01985",
        "description": "Rig Electrician"
    },
    {
        "code": "RAB01986",
        "description": "Rig Maintenance Diver"
    },
    {
        "code": "RAB01987",
        "description": "Rig Mechanic"
    },
    {
        "code": "RAB01988",
        "description": "Rig Medic"
    },
    {
        "code": "RAB01989",
        "description": "Rigger - Docks"
    },
    {
        "code": "RAB01990",
        "description": "Rigger - Film Industry - Entertainment"
    },
    {
        "code": "RAB01991",
        "description": "Rigger - Gas Supply Industry"
    },
    {
        "code": "RAB01994",
        "description": "Rigger - Industrial/Plant Machinery"
    },
    {
        "code": "RAB01992",
        "description": "Rigger - Oil & Natural Gas Industries"
    },
    {
        "code": "RAB01993",
        "description": "Rigger - Ship Building, Ship Repair & Marine Engineering"
    },
    {
        "code": "RAB01995",
        "description": "Ripper"
    },
    {
        "code": "RAB01996",
        "description": "River Inspector"
    },
    {
        "code": "RAB02008",
        "description": "Road Crew Member - 'Roadie'"
    },
    {
        "code": "RAC01900",
        "description": "Road Manager - Rock band"
    },
    {
        "code": "RAB02010",
        "description": "Road Marker"
    },
    {
        "code": "RAB02013",
        "description": "Road Safety Officer"
    },
    {
        "code": "RAC01902",
        "description": "Road Sweeper - Mechanical"
    },
    {
        "code": "RAB02015",
        "description": "Road Sweeper (hand)"
    },
    {
        "code": "RAB02016",
        "description": "Road Tester - Garage Trade"
    },
    {
        "code": "RAB02017",
        "description": "Road Tester - Motor Vehicle & Cycle Industry"
    },
    {
        "code": "RAB02019",
        "description": "Roaster"
    },
    {
        "code": "RAB02020",
        "description": "Rodent Destroyer"
    },
    {
        "code": "RAB02022",
        "description": "Roller Blind Maker"
    },
    {
        "code": "RAB02032",
        "description": "Roofer - 40' up"
    },
    {
        "code": "RAB02033",
        "description": "Roofer - up to 40'"
    },
    {
        "code": "RAC01903",
        "description": "Roofing Inspector (Mostly office based - some estimating)"
    },
    {
        "code": "RAB02035",
        "description": "Rope Maker"
    },
    {
        "code": "RAB02037",
        "description": "Roughneck"
    },
    {
        "code": "RAB02039",
        "description": "Roustabout"
    },
    {
        "code": "RAC01905",
        "description": "Roustabout Pusher - Oil Rig Industry"
    },
    {
        "code": "AAD03390",
        "description": "Armed Forces - Navy - diving"
    },
    {
        "code": "RAB02043",
        "description": "RSPCA Inspector"
    },
    {
        "code": "RAC01906",
        "description": "Rubber & Plastics Worker"
    },
    {
        "code": "RAB02044",
        "description": "Rubber Technologist"
    },
    {
        "code": "SAB02047",
        "description": "Saddler"
    },
    {
        "code": "SAB02048",
        "description": "Safety Officer"
    },
    {
        "code": "SAC01907",
        "description": "Safety Officer - Oil Rig Industry"
    },
    {
        "code": "SAC01908",
        "description": "Sales & Marketing Manager"
    },
    {
        "code": "SAB02050",
        "description": "Salter"
    },
    {
        "code": "SAB02051",
        "description": "Salvage Diver"
    },
    {
        "code": "SAB02052",
        "description": "Salvage Man"
    },
    {
        "code": "SAC01910",
        "description": "Satellite Aerial Fixer (domestic only)"
    },
    {
        "code": "SAB02056",
        "description": "Saturation Tank Attendant"
    },
    {
        "code": "SAB02064",
        "description": "Scaﬀolder"
    },
    {
        "code": "SAB02068",
        "description": "Scene Shifter"
    },
    {
        "code": "SAB02069",
        "description": "Scenery Painter"
    },
    {
        "code": "SAC01912",
        "description": "School Bursar"
    },
    {
        "code": "SAC01913",
        "description": "School Inspector"
    },
    {
        "code": "SAB02074",
        "description": "Scrap Breaker"
    },
    {
        "code": "SAB02076",
        "description": "Scrap Dealer"
    },
    {
        "code": "SAB02079",
        "description": "Screen Printer"
    },
    {
        "code": "SAB02081",
        "description": "Screener - Quarrying"
    },
    {
        "code": "SAB02082",
        "description": "Screenmaker"
    },
    {
        "code": "SAB02084",
        "description": "Screwman"
    },
    {
        "code": "SAB02085",
        "description": "Script Writer"
    },
    {
        "code": "SAB02087",
        "description": "Sculptor"
    },
    {
        "code": "SAB02088",
        "description": "Seaman"
    },
    {
        "code": "SAB02093",
        "description": "Security Guard"
    },
    {
        "code": "SAB02096",
        "description": "Seismologist"
    },
    {
        "code": "SAB02106",
        "description": "Sewage Works Attendant"
    },
    {
        "code": "SAB02107",
        "description": "Sewage Works Manager"
    },
    {
        "code": "SAB02108",
        "description": "Sewerman"
    },
    {
        "code": "SAC01919",
        "description": "Sewing Machine Mechanic"
    },
    {
        "code": "SAB02113",
        "description": "Shaftsman"
    },
    {
        "code": "SAB02114",
        "description": "Sheep Shearer"
    },
    {
        "code": "SAB02115",
        "description": "Sheet Fixer"
    },
    {
        "code": "SAC01920",
        "description": "Shelf Filler"
    },
    {
        "code": "SAB02119",
        "description": "Shepherd"
    },
    {
        "code": "SAB02123",
        "description": "Shipping Clerk"
    },
    {
        "code": "SAB02121",
        "description": "Ship's Broker"
    },
    {
        "code": "SAB02127",
        "description": "Shoe Maker"
    },
    {
        "code": "SAB02128",
        "description": "Shoe Repairer"
    },
    {
        "code": "SAB02129",
        "description": "Shop Assistant"
    },
    {
        "code": "SAB02133",
        "description": "Shop Fitter"
    },
    {
        "code": "SAB02145",
        "description": "Shredding Machine Operator"
    },
    {
        "code": "SAB02146",
        "description": "Shunter - Marshalling/Goods Yard - Railways"
    },
    {
        "code": "SAB02147",
        "description": "Shunter - Mining"
    },
    {
        "code": "SAB02159",
        "description": "Sign Writer (40' up)"
    },
    {
        "code": "SAB02160",
        "description": "Sign Writer (no work at heights)"
    },
    {
        "code": "SAB02163",
        "description": "Signalman"
    },
    {
        "code": "SAB02167",
        "description": "Siloman - Docks"
    },
    {
        "code": "SAB02169",
        "description": "Siloman - quarry"
    },
    {
        "code": "SAC01922",
        "description": "Silversmith"
    },
    {
        "code": "SAB02171",
        "description": "Sister (Hospital)"
    },
    {
        "code": "SAB02172",
        "description": "Site Agent"
    },
    {
        "code": "SAB02176",
        "description": "Skiing - Snow - Prof Instructor"
    },
    {
        "code": "SAB02177",
        "description": "Skipper"
    },
    {
        "code": "SAB02181",
        "description": "Slate Cutter"
    },
    {
        "code": "SAB02182",
        "description": "Slate Dresser"
    },
    {
        "code": "SAB02183",
        "description": "Slate Splitter"
    },
    {
        "code": "SAB02184",
        "description": "Slater - 40' up"
    },
    {
        "code": "SAB02185",
        "description": "Slater - up to 40'"
    },
    {
        "code": "SAB02186",
        "description": "Slaughterer"
    },
    {
        "code": "SAB02187",
        "description": "Slaughterhouse Manager"
    },
    {
        "code": "SAB02188",
        "description": "Sleeping Car Attendant"
    },
    {
        "code": "SAB02189",
        "description": "Sleeve Designer"
    },
    {
        "code": "SAB02190",
        "description": "Smeller"
    },
    {
        "code": "SAB02191",
        "description": "Smith - Gold, Silver etc"
    },
    {
        "code": "SAB02192",
        "description": "Smoker"
    },
    {
        "code": "SAB02197",
        "description": "Social Worker"
    },
    {
        "code": "SAB02198",
        "description": "Sociologist"
    },
    {
        "code": "SAB02199",
        "description": "Solderer"
    },
    {
        "code": "SAB02200",
        "description": "Solicitor"
    },
    {
        "code": "SAB02201",
        "description": "Song Writer"
    },
    {
        "code": "SAB02202",
        "description": "Sorter - Dry Cleaning"
    },
    {
        "code": "SAB02203",
        "description": "Sorter - Laundry"
    },
    {
        "code": "SAB02204",
        "description": "Sorter - Post Office/Telecommunications"
    },
    {
        "code": "SAB02205",
        "description": "Sorter (scrap metal)"
    },
    {
        "code": "SAB02212",
        "description": "Sound Technician"
    },
    {
        "code": "SAB02214",
        "description": "Special Air Service (SAS)"
    },
    {
        "code": "SAB02215",
        "description": "Special Boat Service (SBS)"
    },
    {
        "code": "SAB02217",
        "description": "Special Eﬀects Technician"
    },
    {
        "code": "SAB02218",
        "description": "Speech Therapist"
    },
    {
        "code": "SAB02219",
        "description": "Spiderman"
    },
    {
        "code": "SAB02220",
        "description": "Spinner"
    },
    {
        "code": "SAB02224",
        "description": "Sports Equipment Maker"
    },
    {
        "code": "SAB02225",
        "description": "Sports Equipment Repairer"
    },
    {
        "code": "SAB02226",
        "description": "Spot-Welder"
    },
    {
        "code": "SAB02235",
        "description": "Stablehand"
    },
    {
        "code": "SAB02236",
        "description": "Staﬀ Nurse"
    },
    {
        "code": "SAB02237",
        "description": "Stage Doorkeeper"
    },
    {
        "code": "SAB02238",
        "description": "Stage Hand"
    },
    {
        "code": "SAC01928",
        "description": "Stage Technician"
    },
    {
        "code": "SAB02242",
        "description": "Stamper (identification markings)"
    },
    {
        "code": "SAC01929",
        "description": "Stationer"
    },
    {
        "code": "SAB02253",
        "description": "Statistician"
    },
    {
        "code": "SAB02254",
        "description": "Steel Erector - 40' up"
    },
    {
        "code": "SAB02255",
        "description": "Steel Erector - up to 40'"
    },
    {
        "code": "SAB02258",
        "description": "Steeplejack"
    },
    {
        "code": "SAB02263",
        "description": "Stenographer"
    },
    {
        "code": "SAB02267",
        "description": "Stevedore"
    },
    {
        "code": "SAB02269",
        "description": "Stitcher"
    },
    {
        "code": "SAB02270",
        "description": "Stockbroker"
    },
    {
        "code": "SAB02272",
        "description": "Stockroom Storeman"
    },
    {
        "code": "SAB02273",
        "description": "Stocktaker"
    },
    {
        "code": "SAB02284",
        "description": "Stonemason"
    },
    {
        "code": "SAB02285",
        "description": "Store Detective"
    },
    {
        "code": "SAB02286",
        "description": "Storekeeper"
    },
    {
        "code": "SAB02293",
        "description": "Lollipop Man/Lady"
    },
    {
        "code": "SAB02298",
        "description": "Student"
    },
    {
        "code": "SAB02301",
        "description": "Stunt Man"
    },
    {
        "code": "SAB02305",
        "description": "Submariner"
    },
    {
        "code": "SAB02306",
        "description": "Sugar Beet Cutter/Slicer"
    },
    {
        "code": "SAB02307",
        "description": "Sugar Boiler"
    },
    {
        "code": "SAB02310",
        "description": "Supermarket Cashier"
    },
    {
        "code": "SAB02317",
        "description": "Surgeon"
    },
    {
        "code": "SAB02318",
        "description": "Surgery Nurse"
    },
    {
        "code": "SAB02319",
        "description": "Surgery Receptionist"
    },
    {
        "code": "SAB02320",
        "description": "Surgical Appliance Maker"
    },
    {
        "code": "SAC01936",
        "description": "Surgical Shoe Maker"
    },
    {
        "code": "SAB02322",
        "description": "Surveyor - Oil & Natural Gas Industries (Exploration & Production)"
    },
    {
        "code": "SAB02323",
        "description": "Surveyor - Ship Building, Ship Repair & Marine Engineering"
    },
    {
        "code": "SAC01937",
        "description": "Swimming Instructor"
    },
    {
        "code": "SAB02325",
        "description": "Swimming Pool Attendant"
    },
    {
        "code": "SAB02326",
        "description": "Switchboard Operator"
    },
    {
        "code": "SAB02327",
        "description": "Sword Swallower"
    },
    {
        "code": "TAB02333",
        "description": "Tamperman"
    },
    {
        "code": "TAB02334",
        "description": "Tank Room Attendant"
    },
    {
        "code": "TAB02338",
        "description": "Tarmac Layer - Construction Industry"
    },
    {
        "code": "TAB02339",
        "description": "Tarmac Layer - Road Maintenance & Construction"
    },
    {
        "code": "TAB02340",
        "description": "Tax Consultant"
    },
    {
        "code": "TAB02341",
        "description": "Tax Inspector"
    },
    {
        "code": "TAC01941",
        "description": "Taxi Business Administrator"
    },
    {
        "code": "TAC01943",
        "description": "Taxi Business Proprietor (no driving)"
    },
    {
        "code": "TAB02342",
        "description": "Taxi Driver"
    },
    {
        "code": "TAB02343",
        "description": "Taxidermist"
    },
    {
        "code": "TAC01944",
        "description": "Teaching Assistant"
    },
    {
        "code": "TAB02360",
        "description": "Technician - other"
    },
    {
        "code": "TAC01945",
        "description": "Telecommunication Technical Officer"
    },
    {
        "code": "TAB02365",
        "description": "Telegraphist"
    },
    {
        "code": "TAC01946",
        "description": "Telephone Customer Advisor"
    },
    {
        "code": "TAB02366",
        "description": "Telephone Exchange Superintendent"
    },
    {
        "code": "TAB02367",
        "description": "Telephone Fitter"
    },
    {
        "code": "TAB02368",
        "description": "Telephone Operator"
    },
    {
        "code": "TAB02369",
        "description": "Telephone Repairer"
    },
    {
        "code": "TAB02370",
        "description": "Telephone Supervisor"
    },
    {
        "code": "TAC01947",
        "description": "Telephone Systems Sales Director"
    },
    {
        "code": "TAB02371",
        "description": "Telephonist"
    },
    {
        "code": "TAC01948",
        "description": "Television Engineer"
    },
    {
        "code": "TAB02380",
        "description": "Test Pilots"
    },
    {
        "code": "TAC01949",
        "description": "Textile Worker"
    },
    {
        "code": "TAB02393",
        "description": "Thatcher"
    },
    {
        "code": "TAC01950",
        "description": "Theatre Sound Engineer"
    },
    {
        "code": "TAB02398",
        "description": "Ticket Inspector"
    },
    {
        "code": "TAB02400",
        "description": "Tiler - 40' up"
    },
    {
        "code": "TAB02401",
        "description": "Tiler - up to 40'"
    },
    {
        "code": "TAB02402",
        "description": "Timber Merchant (no manual work)"
    },
    {
        "code": "TAB02405",
        "description": "Timberman"
    },
    {
        "code": "TAC01951",
        "description": "Tobacconist"
    },
    {
        "code": "TAB02412",
        "description": "Tool Fitter"
    },
    {
        "code": "TAB02413",
        "description": "Tool Maker"
    },
    {
        "code": "TAB02414",
        "description": "Tool Pusher"
    },
    {
        "code": "TAB02417",
        "description": "Tour Guide"
    },
    {
        "code": "TAB02418",
        "description": "Tour Manager"
    },
    {
        "code": "TAB02419",
        "description": "Tower Crane Driver"
    },
    {
        "code": "TAB02420",
        "description": "Town Planner"
    },
    {
        "code": "TAB02421",
        "description": "Toxicologist"
    },
    {
        "code": "TAB02426",
        "description": "Trackman"
    },
    {
        "code": "TAB02427",
        "description": "Traffic Warden"
    },
    {
        "code": "TAB02428",
        "description": "Train Crew Inspector"
    },
    {
        "code": "TAB02429",
        "description": "Train Crew Supervisor"
    },
    {
        "code": "TAC01953",
        "description": "Train Driver"
    },
    {
        "code": "TAB02432",
        "description": "Translator"
    },
    {
        "code": "TAC01954",
        "description": "Transport Company Operations Manager (Office based)"
    },
    {
        "code": "TAC01955",
        "description": "Transport Manager"
    },
    {
        "code": "TAC01956",
        "description": "Travel Agent (office based)"
    },
    {
        "code": "TAB02434",
        "description": "Travel Courier"
    },
    {
        "code": "TAB02435",
        "description": "Trawlerman"
    },
    {
        "code": "TAB02436",
        "description": "Tree Feller"
    },
    {
        "code": "TAB02437",
        "description": "Tree Surgeon"
    },
    {
        "code": "TAB02438",
        "description": "Trenchman"
    },
    {
        "code": "TAB02443",
        "description": "Tugman"
    },
    {
        "code": "TAB02449",
        "description": "Tunneller - no explosives etc"
    },
    {
        "code": "TAB02450",
        "description": "Tunneller - using explosives etc"
    },
    {
        "code": "TAC01957",
        "description": "Turf Accountant (on course)"
    },
    {
        "code": "TAC01958",
        "description": "Turf Accountant (shop)"
    },
    {
        "code": "TAB02451",
        "description": "Turner - Machining, Shaping etc - Metal Manufacture"
    },
    {
        "code": "TAB02452",
        "description": "Turner - Pottery Industry"
    },
    {
        "code": "TAB02453",
        "description": "Turnstile Operator"
    },
    {
        "code": "TAB02454",
        "description": "Tutor (salaried)"
    },
    {
        "code": "TAB02455",
        "description": "Tutor (self-employed)"
    },
    {
        "code": "TAB02457",
        "description": "Type Caster"
    },
    {
        "code": "TAC01959",
        "description": "Typesetter"
    },
    {
        "code": "TAB02459",
        "description": "Typograph Operator"
    },
    {
        "code": "TAB02460",
        "description": "Typographical Designer"
    },
    {
        "code": "TAD03322",
        "description": "Tyre/Exhaust Fitter"
    },
    {
        "code": "UAB02463",
        "description": "Undertaker"
    },
    {
        "code": "UAB02464",
        "description": "Undertaker's Director’s Assistant"
    },
    {
        "code": "UAB02465",
        "description": "Underwriter"
    },
    {
        "code": "UAC01960",
        "description": "Unemployed"
    },
    {
        "code": "UAC01961",
        "description": "Unknown"
    },
    {
        "code": "UAB02467",
        "description": "Upholsterer"
    },
    {
        "code": "UAB02469",
        "description": "Usher/Usherette"
    },
    {
        "code": "VAB02470",
        "description": "Valet/Valeter"
    },
    {
        "code": "VAB02471",
        "description": "Valuer"
    },
    {
        "code": "VAB02475",
        "description": "Van Driver"
    },
    {
        "code": "VAB02476",
        "description": "Varnisher"
    },
    {
        "code": "VAB02477",
        "description": "Vatman"
    },
    {
        "code": "VAB02478",
        "description": "Vehicle Body Builder"
    },
    {
        "code": "VAB02479",
        "description": "Vehicle Body Fitter"
    },
    {
        "code": "VAC01962",
        "description": "Vending Machine Engineer"
    },
    {
        "code": "VAB02480",
        "description": "Venetian Blind Maker"
    },
    {
        "code": "VAB02481",
        "description": "Ventriloquist"
    },
    {
        "code": "VAB02482",
        "description": "Verger"
    },
    {
        "code": "VAB02483",
        "description": "Veterinarian"
    },
    {
        "code": "VAB02485",
        "description": "Veterinary Assistant"
    },
    {
        "code": "VAB02492",
        "description": "Vicar"
    },
    {
        "code": "VAC01963",
        "description": "Video Conference Engineer"
    },
    {
        "code": "VAB02493",
        "description": "Video Recorder Operator"
    },
    {
        "code": "VAB02495",
        "description": "Vision Mixer"
    },
    {
        "code": "VAB02496",
        "description": "Vocational Training Instructor"
    },
    {
        "code": "WAB02499",
        "description": "Wages Inspector"
    },
    {
        "code": "WAB02503",
        "description": "Waiter, Waitress"
    },
    {
        "code": "WAB02506",
        "description": "Wallpaper Printer"
    },
    {
        "code": "WAB02507",
        "description": "Warden"
    },
    {
        "code": "WAB02508",
        "description": "Wardrobe Mistress"
    },
    {
        "code": "WAB02510",
        "description": "Warehouse Manager"
    },
    {
        "code": "WAB02513",
        "description": "Warehouseman"
    },
    {
        "code": "WAB02518",
        "description": "Wasteman, Salvage Man"
    },
    {
        "code": "WAC01964",
        "description": "Watch & Clock Maker"
    },
    {
        "code": "WAC01965",
        "description": "Watch & Clock Repairer"
    },
    {
        "code": "WAB02519",
        "description": "Watchman (inland waterways)"
    },
    {
        "code": "WAB02520",
        "description": "Watchstander"
    },
    {
        "code": "WAB02521",
        "description": "Water Bailiﬀ"
    },
    {
        "code": "WAB02522",
        "description": "Water Infusion Man"
    },
    {
        "code": "WAB02524",
        "description": "Water Skiing - Prof. Instructor"
    },
    {
        "code": "WAD03355",
        "description": "Waterworks Manager"
    },
    {
        "code": "WAB02529",
        "description": "Wedding Photographer"
    },
    {
        "code": "WAB02530",
        "description": "Weighbridge Clerk"
    },
    {
        "code": "WAB02538",
        "description": "Weights & Measures Inspector"
    },
    {
        "code": "WAB02541",
        "description": "Welder - 40' up"
    },
    {
        "code": "WAB02542",
        "description": "Welder - up to 40'"
    },
    {
        "code": "WAB02546",
        "description": "Welfare Officer"
    },
    {
        "code": "WAB02547",
        "description": "Welfare Worker"
    },
    {
        "code": "WAB02556",
        "description": "Wicker Worker"
    },
    {
        "code": "WAB02558",
        "description": "Wig Maker"
    },
    {
        "code": "WAB02561",
        "description": "Winch Operator"
    },
    {
        "code": "WAB02562",
        "description": "Winchman"
    },
    {
        "code": "WAB02564",
        "description": "Window Cleaner (40' up)"
    },
    {
        "code": "WAB02565",
        "description": "Window Cleaner (up to 40')"
    },
    {
        "code": "WAB02566",
        "description": "Window Dresser"
    },
    {
        "code": "WAB02567",
        "description": "Wire Winder"
    },
    {
        "code": "WAB02570",
        "description": "Wood Carver"
    },
    {
        "code": "WAB02574",
        "description": "Woodcutter"
    },
    {
        "code": "WAB02575",
        "description": "Woodman"
    },
    {
        "code": "WAC01966",
        "description": "Working Partner"
    },
    {
        "code": "WAB02581",
        "description": "Wrapping Machine Attendant"
    },
    {
        "code": "WAB02583",
        "description": "Writer"
    },
    {
        "code": "YAB02585",
        "description": "Yard Cleaner"
    },
    {
        "code": "YAC01967",
        "description": "Youth Hostel Assistant and Cook"
    },
    {
        "code": "YAC01968",
        "description": "Youth Hostel Manager (some manual work)"
    },
    {
        "code": "YAB02586",
        "description": "Youth Leader (Full time)"
    },
    {
        "code": "AAD03000",
        "description": "Abrasive Wheels Worker"
    },
    {
        "code": "AAD03001",
        "description": "Accounts Administrator/Assistant"
    },
    {
        "code": "AAD03002",
        "description": "Acrobat"
    },
    {
        "code": "AAD03003",
        "description": "Administrator - office"
    },
    {
        "code": "AAD03004",
        "description": "Air Compressor Operator"
    },
    {
        "code": "AAD03392",
        "description": "Alternative Therapist/Complimentary Therapist"
    },
    {
        "code": "AAD03005",
        "description": "Amusement Park Worker"
    },
    {
        "code": "AAD03006",
        "description": "Analyst - Business"
    },
    {
        "code": "AAD03007",
        "description": "Analyst - City"
    },
    {
        "code": "AAD03008",
        "description": "Analyst - Investment"
    },
    {
        "code": "AAD03009",
        "description": "Analyst - Systems"
    },
    {
        "code": "AAD03010",
        "description": "Analyst- Other"
    },
    {
        "code": "AAD03011",
        "description": "Armed Forces - Army - aircrew"
    },
    {
        "code": "AAD03012",
        "description": "Armed Forces - Army - bomb disposal"
    },
    {
        "code": "AAD03013",
        "description": "Armed Forces - Army - no bomb disposal"
    },
    {
        "code": "AAD03014",
        "description": "Armed Forces - Army - SAS"
    },
    {
        "code": "AAD03015",
        "description": "Armed Forces - Full time reservist - no special duties"
    },
    {
        "code": "AAD03016",
        "description": "Armed Forces - Full time reservist - special duties"
    },
    {
        "code": "AAD03017",
        "description": "Armed Forces - Navy - aircrew"
    },
    {
        "code": "AAD03390",
        "description": "Armed Forces - Navy - diving"
    },
    {
        "code": "AAD03018",
        "description": "Armed Forces - Navy - no diving"
    },
    {
        "code": "AAD03019",
        "description": "Armed Forces - Navy - SBS"
    },
    {
        "code": "AAD03389",
        "description": "Armed Forces - RAF - aircrew"
    },
    {
        "code": "AAD03020",
        "description": "Armed Forces - RAF - no ﬂying"
    },
    {
        "code": "AAD03021",
        "description": "Asbestos Inspector"
    },
    {
        "code": "AAD03022",
        "description": "Au Pair"
    },
    {
        "code": "AAD03023",
        "description": "Auxiliary Nurse"
    },
    {
        "code": "BAD03025",
        "description": "Baggage Manager"
    },
    {
        "code": "BAD03026",
        "description": "Bailiﬀ"
    },
    {
        "code": "BAD03027",
        "description": "Banksman"
    },
    {
        "code": "BAD03029",
        "description": "Bin man"
    },
    {
        "code": "BAD03030",
        "description": "Bingo Hall Manager"
    },
    {
        "code": "BAD03031",
        "description": "Blaster - quarry"
    },
    {
        "code": "BAD03032",
        "description": "Book Seller"
    },
    {
        "code": "BAD03033",
        "description": "Box Office Cashier"
    },
    {
        "code": "BAD03034",
        "description": "Box Office Clerk"
    },
    {
        "code": "BAD03035",
        "description": "Breakdown Recovery Man"
    },
    {
        "code": "BAD03036",
        "description": "Briner"
    },
    {
        "code": "BAD03037",
        "description": "Broker - Insurance IFA"
    },
    {
        "code": "BAD03038",
        "description": "Broker - Insurance Non IFA"
    },
    {
        "code": "BAD03039",
        "description": "Broker - Money/investments"
    },
    {
        "code": "BAD03040",
        "description": "Broker - Other"
    },
    {
        "code": "BAD03041",
        "description": "Building Society worker"
    },
    {
        "code": "BAD03042",
        "description": "Bunker Control man"
    },
    {
        "code": "BAD03044",
        "description": "Buyer - stocks and shares"
    },
    {
        "code": "CAD03046",
        "description": "Café Worker"
    },
    {
        "code": "CAD03047",
        "description": "Call Centre Manager"
    },
    {
        "code": "CAD03048",
        "description": "Call Centre Worker"
    },
    {
        "code": "CAD03049",
        "description": "Cameraman Outside Work"
    },
    {
        "code": "CAD03050",
        "description": "Cameraman War or Disaster reporting"
    },
    {
        "code": "CAD03051",
        "description": "Car Rental Company Worker"
    },
    {
        "code": "CAD03052",
        "description": "Caravan Site Manager"
    },
    {
        "code": "CAD03053",
        "description": "Caravan Site Staﬀ"
    },
    {
        "code": "CAD03054",
        "description": "Caulker"
    },
    {
        "code": "CAD03055",
        "description": "CC TV Installer/Maintenance - 40 ft and over"
    },
    {
        "code": "CAD03056",
        "description": "CC TV Installer/Maintenance - under 40 ft"
    },
    {
        "code": "CAD03057",
        "description": "Cemetery Worker"
    },
    {
        "code": "CAD03058",
        "description": "Charity Worker - Admin Only"
    },
    {
        "code": "CAD03390",
        "description": "Charity Worker - UK Work"
    },
    {
        "code": "CAD03391",
        "description": "Charity Worker - Overseas Work"
    },
    {
        "code": "CAD03059",
        "description": "Chemical engineer - UK"
    },
    {
        "code": "CAD03060",
        "description": "Chemist - industrial"
    },
    {
        "code": "CAD03061",
        "description": "Chemist - retail"
    },
    {
        "code": "CAD03062",
        "description": "Child Support Agency (CSA) worker"
    },
    {
        "code": "CAD03063",
        "description": "Chip Shop Worker"
    },
    {
        "code": "CAD03064",
        "description": "Cinema Projectionist"
    },
    {
        "code": "CAD03065",
        "description": "Circus Manager"
    },
    {
        "code": "CAD03066",
        "description": "Civil Engineer"
    },
    {
        "code": "CAD03067",
        "description": "Civil Servant"
    },
    {
        "code": "CAD03068",
        "description": "Cleaner - commercial premises"
    },
    {
        "code": "CAD03069",
        "description": "Cleaner - industrial"
    },
    {
        "code": "CAD03070",
        "description": "Coach - Sports"
    },
    {
        "code": "CAD03071",
        "description": "Community Development Worker"
    },
    {
        "code": "CAD03072",
        "description": "Control Room Operator"
    },
    {
        "code": "CAD03073",
        "description": "Conveyancer"
    },
    {
        "code": "CAD03074",
        "description": "Conveyer Operator"
    },
    {
        "code": "CAD03075",
        "description": "Crane Operator"
    },
    {
        "code": "CAD03076",
        "description": "Crane Slinger"
    },
    {
        "code": "CAD03077",
        "description": "Crop Sprayer - on ground"
    },
    {
        "code": "CAD03078",
        "description": "Crop Sprayer - pilot"
    },
    {
        "code": "CAD03079",
        "description": "Customer Service Staﬀ"
    },
    {
        "code": "CAD03080",
        "description": "Customs and Excise"
    },
    {
        "code": "CAD03081",
        "description": "Cutter"
    },
    {
        "code": "CAD03082",
        "description": "Cutting Machine Operator"
    },
    {
        "code": "DAD03084",
        "description": "Data Controller"
    },
    {
        "code": "DAD03085",
        "description": "Data Inputter"
    },
    {
        "code": "DAD03086",
        "description": "Dealer - money/shares/investment"
    },
    {
        "code": "DAD03087",
        "description": "Demonstrator"
    },
    {
        "code": "DAD03088",
        "description": "Dental Receptionist"
    },
    {
        "code": "DAD03089",
        "description": "Department Store Manager"
    },
    {
        "code": "DAD03090",
        "description": "Department Store worker"
    },
    {
        "code": "DAD03091",
        "description": "Design cutter"
    },
    {
        "code": "DAD03092",
        "description": "Diamond workers"
    },
    {
        "code": "DAD03093",
        "description": "Dinner Lady"
    },
    {
        "code": "DAD03375",
        "description": "Director - Company - admin only"
    },
    {
        "code": "DAD03376",
        "description": "Director - Company - other"
    },
    {
        "code": "DAD03377",
        "description": "Director - Managing - admin only"
    },
    {
        "code": "DAD03378",
        "description": "Director - Managing - other"
    },
    {
        "code": "DAD03379",
        "description": "Director - Musical"
    },
    {
        "code": "DAD03380",
        "description": "Director - Other"
    },
    {
        "code": "DAD03381",
        "description": "Director - Sales - management only"
    },
    {
        "code": "DAD03386",
        "description": "Director - Sales - some selling"
    },
    {
        "code": "DAD03094",
        "description": "Doorman"
    },
    {
        "code": "DAD03095",
        "description": "Drainage Layer/Clearer"
    },
    {
        "code": "DAD03096",
        "description": "Drier"
    },
    {
        "code": "DAD03097",
        "description": "Driller - oﬀshore"
    },
    {
        "code": "DAD03098",
        "description": "Driller - onshore"
    },
    {
        "code": "DAD03099",
        "description": "Driver - construction"
    },
    {
        "code": "DAD03100",
        "description": "Driver - delivery"
    },
    {
        "code": "DAD03101",
        "description": "Driver - HGV"
    },
    {
        "code": "DAD03102",
        "description": "Driver - industrial plant"
    },
    {
        "code": "DAD03103",
        "description": "Driver - PSV"
    },
    {
        "code": "EAD03104",
        "description": "Ecological Consultant Outside UK"
    },
    {
        "code": "EAD03105",
        "description": "Ecological Consultant UK"
    },
    {
        "code": "EAD03106",
        "description": "Editor"
    },
    {
        "code": "EAD03107",
        "description": "Editorial Assistant"
    },
    {
        "code": "EAD03108",
        "description": "Education Assistant"
    },
    {
        "code": "EAD03109",
        "description": "Electrical Engineer"
    },
    {
        "code": "EAD03110",
        "description": "Electrician - oﬀshore"
    },
    {
        "code": "EAD03111",
        "description": "Electrician UK based - domestic"
    },
    {
        "code": "EAD03112",
        "description": "Electrician UK based - industrial"
    },
    {
        "code": "EAD03113",
        "description": "Embassy Employee"
    },
    {
        "code": "EAD03114",
        "description": "Employment Agency worker"
    },
    {
        "code": "EAD03115",
        "description": "Engineer - admin and site visits only"
    },
    {
        "code": "EAD03116",
        "description": "Engineer - admin only"
    },
    {
        "code": "EAD03117",
        "description": "Engineer - heavy manual"
    },
    {
        "code": "EAD03118",
        "description": "Engineer - light manual"
    },
    {
        "code": "EAD03120",
        "description": "Engineer - sales"
    },
    {
        "code": "EAD03121",
        "description": "Engineer - works at heights over 40 ft"
    },
    {
        "code": "EAD03122",
        "description": "Engraver"
    },
    {
        "code": "EAD03124",
        "description": "Examiner - process"
    },
    {
        "code": "EAD03125",
        "description": "Exhaust Fitter"
    },
    {
        "code": "FAD03126",
        "description": "Fabric Designer"
    },
    {
        "code": "FAD03127",
        "description": "Fabricator - welder/fitter"
    },
    {
        "code": "FAD03128",
        "description": "Facilities Assistant"
    },
    {
        "code": "FAD03129",
        "description": "Fashion Stylist"
    },
    {
        "code": "FAD03130",
        "description": "Fast Food Restaurant Assistant"
    },
    {
        "code": "FAD03131",
        "description": "Financial Planner/Paraplanner"
    },
    {
        "code": "FAD03132",
        "description": "Finisher - toys and textiles"
    },
    {
        "code": "FAD03133",
        "description": "Fish and chip shop owner"
    },
    {
        "code": "FAD03134",
        "description": "Fish and chip shop worker"
    },
    {
        "code": "FAD03135",
        "description": "Flight Attendant"
    },
    {
        "code": "FAD03138",
        "description": "Flight Operations Manager"
    },
    {
        "code": "FAD03139",
        "description": "Foreman - heavy manual"
    },
    {
        "code": "FAD03140",
        "description": "Foreman - light manual"
    },
    {
        "code": "FAD03141",
        "description": "Foreman - no manual"
    },
    {
        "code": "FAD03142",
        "description": "Foreman - oﬀshore"
    },
    {
        "code": "FAD03143",
        "description": "Foreman - other"
    },
    {
        "code": "FAD03136",
        "description": "Foster Parent"
    },
    {
        "code": "FAD03137",
        "description": "Furnace Operator - Other"
    },
    {
        "code": "GAD03150",
        "description": "Gallery Assistant"
    },
    {
        "code": "GAD03155",
        "description": "Galley Hand"
    },
    {
        "code": "GAD03156",
        "description": "Gantry Crane Driver"
    },
    {
        "code": "GAD03145",
        "description": "Glamour Model"
    },
    {
        "code": "GAD03151",
        "description": "Glass Worker"
    },
    {
        "code": "GAD03152",
        "description": "Glazier"
    },
    {
        "code": "GAD03146",
        "description": "GP - general practitioner - Doctor"
    },
    {
        "code": "GAD03154",
        "description": "Greaser"
    },
    {
        "code": "GAD03144",
        "description": "Grinder"
    },
    {
        "code": "GAD03147",
        "description": "Groundworker"
    },
    {
        "code": "GAD03148",
        "description": "Guard - railway"
    },
    {
        "code": "GAD03149",
        "description": "Guard - security"
    },
    {
        "code": "GAD03153",
        "description": "Gunsmith"
    },
    {
        "code": "HAD03164",
        "description": "Hammerman"
    },
    {
        "code": "HAD03157",
        "description": "Hardware Shop Retailer"
    },
    {
        "code": "HAD03162",
        "description": "Hod carrier - construction"
    },
    {
        "code": "HAD03163",
        "description": "Hoist Operator"
    },
    {
        "code": "HAD03165",
        "description": "Holiday Representative"
    },
    {
        "code": "HAD03166",
        "description": "Hotel Concierge"
    },
    {
        "code": "HAD03158",
        "description": "Human Resources Advisor"
    },
    {
        "code": "HAD03159",
        "description": "Human Resources Analyst"
    },
    {
        "code": "HAD03161",
        "description": "Human Resources Assistant"
    },
    {
        "code": "HAD03160",
        "description": "Hydro-Extractor Operator"
    },
    {
        "code": "IAD03175",
        "description": "Immigration Officer - admin only"
    },
    {
        "code": "IAD03177",
        "description": "Immigration Officer - otherwise"
    },
    {
        "code": "IAD03171",
        "description": "Independent Financial Adviser - IFA"
    },
    {
        "code": "IAD03173",
        "description": "Industrial Chemist"
    },
    {
        "code": "IAD03178",
        "description": "Inspector (not police)"
    },
    {
        "code": "IAD03179",
        "description": "Instructor - aviation, diving, etc"
    },
    {
        "code": "IAD03180",
        "description": "Instructor - no special risks"
    },
    {
        "code": "IAD03167",
        "description": "Insulator - asbestos work inc"
    },
    {
        "code": "IAD03168",
        "description": "Insulator - no asbestos work"
    },
    {
        "code": "IAD03169",
        "description": "Internal Auditor"
    },
    {
        "code": "IAD03170",
        "description": "IT Analyst"
    },
    {
        "code": "IAD03172",
        "description": "IT Manager - admin only"
    },
    {
        "code": "IAD03174",
        "description": "IT Programmer"
    },
    {
        "code": "IAD03176",
        "description": "IT Technician"
    },
    {
        "code": "JAD03181",
        "description": "Justice of the Peace"
    },
    {
        "code": "KAD03182",
        "description": "Kebab van vendor"
    },
    {
        "code": "KAD03183",
        "description": "Key Cutter"
    },
    {
        "code": "LAD03184",
        "description": "Labourer"
    },
    {
        "code": "LAD03189",
        "description": "Landlord (Property -inc manual work)"
    },
    {
        "code": "LAD03191",
        "description": "Lathe Operator"
    },
    {
        "code": "LAD03195",
        "description": "Leather worker"
    },
    {
        "code": "LAD03185",
        "description": "Legal Practice Manager"
    },
    {
        "code": "LAD03187",
        "description": "Legal Secretary"
    },
    {
        "code": "LAD03190",
        "description": "Life Coach"
    },
    {
        "code": "LAD03188",
        "description": "Lighterman"
    },
    {
        "code": "LAD03192",
        "description": "Linesman"
    },
    {
        "code": "LAD03193",
        "description": "Loader"
    },
    {
        "code": "LAD03194",
        "description": "Loader Operator"
    },
    {
        "code": "MAD03224",
        "description": "Machine Operator - processing"
    },
    {
        "code": "MAD03201",
        "description": "Machinist"
    },
    {
        "code": "MAD03204",
        "description": "Magazine Editor"
    },
    {
        "code": "MAD03207",
        "description": "Magazine Illustrator"
    },
    {
        "code": "MAD03211",
        "description": "Magazine Writer"
    },
    {
        "code": "MAD03198",
        "description": "Maintenance Fitter"
    },
    {
        "code": "MAD03202",
        "description": "Maintenance Manager"
    },
    {
        "code": "MAD03216",
        "description": "Maintenance Technician"
    },
    {
        "code": "MAD03221",
        "description": "Maitre d'Hotel"
    },
    {
        "code": "MAD03208",
        "description": "Manager - admin only"
    },
    {
        "code": "MAD03212",
        "description": "Manager - heavy manual work"
    },
    {
        "code": "MAD03217",
        "description": "Manager - light manual work"
    },
    {
        "code": "MAD03222",
        "description": "Manager - Oﬀshore"
    },
    {
        "code": "MAD03225",
        "description": "Manager - other"
    },
    {
        "code": "MAD03228",
        "description": "Manager - Retail"
    },
    {
        "code": "MAD03230",
        "description": "Manager - Sales"
    },
    {
        "code": "MAD03232",
        "description": "Managing Director - admin/office based only"
    },
    {
        "code": "MAD03233",
        "description": "Managing Director - heavy manual duties"
    },
    {
        "code": "MAD03234",
        "description": "Managing Director - light manual duties"
    },
    {
        "code": "MAD03235",
        "description": "Managing Director - Other"
    },
    {
        "code": "MAD03236",
        "description": "Managing Director - Retail"
    },
    {
        "code": "MAD03237",
        "description": "Managing Director - Sales Management"
    },
    {
        "code": "MAD03238",
        "description": "Managing Director - Selling"
    },
    {
        "code": "MAD03239",
        "description": "Manhole Maker"
    },
    {
        "code": "MAD03197",
        "description": "Marine Biologist"
    },
    {
        "code": "MAD03226",
        "description": "Market or Street Trader"
    },
    {
        "code": "MAD03196",
        "description": "Marriage Guidance Counsellor"
    },
    {
        "code": "MAD03205",
        "description": "Mechanic"
    },
    {
        "code": "MAD03209",
        "description": "Mechanic - Oil Rig"
    },
    {
        "code": "MAD03213",
        "description": "Mechanical Engineer"
    },
    {
        "code": "MAD03214",
        "description": "Medical Receptionist"
    },
    {
        "code": "MAD03218",
        "description": "Medical Secretary"
    },
    {
        "code": "MAD03210",
        "description": "Meter Collector"
    },
    {
        "code": "MAD03215",
        "description": "Meter Fixer/Tester"
    },
    {
        "code": "MAD03219",
        "description": "Meter Reader"
    },
    {
        "code": "MAD03220",
        "description": "Miller"
    },
    {
        "code": "MAD03223",
        "description": "Milliner"
    },
    {
        "code": "MAD03199",
        "description": "Mineralogist"
    },
    {
        "code": "MAD03200",
        "description": "Mixer - processing"
    },
    {
        "code": "MAD03203",
        "description": "Mobile Crane Driver"
    },
    {
        "code": "MAD03227",
        "description": "Motorman"
    },
    {
        "code": "MAD03229",
        "description": "Mould Maker"
    },
    {
        "code": "MAD03231",
        "description": "Moulder"
    },
    {
        "code": "MAD03206",
        "description": "Musician - Professional"
    },
    {
        "code": "NAD03243",
        "description": "Nail Technician/Beautician"
    },
    {
        "code": "NAD03240",
        "description": "Nature Reserve Worker/Warden"
    },
    {
        "code": "NAD03241",
        "description": "NHS Manager"
    },
    {
        "code": "NAD03242",
        "description": "Night Watchman"
    },
    {
        "code": "NAD03244",
        "description": "Nuclear Plant Attendant"
    },
    {
        "code": "NAD03245",
        "description": "Nurse - Sister"
    },
    {
        "code": "NAD03246",
        "description": "Nursery School Assistant"
    },
    {
        "code": "OAD03247",
        "description": "Oceanographer"
    },
    {
        "code": "OAD03251",
        "description": "Oﬀ-Licence Employee"
    },
    {
        "code": "OAD03248",
        "description": "Oiler and Greaser"
    },
    {
        "code": "OAD03382",
        "description": "Other - Occupation not listed"
    },
    {
        "code": "OAD03249",
        "description": "Outdoor Pursuits Centre Instructor"
    },
    {
        "code": "OAD03250",
        "description": "Ovensman"
    },
    {
        "code": "PAD03258",
        "description": "P E Teacher"
    },
    {
        "code": "PAD03261",
        "description": "Packer"
    },
    {
        "code": "PAD03272",
        "description": "Panel Beater"
    },
    {
        "code": "PAD03259",
        "description": "Personal Assistant (PA)"
    },
    {
        "code": "PAD03383",
        "description": "Photocopier Engineer"
    },
    {
        "code": "PAD03263",
        "description": "Pipe Fitter"
    },
    {
        "code": "PAD03264",
        "description": "Pipe Jointer"
    },
    {
        "code": "PAD03268",
        "description": "Pipe Layer"
    },
    {
        "code": "PAD03253",
        "description": "Planning Engineer"
    },
    {
        "code": "PAD03254",
        "description": "Plant Operator"
    },
    {
        "code": "PAD03252",
        "description": "Playschool/Group Worker/Leader"
    },
    {
        "code": "PAD03260",
        "description": "Politician"
    },
    {
        "code": "PAD03255",
        "description": "Press Operator - processing"
    },
    {
        "code": "PAD03256",
        "description": "Process worker"
    },
    {
        "code": "PAD03257",
        "description": "Producer - TV/film/theatre"
    },
    {
        "code": "PAD03266",
        "description": "Project Manager/Programme Manager"
    },
    {
        "code": "PAD03267",
        "description": "Pumpman"
    },
    {
        "code": "QAD03273",
        "description": "Quality Control Engineer"
    },
    {
        "code": "QAD03274",
        "description": "Quality Control Inspector"
    },
    {
        "code": "RAD03278",
        "description": "Racetrack Steward"
    },
    {
        "code": "RAD03281",
        "description": "Radiotherapist"
    },
    {
        "code": "RAD03275",
        "description": "Recruitment Consultant"
    },
    {
        "code": "RAD03277",
        "description": "Relationship Manager"
    },
    {
        "code": "RAD03279",
        "description": "Riveter"
    },
    {
        "code": "RAD03384",
        "description": "Road Patrol Man"
    },
    {
        "code": "RAD03280",
        "description": "Road Worker/Labourer"
    },
    {
        "code": "RAD03276",
        "description": "Rollerman"
    },
    {
        "code": "SAD03299",
        "description": "Safety Inspector"
    },
    {
        "code": "SAD03300",
        "description": "Sailing Instructor"
    },
    {
        "code": "SAD03305",
        "description": "Salary Administrator"
    },
    {
        "code": "SAD03306",
        "description": "Sales Assistant - retail"
    },
    {
        "code": "SAD03312",
        "description": "Sales Executive"
    },
    {
        "code": "SAD03317",
        "description": "Sales Manager"
    },
    {
        "code": "SAD03319",
        "description": "Sales Support Administrator"
    },
    {
        "code": "SAD03283",
        "description": "Sandblaster"
    },
    {
        "code": "SAD03296",
        "description": "Saw Doctor or Repairer or Sharpener"
    },
    {
        "code": "SAD03291",
        "description": "Scaﬀolder Oﬀshore Oil or Gas"
    },
    {
        "code": "SAD03313",
        "description": "School Assistant"
    },
    {
        "code": "SAD03307",
        "description": "Secretary"
    },
    {
        "code": "SAD03314",
        "description": "Security Consultant"
    },
    {
        "code": "SAD03285",
        "description": "Security Manager"
    },
    {
        "code": "SAD03301",
        "description": "Set Designer"
    },
    {
        "code": "SAD03315",
        "description": "Sewing Machinist"
    },
    {
        "code": "SAD03294",
        "description": "Sheet Metal Worker"
    },
    {
        "code": "SAD03286",
        "description": "Shotblaster"
    },
    {
        "code": "SAD03292",
        "description": "Shotfirer"
    },
    {
        "code": "SAD03302",
        "description": "Sieve Operator - food"
    },
    {
        "code": "SAD03308",
        "description": "Sieve Operator - quarry"
    },
    {
        "code": "SAD03316",
        "description": "Sifter - food"
    },
    {
        "code": "SAD03318",
        "description": "Sifter - quarry"
    },
    {
        "code": "SAD03287",
        "description": "Siloman"
    },
    {
        "code": "SAD03295",
        "description": "Sound Balancer"
    },
    {
        "code": "SAD03297",
        "description": "Sound Mixer"
    },
    {
        "code": "SAD03303",
        "description": "Sound Recordist"
    },
    {
        "code": "SAD03298",
        "description": "Spray Painter"
    },
    {
        "code": "SAD03309",
        "description": "Stage Manager"
    },
    {
        "code": "SAD03284",
        "description": "Station Manager"
    },
    {
        "code": "SAD03288",
        "description": "Station Officer - Fire"
    },
    {
        "code": "SAD03289",
        "description": "Steriliser"
    },
    {
        "code": "SAD03290",
        "description": "Stone Breaker"
    },
    {
        "code": "SAD03293",
        "description": "Stone/Slate Polisher"
    },
    {
        "code": "SAD03304",
        "description": "Storeman"
    },
    {
        "code": "SAD03310",
        "description": "Structural Engineer"
    },
    {
        "code": "SAD03282",
        "description": "Supermarket Manager"
    },
    {
        "code": "SAD03311",
        "description": "Systems Programmer"
    },
    {
        "code": "TAD03338",
        "description": "Tailor"
    },
    {
        "code": "TAD03340",
        "description": "Takeaway Food Shop Assistant"
    },
    {
        "code": "TAD03341",
        "description": "Takeaway Food Shop Manager - no serving"
    },
    {
        "code": "TAD03327",
        "description": "Tanker Driver"
    },
    {
        "code": "TAD03330",
        "description": "Tanker Filler"
    },
    {
        "code": "TAD03332",
        "description": "Tanner"
    },
    {
        "code": "TAD03339",
        "description": "Tattoo Artist"
    },
    {
        "code": "TAD03325",
        "description": "Teacher"
    },
    {
        "code": "TAD03328",
        "description": "Technician - admin and site visits"
    },
    {
        "code": "TAD03331",
        "description": "Technician - admin only"
    },
    {
        "code": "TAD03333",
        "description": "Technician - heavy manual"
    },
    {
        "code": "TAD03335",
        "description": "Technician - light manual"
    },
    {
        "code": "TAD03323",
        "description": "Telesales Caller"
    },
    {
        "code": "TAD03324",
        "description": "Telesales Manager"
    },
    {
        "code": "TAD03336",
        "description": "Test Engineer"
    },
    {
        "code": "TAD03321",
        "description": "Tester"
    },
    {
        "code": "TAD03334",
        "description": "Trade Union Official (full time)"
    },
    {
        "code": "TAD03326",
        "description": "Trainer - education/office based"
    },
    {
        "code": "TAD03329",
        "description": "Tram Driver/Conductor"
    },
    {
        "code": "TAD03337",
        "description": "Turf Cutter/Layer"
    },
    {
        "code": "UAD03343",
        "description": "Umpire"
    },
    {
        "code": "UAD03344",
        "description": "Under Secretary"
    },
    {
        "code": "UAD03342",
        "description": "University lecturer"
    },
    {
        "code": "VAD03348",
        "description": "Vacuum Plant Operator"
    },
    {
        "code": "VAD03345",
        "description": "Valveman"
    },
    {
        "code": "VAD03346",
        "description": "Venture Capitalist"
    },
    {
        "code": "VAD03347",
        "description": "Vintner"
    },
    {
        "code": "WAD03356",
        "description": "Wages Clerk"
    },
    {
        "code": "WAD03352",
        "description": "Washer"
    },
    {
        "code": "WAD03353",
        "description": "Waste Disposal/Recycling Operative"
    },
    {
        "code": "WAD03351",
        "description": "Water Meter Reader/Fitter/Tester"
    },
    {
        "code": "WAD03354",
        "description": "Water Treatment Plant Operator"
    },
    {
        "code": "WAD03357",
        "description": "Weaver"
    },
    {
        "code": "WAD03359",
        "description": "Website/Webpage Designer"
    },
    {
        "code": "WAD03349",
        "description": "Weighbridgeman"
    },
    {
        "code": "WAD03358",
        "description": "Window/Conservatory Fitter"
    },
    {
        "code": "WAD03360",
        "description": "Windscreen Fitter/Repairer"
    },
    {
        "code": "WAD03350",
        "description": "Wood Worker"
    },
    {
        "code": "XAD03362",
        "description": "X-ray Technician - Radiologist"
    },
    {
        "code": "YAD03363",
        "description": "Yacht & Boat Builder"
    },
    {
        "code": "YAD03365",
        "description": "Yard Assistant"
    },
    {
        "code": "YAD03364",
        "description": "Yarn Worker"
    },
    {
        "code": "YAD03366",
        "description": "Yoga Teacher"
    },
    {
        "code": "YAD03367",
        "description": "Youth Worker (full time)"
    },
    {
        "code": "ZAD03370",
        "description": "Zoo Curator"
    },
    {
        "code": "ZAD03368",
        "description": "Zoo Keeper"
    },
    {
        "code": "ZAD03369",
        "description": "Zoo Keeper - large zoos"
    },
    {
        "code": "ZAD03371",
        "description": "Zoo Keeper - small zoos"
    },
    {
        "code": "ZAD03372",
        "description": "Zoological Research Associate"
    },
    {
        "code": "ZAD03373",
        "description": "Zoological Researcher"
    },
    {
        "code": "ZAD03374",
        "description": "Zoologist"
    }
]