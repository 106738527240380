import { useTheme } from "@mui/material";
import axios from "axios";
import dot from "dot-object";
import React, { useEffect, useState } from "react";

import { ConversationStep } from "../../interfaces";

import "./IllustrationPage.scss";

import { numberWithCommas, trackEvent } from "../../util";
//
const { REACT_APP_MORTGAGE_URL } = process.env;

interface IllustrationPageProps {
    values?: any;
    step?: ConversationStep;
    setDataValues?: (values: any) => void;
}

const IllustrationPage: React.FC<IllustrationPageProps> = ({
    values,
    step,
    setDataValues,
}) => {
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(true);
    const [isPropertyValueChanged, setIsPropertyValueChanged] = useState(false);
    const [formValues, setFormValues] = useState<any>({});
    const [result, setResult] = useState<any>({});

    const renderAccordionPanel = () => {
        return (
            <div className="accordion-panel" id={`${step?.id}-panel`}>
                <div className="row">
                    <div className="col">
                        <div className="amount-box">
                            <input
                                type="text"
                                value={formValues?.property_value}
                                style={{
                                    borderBottom: `1px solid ${theme?.custom?.customization?.primaryColor}`,
                                }}
                                onChange={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        property_value: e.target.value,
                                    });
                                    setDataValues!({
                                        ...formValues,
                                        property_value: e.target.value,
                                    });
                                }}
                            />
                            <p>Purchase Price</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="amount-box">
                            <input
                                type="text"
                                value={formValues?.mortgage_balance}
                                style={{
                                    borderBottom: `1px solid ${theme?.custom?.customization?.primaryColor}`,
                                }}
                                onChange={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        mortgage_balance: e.target.value,
                                    });
                                    setDataValues!({
                                        ...formValues,
                                        mortgage_balance: e.target.value,
                                    });
                                }}
                            />
                            <p>
                                {values?.mortgage_type === "Remortgage"
                                    ? "Mortgage Balance"
                                    : "Deposit"}
                            </p>
                        </div>
                    </div>
                </div>
                {formValues?.product_type?.includes("Buy to Let") && (
                    <div className="row">
                        <div className="col">
                            <div className="amount-box">
                                <input
                                    type="text"
                                    value={formValues?.monthly_rent}
                                    style={{
                                        borderBottom: `1px solid ${theme?.custom?.customization?.primaryColor}`,
                                    }}
                                    onChange={(e) => {
                                        setFormValues({
                                            ...formValues,
                                            monthly_rent: e.target.value,
                                        });
                                        setDataValues!({
                                            ...formValues,
                                            monthly_rent: e.target.value,
                                        });
                                    }}
                                />
                                <p>Rental Income</p>
                            </div>
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="col">
                        <div className="product-box">
                            <h4>Product Type</h4>
                            <select
                                name="productType"
                                value={formValues?.product_type}
                                style={{
                                    borderBottom: `1px solid ${theme?.custom?.customization?.primaryColor}`,
                                }}
                                onChange={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        product_type: e.target.value,
                                    });
                                    setDataValues!({
                                        ...formValues,
                                        product_type: e.target.value,
                                    });
                                }}
                            >
                                <option value="Standard Purchase">
                                    Standard Purchase
                                </option>
                                <option value="Standard Remortgage">
                                    Standard Remortgage
                                </option>
                                { theme?.custom?.apiKey !== "0043a7a9-fe21-4291-bf4f-6f253160c427" ? <>
                                <option value="Buy to Let Purchase">
                                    Buy to Let Purchase
                                </option>
                                <option value="Buy to Let Remortgage">
                                    Buy to Let Remortgage
                                </option>
                                </>: null}
                            </select>
                        </div>
                    </div>
                    <div className="col">
                        <div className="product-box">
                            <h4>Repayment Method</h4>
                            <select
                                name="repaymentMethod"
                                value={formValues?.payment_method}
                                onChange={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        payment_method: e.target.value,
                                    });
                                    setDataValues!({
                                        ...formValues,
                                        payment_method: e.target.value,
                                    });
                                }}
                                style={{
                                    borderBottom: `1px solid ${theme?.custom?.customization?.primaryColor}`,
                                }}
                            >
                                <option value="Repayment">Repayment</option>
                                <option value="Interest_Only">
                                    Interest Only
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <button
                    className="calculate"
                    style={{
                        backgroundColor:
                            theme?.custom?.customization?.secondaryColor,
                    }}
                    onClick={() => getAllProducts()}
                >
                    Calculate
                </button>
            </div>
        );
    };

    const renderBestProductResultSummary = (productTerm: number) => {
        return (
            <div
                className="product-result"
                onClick={() => {
                    setFormValues({
                        ...formValues,
                        product_term: productTerm,
                    });

                    setDataValues!({
                        ...formValues,
                        property_term: productTerm,
                    });
                }}
            >
                <div
                    className={`best-class-result ${
                        formValues?.product_term === productTerm ? "active" : ""
                    }`}
                >
                    <span className="length">
                        Best {productTerm} Year {formValues?.mortgage_class}
                    </span>
                    <hr
                        className="mortgage-line"
                        style={{
                            borderColor:
                                theme?.custom?.customization?.primaryColor,
                        }}
                    ></hr>
                    {Object.keys(
                        dot.pick(
                            `${productTerm}.${formValues?.mortgage_class}`,
                            result
                        ) || {}
                    ).length ? (
                        <>
                            <span className="length-amount">
                                {numberWithCommas(
                                    dot.pick(
                                        `${productTerm}.${formValues?.mortgage_class}.InitialMonthlyPayment`,
                                        result
                                    )
                                )}
                                <span className="per">Per month</span>
                            </span>
                            <div
                                style={{
                                    marginTop: 10,
                                }}
                            >
                                <div
                                    className="rate"
                                    style={{
                                        color: theme?.custom?.customization
                                            ?.secondaryColor,
                                    }}
                                >
                                    Rate:{" "}
                                    {dot.pick(
                                        `${productTerm}.${formValues?.mortgage_class}.InitialPayRate`,
                                        result
                                    )}
                                    %
                                </div>
                                <div
                                    className="rate aprc-result"
                                    style={{
                                        color: theme?.custom?.customization
                                            ?.secondaryColor,
                                    }}
                                >
                                    Standard Variable Rate:{" "}
                                    {dot.pick(
                                        `${productTerm}.${formValues?.mortgage_class}.StandardVariableRate`,
                                        result
                                    )}
                                    %
                                </div>
                                <div
                                    className="rate aprc-result"
                                    style={{
                                        color: theme?.custom?.customization
                                            ?.secondaryColor,
                                    }}
                                >
                                    APRC:{" "}
                                    {dot.pick(
                                        `${productTerm}.${formValues?.mortgage_class}.APRC`,
                                        result
                                    )}
                                </div>
                            </div>
                        </>
                    ) : (
                        <span>No mortgage available</span>
                    )}
                </div>
                {formValues?.product_term === productTerm && (
                    <div className="triangle-clip"></div>
                )}
            </div>
        );
    };

    const renderProductDetails = (
        productTerm: number,
        mortgageClass: string
    ) => {
        return (
            <div className="product-details">
                <div className="required-fees">
                    <h3 className="title">
                        Deposit you will require to complete your purchase
                    </h3>
                    <p className="subtitle">
                        We have taken the amount that you specified you saved
                        and broken it down into deposit and lender fees. These
                        are the major upfront costs you’ll face when securing
                        your mortgage.
                    </p>
                    <div className="basic-row">
                        <div className="t-col">
                            <div
                                className="b-total"
                                style={{
                                    backgroundColor:
                                        theme?.custom?.customization
                                            ?.secondaryColor,
                                }}
                            >
                                <h2>
                                    {numberWithCommas(
                                        Math.round(
                                            dot.pick(
                                                `${productTerm}.${mortgageClass}.TotalDeposit`,
                                                result
                                            )
                                        ).toString()
                                    )}
                                </h2>
                            </div>
                        </div>
                        <div className="basic-col">
                            <div className="t-row">
                                <div className="col">
                                    <div className="b-border">
                                        <h5>Deposit</h5>
                                        <h3>
                                            {numberWithCommas(
                                                formValues?.mortgage_balance
                                            )}
                                        </h3>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="b-border">
                                        <h5>Stamp Duty</h5>
                                        <h3>
                                            {numberWithCommas(
                                                dot.pick(
                                                    `${productTerm}.${mortgageClass}.StampDuty`,
                                                    result
                                                )
                                            )}
                                        </h3>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="b-border">
                                        <h5>Lender fees</h5>
                                        <h3>
                                            {numberWithCommas(
                                                dot.pick(
                                                    `${productTerm}.${mortgageClass}.FeesTotal`,
                                                    result
                                                )
                                            )}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderLoanToValue = (productTerm: number, mortgageClass: string) => {
        return (
            <div className="loan-to-value">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h2 className="title">Loan to Value (LTV)</h2>
                            <p className="subtitle">
                                Lenders group mortgages into LTV brackets in the
                                5% increments. This means the bigger the
                                deposit/equity you have the better mortgage deal
                                you are likely to receive.
                            </p>
                            <p className="subtitle">
                                With the LTV rate displayed below you’re likely
                                to get a good deal on your mortgage and have a
                                higher chance of being accepted by a lender.
                            </p>
                        </div>
                        <div className="col ltv-value-col">
                            <h2
                                className="ltv-value"
                                style={{
                                    color: theme?.custom?.customization
                                        ?.primaryColor,
                                }}
                            >
                                {dot.pick(
                                    `${productTerm}.${mortgageClass}.LoanToValue`,
                                    result
                                )}
                                %
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderLenderOverview = (
        productTerm: number,
        mortgageClass: string
    ) => {
        return (
            <div className="product-overview">
                <div className="container">
                    <h2 className="title">Lender Overview</h2>
                    <p className="subtitle">
                        We have matched your details over 12,000 mortgages from
                        more than 130 lenders. The lender shown may differ from
                        your final mortgage offer.
                    </p>
                    <div
                        className="overview-result"
                        style={{
                            borderBottom: `1px solid ${theme?.custom?.customization?.primaryColor}`,
                        }}
                    >
                        <span>Initial monthly payment</span>
                        <span>
                            {numberWithCommas(
                                dot.pick(
                                    `${productTerm}.${mortgageClass}.InitialMonthlyPayment`,
                                    result
                                )
                            )}
                        </span>
                    </div>
                    <div
                        className="overview-result"
                        style={{
                            borderBottom: `1px solid ${theme?.custom?.customization?.primaryColor}`,
                        }}
                    >
                        <span>Lender intro rate</span>
                        <span>
                            {dot.pick(
                                `${productTerm}.${mortgageClass}.InitialPayRate`,
                                result
                            )}
                            %
                        </span>
                    </div>
                    <div
                        className="overview-result"
                        style={{
                            borderBottom: `1px solid ${theme?.custom?.customization?.primaryColor}`,
                        }}
                    >
                        <span>Lender Arrangement Fee</span>
                        <span>
                            {
                                `£ ${(dot.pick(
                                    `${productTerm}.${mortgageClass}.ArrangementFee`,
                                    result
                                ) || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                            }
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const renderMortgageOverview = (
        productTerm: number,
        mortgageClass: string
    ) => {
        return (
            <div className="product-overview">
                <div className="container">
                    <h2 className="title">Mortgage Overview</h2>
                    <p className="subtitle">
                        We have matched the details you gave to live mortgage
                        deals. This is based upon the best available rate we can
                        offer you. Your final mortgage offer may differ from
                        this illustration.
                    </p>
                    <div
                        className="overview-result"
                        style={{
                            borderBottom: `1px solid ${theme?.custom?.customization?.primaryColor}`,
                        }}
                    >
                        <span>Property Price</span>
                        <span>
                            {numberWithCommas(formValues?.property_value)}
                        </span>
                    </div>
                    <div
                        className="overview-result"
                        style={{
                            borderBottom: `1px solid ${theme?.custom?.customization?.primaryColor}`,
                        }}
                    >
                        <span>Mortgage Amount</span>
                        <span>
                            {numberWithCommas(
                                dot.pick(
                                    `${productTerm}.${mortgageClass}.MortgageAmount`,
                                    result
                                )
                            )}
                        </span>
                    </div>
                    <div
                        className="overview-result"
                        style={{
                            borderBottom: `1px solid ${theme?.custom?.customization?.primaryColor}`,
                        }}
                    >
                        <span>Max LTV</span>
                        <span>
                            {dot.pick(
                                `${productTerm}.${mortgageClass}.LoanToValue`,
                                result
                            )}
                            %
                        </span>
                    </div>
                    <div
                        className="overview-result"
                        style={{
                            borderBottom: `1px solid ${theme?.custom?.customization?.primaryColor}`,
                        }}
                    >
                        <span>Mortgage Term</span>
                        <span>{formValues?.mortgage_term} Years</span>
                    </div>
                </div>
            </div>
        );
    };

    const renderProductResults = () => {
        return (
            <div className="result-container">
                <p className="borrowing">
                    This is based on borrowing{" "}
                    {numberWithCommas(formValues.lendingAmount)} for a house
                    value of {numberWithCommas(formValues?.property_value)} over{" "}
                    {formValues?.mortgage_term} years.
                </p>
                
                <button
                    className="accordion green-button"
                    style={{
                        backgroundColor:
                            step?.theme?.customization
                                ?.primaryColor,
                    }}
                >
                    Customise
                </button>
                {renderAccordionPanel()}

                <div className="product-result-summary">
                    {renderBestProductResultSummary(2)}
                    {renderBestProductResultSummary(5)}
                </div>
                {[
                    {
                        productTerm: 2,
                        mortgageClass: "Fixed",
                    },
                    {
                        productTerm: 5,
                        mortgageClass: "Fixed",
                    },
                    {
                        productTerm: 2,
                        mortgageClass: "Variable",
                    },
                    {
                        productTerm: 5,
                        mortgageClass: "Variable",
                    },
                ].map((product) => (
                    <div
                        key={`${product?.mortgageClass}-${product?.productTerm}`}
                        className={`product-result-list ${
                            formValues?.product_term === product?.productTerm &&
                            formValues?.mortgage_class ===
                                product?.mortgageClass
                                ? "active"
                                : ""
                        }`}
                        data-product-term={product?.productTerm}
                        data-mortgage-class={product?.mortgageClass}
                    >
                        {Object.keys(
                            dot.pick(
                                `${product?.productTerm}.${product?.mortgageClass}`,
                                result
                            ) || {}
                        ).length ? (
                            <>
                                {/* {values?.mortgage_type !== "Remortgage"
                                    ? renderProductDetails(
                                          product?.productTerm,
                                          product?.mortgageClass
                                      )
                                    : null} */}
                                {/* {renderLoanToValue(
                                    product?.productTerm,
                                    product?.mortgageClass
                                )}
                                {renderMortgageOverview(
                                    product?.productTerm,
                                    product?.mortgageClass
                                )} */}
                                {renderLenderOverview(
                                    product?.productTerm,
                                    product?.mortgageClass
                                )}
                            </>
                        ) : (
                            renderNoResult()
                        )}
                    </div>
                ))}
                <p className="disclaimer">
                    <br/>
                    By completing this process you are not committing to the selected product as a professional mortgage adviser will review your information before making a fully advised recommendation on the mortgage that best meets your needs.<br/><br/>
                    The maximum amount shown is only intended as a guide and cannot be guaranteed with the limited information provided.
                </p>
            </div>
        );
    };

    const renderHeader = () => {
        return (
            <div className="header-container">
                <div className="row">
                    <div className="col">
                        <div className="header-info">
                            <h1>{formValues?.product_type}</h1>
                            <p>
                                By completing this process you are not
                                committing to the selected product as a
                                professional mortgage adviser will review your
                                information before making a fully advised
                                recommendation on the mortgage that best meets
                                your needs.
                            </p>
                        </div>
                    </div>
                    <div className="col header-customize-col">
                        {!isLoading && (
                            <div className="header-customize">
                                <h4>
                                    Don't want a Fixed Rate? Click here to
                                    Customise your mortgage
                                </h4>
                                <button
                                    className="accordion"
                                    style={{
                                        backgroundColor:
                                            theme?.custom?.customization
                                                ?.primaryColor,
                                    }}
                                >
                                    Customise
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const renderIsPropertyValueChangedNote = () => {
        return (
            <>
                {isPropertyValueChanged ? (
                    <div className="result-container">
                        <p className="borrowing">
                            Based on our initial checks the mortgage amount
                            required is not affordable, however a mortgage
                            amount of{" "}
                            {numberWithCommas(formValues?.lendingAmount)} could
                            be possible. To fully establish your maximum
                            affordability please arrange a meeting with a member
                            of our team.
                        </p>
                    </div>
                ) : null}
            </>
        );
    };

    const renderNoResult = () => {
        return (
            <div className="no-result">
                <div className="container">
                    <h2
                        style={{
                            color: theme?.custom?.customization?.primaryColor,
                        }}
                    >
                        No mortgages found
                    </h2>
                    <p>Try customising your results</p>
                </div>
            </div>
        );
    };

    const renderLoader = () => {
        return (
            <div className="illustration-page-loader">
                <div className="load-circle" />
                <div
                    className="load-content"
                    style={{
                        backgroundColor:
                            theme?.custom?.customization?.primaryColor,
                    }}
                >
                    <h3>Calculating Results.</h3>
                </div>
                <div
                    className="load-content"
                    style={{
                        backgroundColor:
                            theme?.custom?.customization?.secondaryColor,
                    }}
                >
                    <h3>Finding the best deal.</h3>
                </div>
                <div
                    className="load-content"
                    style={{
                        backgroundColor: "#54565A",
                    }}
                >
                    <h3>Scanning the market.</h3>
                </div>
            </div>
        );
    };

    const renderToggleSwitches = () => {
        return (
            <div className="switch-container">
                <h4>Fixed Mortgage</h4>
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={formValues?.mortgage_class === "Fixed"}
                        onChange={(e) => {
                            const checked = e.target.checked;
                            const mortgageClass = checked
                                ? "Fixed"
                                : "Variable";

                            setFormValues({
                                ...formValues,
                                mortgage_class: mortgageClass,
                            });

                            setDataValues!({
                                ...formValues,
                                mortgage_class: mortgageClass,
                            });
                        }}
                    />
                    <span
                        className="slider round"
                        style={{
                            backgroundColor:
                                theme?.custom?.customization?.primaryColor,
                        }}
                    ></span>
                </label>
                <h4>Variable Mortgage</h4>
            </div>
        );
    };

    const calculateHolidayLetLendingAmount = (values: any) => {
        return values?.monthly_rent / 0.055 / 1.45;
    };

    const calculateRentalIncome = (values: any) => {
        const app1Income = parseFloat(values?.applicant1_annualSalary || 0);
        const app2Income = parseFloat(values?.applicant2_annualSalary || 0);
        const income = app1Income + app2Income;
        const modifier = income > 50000 ? 1.45 : 1.25;

        const borrowingAmount = (values?.monthly_rent / modifier / 0.055) * 12;
        const ltv =
            100 * (borrowingAmount / Math.floor(values?.property_value));

        const loanAmount = Math.floor(values?.property_value) - Math.floor(values?.mortgage_balance);

        setIsPropertyValueChanged(false);

        if (ltv > 75) {

            if (Math.floor(values?.property_value) * 0.75 > loanAmount) {
                return loanAmount;
            }

            return Math.floor(values?.property_value) * 0.75;
        }

        if (borrowingAmount < loanAmount) {
            setIsPropertyValueChanged(true);
        }

        if (borrowingAmount > loanAmount) {
            return loanAmount;
        }

        return borrowingAmount;
    };

    const calculateStampDutyForFirstHome = (values: any) => {
        let propertyValue = Math.round(values?.property_value);
        let tax = 0;

        if (propertyValue <= 425000) return tax;

        propertyValue -= 425000;
        tax += Math.min(propertyValue, 625000) * 0.05;

        return tax;
    };

    const calculateStampDuty = (values: any) => {
        const isFirstHome = values?.mortgage_type === "First Home";
        let propertyValue = Math.round(values?.property_value);
        let tax = 0;

        if (isFirstHome && propertyValue <= 625000)
            return calculateStampDutyForFirstHome(values);

        if (propertyValue <= 250000) return tax;

        propertyValue -= 250000;
        tax += Math.min(propertyValue, 675000) * 0.05;

        if (propertyValue <= 675000) return tax;

        propertyValue -= 675000;
        tax += Math.min(propertyValue, 575000) * 0.1;

        if (propertyValue <= 575000) return tax;

        tax += propertyValue * 0.12;

        return tax;
    };

    const getMaxBorrowingAmount = (values: any) => {
        const maxCap = 1500000;
        const loanAmount =
            parseFloat(values?.property_value) -
            parseFloat(values?.mortgage_balance);
        return loanAmount > maxCap ? maxCap : loanAmount;
        // if (values?.product_type?.includes("Remortgage")) {
        //     return values?.mortgage_balance > maxCap
        //         ? maxCap
        //         : values?.mortgage_balance;
        // } else {
        //     const app1Income = parseFloat(values?.applicant1_annualSalary || 0);
        //     const app2Income = parseFloat(values?.applicant2_annualSalary || 0);
        //     const income = app1Income + app2Income;
        //     const loanAmount =
        //         parseFloat(values?.property_value) -
        //         parseFloat(values?.mortgage_balance);
        //     const borrowing =
        //         //for dupree
        //         theme?.custom?.apiKey === "dd1a7b84-a16c-4d16-ae74-9c6f2e370918"
        //         //for Arc & Co
        //         || theme?.custom?.apiKey === "370c8885-59dc-40c5-ba4e-2ce4a3ec01b4"
        //             ? 5 * income
        //             : 4.5 * income;
        //     if (loanAmount < borrowing) {
        //         return loanAmount > maxCap ? maxCap : loanAmount;
        //     } else {
        //         return borrowing > maxCap ? maxCap : borrowing;
        //     }
        // }
    };

    const getLendingAmount = (values: any) => {
        // Commented for US -> 45237
        // if (values?.product_type?.includes("Buy to Let")) {
        //     if (
        //         values?.mortgage_type === "Holiday Let" &&
        //         (values?.holiday_let_home_reason === "Rented Out" ||
        //             values?.holiday_let_home_reason === "Bit of Both")
        //     ) {
        //         return Math.round(calculateHolidayLetLendingAmount(values));
        //     }

        //     if (
        //         values?.mortgage_type === "Holiday Home" &&
        //         values?.holiday_let_home_reason === "Rented Out"
        //     ) {
        //         return Math.round(calculateHolidayLetLendingAmount(values));
        //     }

        //     if (values?.monthly_rent) {
        //         return Math.round(calculateRentalIncome(values));
        //     }

        //     return getMaxBorrowingAmount(values);
        // }

        return getMaxBorrowingAmount(values);
    };

    const calculateAPRC = (values: any, product: any) => {
        const loanAmount = getLendingAmount(values);
        const totalFees = product?.FeesTotal;
        const term = parseInt(values?.mortgage_term);
        const initialRate = product?.InitialPayRate;
        const initialDuration = product?.InitialRatePeriodMonths / 12;
        const firstInterest = loanAmount * initialRate * initialDuration;
        const variableRate = product?.StandardVariableRate;
        const variableDuration = term - initialDuration;
        const secondInterest = loanAmount * variableRate * variableDuration;
        const totalInterest = firstInterest + secondInterest;
        const aprc =
            ((totalInterest + totalFees) / loanAmount / (term * 12)) * 12;

        return aprc.toFixed(2);
    };

    const getRunSourceArguments = (
        values: any = formValues,
        productTermFrom: number,
        productTermTo: number,
        mortgageClass: string
    ) => {
        return {
            Term: values?.mortgage_term,
            ExpectedValuation: values?.property_value,
            LoanRequired: getLendingAmount(values),
            ReasonForMortgage: values?.reasonForRemortgage,
            MortgageType: values?.product_type?.includes("Buy to Let")
                ? "Buy_To_Let"
                : "Standard",
            PaymentMethod: values?.payment_method,
            MortgageClass: {
                LiborLinked: "Ignore",
                Capped: "Ignore",
                Fixed: mortgageClass === "Fixed" ? "No_Filter" : "Ignore",
                Variable: mortgageClass === "Variable" ? "No_Filter" : "Ignore",
                Discount: mortgageClass === "Variable" ? "No_Filter" : "Ignore",
                Tracker: mortgageClass === "Variable" ? "No_Filter" : "Ignore",
            },
            ...((values?.mortgage_type === "Holiday Let" ||
                values?.mortgage_type === "Holiday Home") && {
                PropertyDetails: {
                    PropertyUse:
                        values?.mortgage_type === "Holiday Let"
                            ? "Holiday_Let"
                            : "Second_Home",
                },
            }),
            Filters: {
                SortResultsByColumn: {
                    Direction: "Ascending",
                    Column: "InitialPayRate",
                },
                Retention: "Ignore",
                ProductTermPeriodFromMonths: productTermFrom,
                ProductTermPeriodToMonths: productTermTo,
                HelpToBuy:
                    values?.help_to_buy === "Yes" ? "No_Filter" : "Ignore",
            },
            NumberOfItems: 1,
        };
    };

    const getProductResult = (values: any, data: any) => {
        if (!data) return {};

        return {
            ...data,
            APRC: `${calculateAPRC(values, data)}%`,
            InitialMonthlyPayment: data?.InitialMonthlyPayment,
            LoanToValue: Math.round(
                100 *
                    (getLendingAmount(values) /
                        parseFloat(values?.property_value))
            ),
            StampDuty: calculateStampDuty(values),
            TotalDeposit:
                calculateStampDuty(values) +
                parseInt(values?.mortgage_balance) +
                parseInt(data?.FeesTotal),
            MortgageAmount: getLendingAmount(values),
        };
    };

    const getAllProducts = async (values: any = formValues) => {
        try {
            setIsLoading(true);

            const {
                data: { results },
            } = await axios.post(`${REACT_APP_MORTGAGE_URL}/api/product/all`, [
              getRunSourceArguments(values, 19, 27, "Fixed"), //19 to 27 month initial period products. i.e. 2 years
              getRunSourceArguments(values, 55, 68, "Fixed"), //55 to 68 month initial period products. i.e 5 years
              getRunSourceArguments(values, 19, 27, "Variable"),
              getRunSourceArguments(values, 55, 68, "Variable"),
            ]);

            setResult({
                2: {
                    Fixed: getProductResult(values, results[0]),
                    Variable: getProductResult(values, results[2]),
                },
                5: {
                    Fixed: getProductResult(values, results[1]),
                    Variable: getProductResult(values, results[3]),
                },
            });

            setFormValues({
                ...values,
                lendingAmount: getLendingAmount(values),
            });

            setIsLoading(false);
        } catch (err) {
            console.error(err);
        }
    };

    const getInitialValues = () => {
        let paymentMethod = "Repayment";
        let reasonForRemortgage = "Purchase";
        let productType = "Standard Purchase";
        let monthlyRent =
            parseFloat(values?.monthly_rent || values?.holiday_let_rental) || 0;
        let propertyValue = values?.property_value || 0;
        let maxPropertyValue =
            parseFloat(values?.property_value) -
                (parseFloat(values?.property_value) -
                    parseFloat(values?.mortgage_balance)) +
                getLendingAmount(values) || 0;

        switch (values?.mortgage_type) {
            case "Holiday Let":
                reasonForRemortgage = values?.holiday_let_mortgage_reason;
                paymentMethod = "Interest_Only";

                if (
                    values?.holiday_let_home_reason === "Rented Out" ||
                    values?.holiday_let_home_reason === "Bit of Both"
                ) {
                    productType = `Buy to Let ${reasonForRemortgage}`;
                    monthlyRent = monthlyRent * 4;
                } else {
                    productType = "Standard Purchase";
                    reasonForRemortgage = "Purchase";
                }
                break;
            case "Holiday Home":
                reasonForRemortgage = values?.holiday_home_mortgage_reason;
                paymentMethod = "Interest_Only";

                if (values?.holiday_let_home_reason === "Rented Out") {
                    productType = `Buy to Let ${reasonForRemortgage}`;
                    monthlyRent = monthlyRent * 4;
                } else {
                    productType = `Standard ${reasonForRemortgage}`;
                }
                break;
            case "Remortgage":
                productType = "Standard Remortgage";
                reasonForRemortgage = "Remortgage";
                break;
            case "Buy to Let":
                productType = "Buy to Let Purchase";
                paymentMethod = "Interest_Only";
                break;
            case "Buy to Let Remortgage":
                productType = "Buy to Let Remortgage";
                paymentMethod = "Interest_Only";
                break;
            default:
                break;
        }

        if (theme?.custom?.apiKey === "dd1a7b84-a16c-4d16-ae74-9c6f2e370918") {
            if (maxPropertyValue < propertyValue) {
                setIsPropertyValueChanged(true);
                propertyValue = maxPropertyValue;
            } else {
                setIsPropertyValueChanged(false);
            }
        }

        return {
            ...values,
            payment_method: paymentMethod,
            reasonForRemortgage: reasonForRemortgage,
            product_type: productType,
            product_term: 5,
            mortgage_class: "Fixed",
            monthly_rent: monthlyRent,
            property_value: propertyValue,
        };
    };

    const initializeAccordion = () => {
        const accordion = document.getElementsByClassName("accordion");
        for (let i = 0; i < accordion.length; i++) {
            accordion[i].addEventListener("click", (ev) => {
                ev.preventDefault();
                const panel =
                    //@ts-ignore
                    ev?.target?.nextSibling;
                if (panel?.id === `${step?.id}-panel`) {
                    if (panel?.style?.maxHeight) {
                        panel.style.maxHeight = null;
                    } else {
                        panel.style.maxHeight = panel.scrollHeight + "px";
                    }
                }
            });
        }
    };

    useEffect(() => {
        if (!isLoading) {
            initializeAccordion();
        }
        /*eslint-disable react-hooks/exhaustive-deps*/
    }, [!isLoading]);

    useEffect(() => {
        const initialize = async () => {
            setFormValues(getInitialValues());
            setDataValues!(getInitialValues());
            getAllProducts(getInitialValues());
        };
        if (theme?.custom?.googleAnalyticsTrackingId) {
            trackEvent(theme?.custom?.googleAnalyticsTrackingId, {
                category: "MeetParkerChat",
                action: "Illustration Page Loaded",
            });
        }
        initialize();
        /*eslint-disable react-hooks/exhaustive-deps*/
    }, []);

    return (
        <div className={`illustration-page ${isLoading ? "loading" : ""}`}>
            {isLoading ? (
                renderLoader()
            ) : (
                <>
                    {/* {renderHeader()}
                    {renderAccordionPanel()} */}
                    {renderIsPropertyValueChangedNote()}
                    {/* {renderToggleSwitches()} */}
                    {renderProductResults()}
                </>
            )}
        </div>
    );
};

export default React.memo(IllustrationPage);
