import { ConversationStep } from "../../interfaces";

export default [
  {
    id: "holiday_home_mortgage_reason",
    type: "bot",
    message: "Are you Purchasing or Remortgaging your Holiday Let?",
    options: [
      {
        label: "Purchase",
        value: "Purchase",
        trigger: "holiday_let_home_reason",
      },
      {
        label: "Remortgage",
        value: "Remortgage",
        trigger: "holiday_let_home_reason",
      },
    ],
  },
  {
    id: "holiday_home_ltv_reason",
    type: "bot",
    message:
      "A second home mortgage will usually require a 25% deposit, or 15% at a very minimum.",
    trigger: "mortgage_term",
  },
] as ConversationStep[];
