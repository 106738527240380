import { useTheme } from "@mui/system";
import DOMPurify from "dompurify";
import { useContext } from "react";
import { AppContext } from "../AppContext";
import { ChatMessageOptionsInterface } from "../interfaces";

const ChatMessageOptions: React.FC<ChatMessageOptionsInterface> = ({
    options,
    onOptionSelect,
}) => {
    const { values } = useContext(AppContext);

    const theme: any = useTheme();

    return (
        <div className="conversation-buttons">
            {options
                .filter((o) => (o?.enable ? o.enable(values) : true))
                .map((option: any) => (
                    <div
                        className="conversation-options"
                        key={option?.label}
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(option?.label!),
                        }}
                        style={{
                            backgroundColor: theme?.palette.primary.main,
                            borderColor: theme?.palette.primary.main,
                        }}
                        onClick={() => {
                            // setIsStatic(false);
                            // triggerNextPage!(lastStep, {
                            //     trigger: option?.trigger,
                            //     value: option?.value,
                            // })
                            if (
                                (theme?.custom?.apiKey ===
                                    "aca36806-760b-4b14-84a4-6e9e2757f237" &&
                                    option?.label === "Mortgage" &&
                                    option?.trigger === "mortgage_type" &&
                                    option?.value === "Mortgage") ||
                                (theme?.custom?.apiKey ===
                                    "aca36806-760b-4b14-84a4-6e9e2757f237" &&
                                    option?.label === "Protection" &&
                                    option?.trigger === "household" &&
                                    option?.value === "Protection Review")
                            ) {
                                //@ts-ignore
                                window.dataLayer = window.dataLayer || [];
                                //@ts-ignore
                                window.dataLayer.push({
                                    event: "ai_assistant",
                                    action: "form_start",
                                });

                                console.log(`//@ts-ignore
                                window.dataLayer = window.dataLayer || [];
                                //@ts-ignore
                                window.dataLayer.push({
                                    event: "ai_assistant",
                                    action: "form_start",
                                });`);

                                console.log(
                                    `window.dataLayer: `,
                                    //@ts-ignore
                                    window.dataLayer
                                );
                            }
                            if (
                                //@ts-ignore
                                (theme?.custom?.apiKey ===
                                    "e391b696-0125-4fe7-a463-a998667352c9" ||
                                    //@ts-ignore
                                    theme?.custom?.apiKey ===
                                        "e391b696-0125-4fe7-a463-a998667352c0" ||
                                    //@ts-ignore
                                    theme?.custom?.apiKey ===
                                        "e391b696-0125-4fe7-a463-a998667352c1" ||
                                    //@ts-ignore
                                    theme?.custom?.apiKey ===
                                        "e391b696-0125-4fe7-a463-a998667352c2") &&
                                (option?.label === "Let's Chat Now" ||
                                    option?.label ===
                                        "Book Your Personal Consultation")
                            ) {
                                window.open(
                                    "https://calendly.com/fitch-team/free-consultation",
                                    "_blank"
                                );
                            } else {
                                onOptionSelect!(option);
                            }
                        }}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.backgroundColor = "#FFF";
                            e.currentTarget.style.borderColor =
                                theme?.palette.primary.main;
                            e.currentTarget.style.color =
                                theme?.palette.primary.main;
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.backgroundColor =
                                theme?.palette.primary.main;
                            e.currentTarget.style.borderColor =
                                theme?.palette.primary.main;
                            e.currentTarget.style.color = "#FFF";
                        }}
                    />
                ))}
        </div>
    );
};

export default ChatMessageOptions;
