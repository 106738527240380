import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useCallback, useContext, useEffect, useState } from "react";

import axios from "axios";
import { AppContext } from "./AppContext";
import Logo from "./assets/images/logo.png";
import AppTheme from "./interfaces/Theme";
import defaultTheme from "./theme";

const { REACT_APP_SHOP_URL } = process.env;

export default function withRoot(
    Component: any,
    apiKey: string,
    config: any = {}
) {
    const WithRoot = (props: any) => {
        const { setDataValues } = useContext(AppContext);

        const [isLoading, setIsLoading] = useState(false);
        const [theme, setTheme] = useState<AppTheme>({});

        const getDefaultTheme = (themeId: string) => {
            switch (themeId) {
                case "blue":
                    return {
                        theme: "royal",
                        primaryColor: "#002554",
                        secondaryColor: "#fdf8f3",
                    };
                case "brown":
                    return {
                        theme: "hazelnut",
                        primaryColor: "#b1a296",
                        secondaryColor: "#dff1ff",
                    };
                case "red":
                    return {
                        theme: "ruby",
                        primaryColor: "#ff385c",
                        secondaryColor: "#464646",
                    };
                case "green":
                    return {
                        theme: "jade",
                        primaryColor: "#41b3a3",
                        secondaryColor: "#fff0e6",
                    };
                default:
                    throw new Error("Invalid theme id.");
            }
        };

        const getClient = useCallback(async () => {
            try {
                setIsLoading(true);
                if (apiKey) {
                    const { data } = await axios.get(
                        `${REACT_APP_SHOP_URL}/api/v1/shop/client/get?apiKey=${apiKey}`
                    );

                    const {
                        email,
                        logo,
                        name,
                        config: productConfig,
                        attributes: { theme: themeId, version },
                        emailCustomization,
                        flowCustomization,
                        appointmentCustomization,
                        customization,
                        stripe,
                        products,
                        links,
                        files,
                        workHours,
                        googleAnalyticsTrackingId,
                    } = data;

                    let customFlowData;
                    if (flowCustomization) {
                        customFlowData = await axios.get(
                            `${REACT_APP_SHOP_URL}/api/v1/shop/flow?id=${flowCustomization}`
                        );
                    }

                    const themeProps = {
                        apiKey,
                        themeId: getDefaultTheme(themeId)?.theme,
                        email,
                        logo,
                        name,
                        version,
                        config: productConfig,
                        appConfig: config,
                        customization: {
                            fontFamily: customization?.fontFamily,
                            primaryColor:
                                customization?.primaryColor ||
                                getDefaultTheme(themeId)?.primaryColor,
                            secondaryColor:
                                customization?.secondaryColor ||
                                getDefaultTheme(themeId)?.secondaryColor,
                            companyName:
                                customization?.companyName || "Meet Parker",
                            botAvatar: customization?.botAvatar || Logo,
                            expertPhoneNumber:
                                customization?.expertPhoneNumber || "",
                            botName: customization?.botName || "Parker",
                            parkerSlideBG: customization?.parkerSlideBG || null,
                            parkerSlideTextColor:
                                customization?.parkerSlideTextColor ||
                                apiKey ===
                                    "370c8885-59dc-40c5-ba4e-2ce4a3ec01b4"
                                    ? "#fff"
                                    : "#000",
                        },
                        emailCustomization,
                        appointmentCustomization,
                        flowCustomization,
                        customFlowData: customFlowData?.data,
                        stripe,
                        products,
                        files,
                        links,
                        workHours,
                        googleAnalyticsTrackingId,
                    };

                    setTheme(themeProps);
                    setDataValues({
                        theme: themeProps,
                    });
                } else {
                    // navigate("/404");
                }
            } catch (err) {
                console.log(err);
                console.error(err);
            } finally {
                setIsLoading(false);
            }
            /*eslint-disable react-hooks/exhaustive-deps*/
        }, []);

        useEffect(() => {
            getClient();
        }, [getClient]);

        const fontFamily = theme?.customization?.fontFamily
            ? `${theme?.customization?.fontFamily} !important`
            : `montserratlight, Montserrat, sans-serif !important`;

        return isLoading ? null : (
            <ThemeProvider
                theme={{
                    ...defaultTheme,
                    palette: {
                        ...defaultTheme?.palette,
                        primary: {
                            main: theme?.customization?.primaryColor,
                        },
                        secondary: {
                            main: theme?.customization?.secondaryColor,
                        },
                    },
                    custom: theme,
                    typography: {
                        ...defaultTheme?.typography,
                        fontFamily,
                        body1: {
                            ...defaultTheme?.typography?.body1,
                            fontFamily,
                        },
                        body2: {
                            ...defaultTheme?.typography?.body2,
                            fontFamily,
                        },
                        button: {
                            ...defaultTheme?.typography?.button,
                            fontFamily,
                        },
                        caption: {
                            ...defaultTheme?.typography?.caption,
                            fontFamily,
                        },
                        h1: {
                            ...defaultTheme?.typography?.h1,
                            fontFamily,
                        },
                        h2: {
                            ...defaultTheme?.typography?.h2,
                            fontFamily,
                        },
                        h3: {
                            ...defaultTheme?.typography?.h3,
                            fontFamily,
                        },
                        h4: {
                            ...defaultTheme?.typography?.h4,
                            fontFamily,
                        },
                        h5: {
                            ...defaultTheme?.typography?.h5,
                            fontFamily,
                        },
                        h6: {
                            ...defaultTheme?.typography?.h6,
                            fontFamily,
                        },
                        subtitle1: {
                            ...defaultTheme?.typography?.subtitle1,
                            fontFamily,
                        },
                        subtitle2: {
                            ...defaultTheme?.typography?.subtitle2,
                            fontFamily,
                        },
                    },
                }}
            >
                <CssBaseline />
                <Component {...props} />
            </ThemeProvider>
        );
    };

    return WithRoot;
}
