import React, { useState } from "react";

interface AppContextType {
  values?: any;
  setDataValues?: any;
}

interface AppProviderProps {
  children?: any;
}

export const AppContext = React.createContext<AppContextType>({});

const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [values, setValues] = useState<any>({});

  const setDataValues = (data: any) => {
    setValues({
      ...values,
      ...data,
    });
  };

  return (
    <AppContext.Provider
      value={{
        values,
        setDataValues,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
