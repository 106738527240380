import { useTheme } from "@mui/material";
import { useContext, useState } from "react";
import { AppContext } from "../AppContext";
import { ChatMessageInputInterface } from "../interfaces";
import { hexToRgb } from "../util";

const ChatMessageInput: React.FC<ChatMessageInputInterface> = ({
  input,
  onInputSubmit,
}) => {
  const { values } = useContext(AppContext);

  const theme = useTheme();
  const [text, setText] = useState("");

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onInputSubmit(text);
      }}
      style={{ width: "100%" }}
    >
      <div
        className="conversation-input-wrapper"
        style={{
          borderColor: theme?.palette?.primary?.main,
        }}
      >
        {input?.startAdornment && (
          <span className="conversation-input-startAdornment">
            {typeof input.startAdornment === "function"
              ? (input?.startAdornment as Function)(text, values)
              : input.startAdornment}
          </span>
        )}
        <input
          className="conversation-input"
          type={input?.type}
          placeholder={input?.placeholder}
          required
          value={text}
          autoFocus={true}
          onChange={(e) => {
            setText(e.target.value);
          }}
        />
        {input?.endAdornment && (
          <span className="conversation-input-endAdornment">
            {typeof input.endAdornment === "function"
              ? (input?.endAdornment as Function)(text, values)
              : input.endAdornment}
          </span>
        )}
        <button
          type="submit"
          className="conversation-input-btn"
          style={{
            backgroundColor: theme?.custom?.customization?.primaryColor,
            boxShadow: `0px 12px 18px -6px rgb(${
              hexToRgb(theme?.custom?.customization?.primaryColor!)?.r
            } ${hexToRgb(theme?.custom?.customization?.primaryColor!)?.g} ${
              hexToRgb(theme?.custom?.customization?.primaryColor!)?.b
            } / 53%)`,
          }}
        >
          Send
        </button>
      </div>
    </form>
  );
};

export default ChatMessageInput;
