import { ConversationStep } from "../../interfaces";
import { numberWithCommas } from "../../util";

export default [
  {
    id: "monthly_rent",
    message: "What is your expected monthly rent?",
    type: "bot",
    input: {
      type: "number",
      placeholder: "Type here...",
      startAdornment: "£",
    },
    formatter: numberWithCommas,
    options: [
      {
        label: "£ 1,000",
        value: 1000,
        trigger: (values) => {
          if (values?.mortgage_type === "Buy to Let Remortgage") {
            return "has_loans";
          }

          return "mortgage_balance";
        },
      },
      {
        label: "£ 1,250",
        value: 1250,
        trigger: (values) => {
          if (values?.mortgage_type === "Buy to Let Remortgage") {
            return "has_loans";
          }

          return "mortgage_balance";
        },
      },
      {
        label: "£ 1,750",
        value: 1750,
        trigger: (values) => {
          if (values?.mortgage_type === "Buy to Let Remortgage") {
            return "has_loans";
          }

          return "mortgage_balance";
        },
      },
      {
        label: "£ 2,000",
        value: 2000,
        trigger: (values) => {
          if (values?.mortgage_type === "Buy to Let Remortgage") {
            return "has_loans";
          }

          return "mortgage_balance";
        },
      },
    ],
    trigger: (values: any) => {
      if (values?.mortgage_type === "Buy to Let Remortgage") {
        return "has_loans";
      }

      return "mortgage_balance";
    },
  },
  {
    id: "btl_mortgage",
    message: "What sort of Buy to Let Mortgage are you looking for?",
    type: "bot",
    options: [
      {
        label: "HMO (house of multiple occupants)",
        value: "HMO (house of multiple occupants)",
        trigger: "applicant1_firstName",
      },
      {
        label: "Limited Company SPV",
        value: "Limited Company SPV",
        trigger: "applicant1_firstName",
      },
      {
        label: "Portfolio Landlord (more than 4 properties)",
        value: "Portfolio Landlord (more than 4 properties)",
        trigger: "applicant1_firstName",
      },
    ],
  },
] as ConversationStep[];
