import { renderApp } from ".";

const widgetDivs = document.querySelectorAll(".mp-parker-slide");
widgetDivs.forEach((div: any) => {
  const apiKey = div.dataset.apikey;

  const config = {
    header: div.dataset.header,
  };

  renderApp(apiKey, config, div);
});

const createParkerSlideDiv = (htmlElement: HTMLElement): HTMLElement => {
  if (htmlElement) {
    return htmlElement;
  }

  const element = document.createElement("div");
  element.id = "mp-parker-slide";

  document.body.appendChild(element);

  return element;
};

const isChatAppExisting = () => {
  return document.querySelector("#mp-parker-slide") !== null;
};

const MeetParkerSlide = {
  init: (apiKey: string, config: any = {}, element: HTMLElement) => {
    if (isChatAppExisting()) {
      document.body.removeChild(document.querySelector("#mp-parker-slide")!);
    }
    renderApp(apiKey, config as any, createParkerSlideDiv(element));
  },
};

export default MeetParkerSlide;
