import React from "react";
import axios from "axios";
import { sendAppointmentEmail } from "../../util";
import { ConversationStep } from "../../interfaces";
import Theme from "../../interfaces/Theme";

const { REACT_APP_MORTGAGE_URL } = process.env;

interface IProps {
  step?: ConversationStep;
  values?: any;
}

interface IState {
  values: any;
  theme: Theme;
}

class AppointmentAPICall extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      values: props?.values,
      theme: props?.step?.theme!,
    };
  }

  //get email from keys
  getEmailValue(values: any): string {
    const arrayOfValues = Object.keys(values);
    const emailKey = arrayOfValues.find(
      (data: any) => data.toLowerCase().indexOf("email") > -1
    );
    return emailKey ? values[emailKey] : null;
  }

  getNames(values: any): string {
    const arrayOfValues = Object.keys(values);
    const _firstName: string | any = arrayOfValues.find(
      (data: any) => data.toLowerCase().indexOf("first_name") > -1
    );
    const _lastName: string | any = arrayOfValues.find(
      (data: any) => data.toLowerCase().indexOf("last_name") > -1
    );
    return _firstName || _lastName
      ? `${values[_firstName]} ${values[_lastName]}`
      : "";
  }

  async sendAppointmentEmail() {
    await sendAppointmentEmail(this.state.values, this.state.theme);
  }

  async sendNoAppointmentEmail() {
    await axios.post(`${REACT_APP_MORTGAGE_URL}/api/teams/noAppointment`, {
      apiKey: this.state.theme?.apiKey,
      appointmentCustomization: this.state.theme?.appointmentCustomization,
      emailCustomization: this.state.theme?.emailCustomization,
      email: this.state.values?.applicant1_email
        ? this.state.values?.applicant1_email
        : this.getEmailValue(this.state.values),
      props: {
        name: this.state.values?.applicant1_firstName
          ? `${this.state.values?.applicant1_firstName}`
          : this.getNames(this.state.values),
      },
      links: this.state.theme?.links,
    });
  }

  async componentDidMount() {
    if (this.state.values?.talk_with_advisor === "Yes") {
      await this.sendAppointmentEmail();
    } else {
      await this.sendNoAppointmentEmail();
    }
  }

  render() {
    if (this.state.values?.talk_with_advisor === "No") return null;
    if (this.state.values?.contact_requested === "Talk Now") return null;

    return (
      <div className="conversation-message-bubble">
        <div className="conversation-message-bubble-text">
          I'm just booking this in for you
        </div>
      </div>
    );
  }
}

export default React.memo(AppointmentAPICall);
